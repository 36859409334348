<template lang="pug">
  include ./../../../../mixins
  +b.favourite-control-wrapper--labeled(
    v-if='withLabel'
    :class="userIsFavourite ? 'is-active' : ''"
    @click='updateFavouriteRelation(userId, userIsFavourite, index)'
  )
    +b.favourite-control-label {{ label }}
    +b.I.icon(
      :class="userIsFavourite ? 'icon-empty-heart' : 'icon-heart'"
    )
  +b.favourite-control-wrapper(
    v-else
    :class="userIsFavourite ? 'is-active' : ''"
    @click='updateFavouriteRelation(userId, userIsFavourite, index)'
  )
    +b.I.icon(
      :class="userIsFavourite ? 'icon-empty-heart' : 'icon-heart'"
    )
</template>

<script>
import InfoModal from '@components/Modals/InfoModal'
import {
  favouriteUserAppendRemoveResource,
} from '../../../services/users.service'

export default {
  props: {
    userId: {
      default: null,
    },
    isFavourite: {
      default: false,
    },
    index: {
      default: null,
    },
    withLabel: {
      default: true,
    },
  },

  data() {
    return {
      label: '',
      userIsFavourite: this.isFavourite,
    }
  },

  mounted() {
    if (this.isFavourite) {
      this.label = this._('прибрати із симпатій')
    } else {
      this.label = this._('додати до симпатій')
    }
  },

  methods: {
    updateFavouriteRelation(userId, userIsFavourite, index) {
      let action = 'append'
      if (userIsFavourite) {
        action = 'delete'
      }
      favouriteUserAppendRemoveResource.execute({}, {
        userId,
        action,
      }).then(() => {
        if ('delete' === action) {
          this.label = this._('додати до симпатій')
          this.$emit('deleteFromFavouritesEvent', {
            index,
          })
        } else {
          this.label = this._('прибрати із симпатій')
        }
        this.userIsFavourite = !this.userIsFavourite
      }).catch(async error => {
        if (403 === error.status) {
          const message = this._('Необхідно авторизуватися')
          const btnTitle = this._('Авторизуватися')
          this.openModal(message, btnTitle, window.loginPageLink)
        }
      })
    },

    openModal(title, btnTitle, link) {
      this.$modal.show(InfoModal, {
        title,
        btnTitle,
        btnLink: link,
      }, {
        height: 'auto',
        width: 360,
        adaptive: true,
        scrollable: false,
        transition: 'false',
      })
    },
  },
}
</script>
