<script>
import { emitter } from './EventEmmiter'

export default {
  props: {
    url: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      audio: {},
    }
  },

  watch: {
    url: {
      immediate: true,
      handler() {
        this.initAudio()
      },
    },
  },

  beforeDestroy() {
    emitter.unsubscribe('message:update', this.sound)
  },

  mounted() {
    emitter.subscribe('message:update', this.sound)
  },

  methods: {
    initAudio() {
      /**
       * create notification audio object
       * and waiting when audio will be loaded
       */
      this.audio = new Promise(resolve => {
        const audio = new Audio()
        audio.addEventListener('canplaythrough', () => resolve(audio))
        audio.preload = 'auto'
        audio.src = this.url
        audio.load()
      })
    },

    sound() {
      this.audio.then(audio => audio.play())
    },
  },

  render() {
    return null
  },
}

</script>
