<template lang="pug">
  include ./../../../mixins
  +b.header-menu-item-wrapper.top-link(
    v-if='isDesktop == "desktop"'
  )
    +b.SPAN.header-text.menu-link--iconed.--disabled.--flag {{ language.flag }}
      +b.I.icon.icon-chevronb-down
    +b.DIV.submenu-nav-wrapper
      +b.DIV.submenu-nav
        +b.SPAN.header-text.menu-link(
          v-for='item in languages'
          :value='item.code'
          @click='changeLanguage(item)'
        ) {{ item.name }}
  +b.header-menu-item-wrapper.top-link.top-menu(
    v-else
  )
    +b.SPAN.header-text.menu-link--iconed.--disabled.--flag {{ language.flag }}
      +b.I.icon.icon-chevronb-down
    +b.DIV.submenu-nav-wrapper-mobile
      +b.DIV.submenu-nav-mobile
        +b.SPAN.header-text.menu-link(
          v-for='item in languages'
          :value='item.code'
          @click='changeLanguage(item)'
        ) {{ item.name }}
</template>

<script>
import { prefixLanguage } from '@/js/utils/urls'

export default {
  props: {
    languages: {},
    isDesktop: {},
  },
  data() {
    return {
      language: '',
    }
  },
  mounted() {
    this.setDefaultLanguage()
  },
  methods: {
    changeLanguage(language) {
      const { code } = language
      window.location.assign(
        prefixLanguage(window.location.href, code)
      )
    },
    test() {
      console.log('input')
    },
    setDefaultLanguage() {
      this.languages.forEach(el => {
        if ('true' === el.is_current) {
          this.language = el
        }
      })
    },
  },
}
</script>
