export function formatDateShort(date) {
  // 2020-11-26 to 11.20
  const [year, month] = date.split('-')
  return `${month}.${year[2]}${year[3]}`
}

export function formatDate(date) {
  // 2020-11-26 to 26.11.2020
  const [year, month, day] = date.split('-')
  return `${day}.${month}.${year}`
}

export function getUrlParameter(name) {
  name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]')
  const regex = new RegExp(`[\\?&]${name}=([^&#]*)`)
  const results = regex.exec(window.location.search)
  return null === results ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '))
}

export const hasGetParameter = name => {
  const url = new URL(window.location.href)
  if (url.searchParams.get(`${name}`)) {
    return true
  }
  return false
}

export const getParams = url => {
  const one = 1
  const params = {}
  const parser = document.createElement('a')
  parser.href = url
  const query = parser.search.substring(one)
  const vars = query.split('&')
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=')
    params[pair[0]] = decodeURIComponent(pair[1])
  }
  return params
}

export function getMimeType(file, fallback = null) {
  const byteArray = new Uint8Array(file).subarray(0, 4)
  let header = ''

  for (let i = 0; i < byteArray.length; i++) {
    header += byteArray[i].toString(16)
  }

  switch (header) {
  case '89504e47':
    return 'image/png'
  case '47494638':
    return 'image/gif'
  case 'ffd8ffe0':
  case 'ffd8ffe1':
  case 'ffd8ffe2':
  case 'ffd8ffe3':
  case 'ffd8ffe8':
    return 'image/jpeg'
  default:
    return fallback
  }
}
