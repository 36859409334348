<template lang="pug">
  include /mixins
  +b.decoration
    +e.content
      +b.card.--space_sm.--space_3xl-xl.--radius_sm
        +b.ds-panel.--space_xl.--space_6xl-xl
          +e.element.--offset_bottom
            +b.P.ds-caption.--size_lg.--size_xl-sm.--size_2xl-xl.--weight_bold {{ _("Запропонувати благодійний проєкт") }}
        +b.VALIDATION-OBSERVER(
          ref="validator"
          tag="form"
          v-slot="{ errors: globalErrors }"
          @submit.prevent="prepareData"
        )
          +b.g-row.--appearance_spaced.--space_lg.--space_2xl-xl.--space_sm-vertical-xl
            +b.g-cell.g-cols.--12-xs.--6-sm.--3-lg
              +b.form-inp.--space_none
                +b.input_label {{ _("Ім'я") }} *
                input-group.input_style(
                  error-class="help-type-error--offset_none"
                  rules='required'
                  type="text"
                  id="name"
                  name="name"
                  placeholder=""
                  v-model="formdata.name"
                  :class="{'is-error': globalErrors.name && globalErrors.name.length}"
                )
            +b.g-cell.g-cols.--12-xs.--6-sm.--3-lg
              +b.form-inp.--space_none
                +b.input_label {{ _("Прізвище") }} *
                input-group.input_style(
                  error-class="help-type-error--offset_none"
                  rules='required'
                  type="text"
                  id="surname"
                  name="surname"
                  placeholder=""
                  v-model="formdata.surname"
                  :class="{'is-error': globalErrors.surname && globalErrors.surname.length}"
                )
            +b.g-cell.g-cols.--12-xs.--6-sm.--3-lg
              +b.form-inp.--space_none
                +b.input_label {{ _("Телефон") }} *
                input-group.input_style(
                  error-class="help-type-error--offset_none"
                  rules='required|phone'
                  type="tel"
                  id="phone"
                  name="phone"
                  placeholder=""
                  v-model="formdata.phone"
                  :class="{'is-error': globalErrors.phone && globalErrors.phone.length}"
                )
            +b.g-cell.g-cols.--12-xs.--6-sm.--3-lg
              +b.form-inp.--space_none
                +b.input_label {{ _("Email") }} *
                input-group.input_style(
                  error-class="help-type-error--offset_none"
                  rules='required|email'
                  type="email"
                  id="email"
                  name="email"
                  placeholder=""
                  v-model="formdata.email"
                  :class="{'is-error': globalErrors.email && globalErrors.email.length}"
                )
            +b.g-cell.g-cols.--12-xs
              +b.g-row.--appearance_spaced.--space_lg.--space_2xl-xl.--space_sm-vertical-xl
                +b.g-cell.g-cols.--12-xs.--9-lg
                  +b.form-inp.--space_none
                    +b.input_label {{ _("Опишіть свій проект") }} *
                    validation-provider(
                      id="comment"
                      name="comment"
                      rules="required",
                      v-slot="{ validate, errors }",
                      tag="div"
                      :class="{'is-error': globalErrors.email && globalErrors.email.length}"
                    ).input_style
                      +b.TEXTAREA(
                        ref='comment',
                        rows="8"
                        :disabled='false',
                        placeholder="",
                        v-model="formdata.comment"
                      )
                      +b.P.help-type-error.--offset_none(v-if="errors[0]") {{ errors[0] }}
                +b.g-cell.g-cols.--12-xs.--3-lg
                  +b.project-form-wrapper
                    +b.ds-panel.--space_3xl
                      +e.element.--offset_bottom
                        validation-provider(
                          tag="div"
                          v-slot="{ errors }"
                          name="files"
                        )
                          ui-uploader(
                            ref="uploader",
                            :max-files="5",
                            accepted-files=".pdf, .doc, .docx, .xls, .xlsx, .txt",
                            :max-size="10",
                            v-model="formdata.files"
                          )
                            template(slot="button")
                              +b.control-button.--transparent_black
                                +e.icon
                                  +b.I.icon-paperclip
                                +e.SPAN.content {{ _("Додати файли") }}
                            template(slot="required" v-if="errors[0]")
                              +b.P.help-type-error.--offset_none {{ errors[0] }}
                    div
                      +b.ds-panel.--space_3xl
                        +e.element.--offset_bottom
                          validation-provider(
                            tag="div"
                            v-slot="{ errors }"
                            :rules="{ required: { allowFalse: false } }"
                            name="agreement"
                          )
                            +b.LABEL.control-checkbox.--variant_flex
                              +e.INPUT.element(
                                type="checkbox"
                                v-model="formdata.agreement"
                              )
                              +e.label
                              +e.content.--space_sm-xl
                                span
                                  span {{ _("Згоден із") }}
                                  |&nbsp;
                                  +b.A.ds-link.--color_gray.--size_sm.--inline(
                                    :href="policyUrl"
                                    target="_blank"
                                  ) {{ _("правилами приватності") }}
                            +b.P.help-type-error.--offset_none(v-if="errors[0]") {{ errors[0] }}
                      +b.BUTTON.control-button(
                        @click.prevent='prepareData'
                      ) {{ _("ПРОПОНУЙ СВІЙ ПРОЕКТ") }}
    +e.IMG.item.--variant_1(src="/static/img/decorate-1.png")
    +e.IMG.item.--variant_2(src="/static/img/decorate-2.png")
</template>

<script>
import FormMixin, { defaultValidatorErrorsParser } from '@mixins/SendMixin'
import { businessRequestCreateResource } from '@services/businessRequests.service'
import InfoModal from '@components/Modals/InfoModal'
import { clearPhone } from '@utils/helpers'

export default {
  name: 'ProjectRequestForm',

  mixins: [
    FormMixin,
  ],

  data() {
    return {
      policyUrl: window.policyUrl,
      formdata: {
        name: null,
        surname: null,
        email: null,
        phone: null,
        comment: null,
        files: [],
      },
    }
  },

  methods: {
    updateValidator(errors) {
      const parsed = defaultValidatorErrorsParser(errors)
      this.$refs.validator.setErrors(parsed)
    },

    async prepareData() {
      const isValid = await this.$refs.validator.validate()

      if (!isValid) return

      this.submit(isValid, this.formdata)
    },

    send(data) {
      if (data.phone) {
        data.phone = clearPhone(data.phone)
      }
      data.typeOfRequest = 'project'

      return businessRequestCreateResource.execute({}, data).then(() => {
        this.openMessageModal()
        this.resetForm()
      })
    },

    openMessageModal() {
      this.$modal.show(InfoModal, {
        title: this._('Дякуємо за запит!'),
        information: this._("Ми з'єднаємось із Вами у найближчий час"),
      },
      {
        height: 'auto',
        width: 360,
        adaptive: true,
        scrollable: false,
        transition: 'false',
      })
    },

    resetForm() {
      this.formdata = {}
      this.$refs.uploader.resetFiles()

      this.$nextTick(() => {
        this.$refs.validator.reset()
      })
    },
  },
}
</script>
