<template lang="pug">
  include /mixins
  +b.i-gallery(@click.prevent="openVideoModal")
    +e.content
      +e.icon.--variant_play
        +b.I.icon-play
      +b.ds-aspect-ratio.--appearance_filled.--variant_1
        +e.body
          +b.i-image-wrapper
            +e.IMG.item(:src="preview")
</template>

<script>
import { getVideoId, getVideoPreview } from '@utils/video'

export default {
  props: {
    url: {
      type: String,
    },
  },

  data() {
    return {
      preview: null,
    }
  },

  created() {
    this.generatePreview()
  },

  methods: {
    generatePreview() {
      const id = getVideoId(this.url)

      this.preview = getVideoPreview(id)
    },

    openVideoModal() {
      const modal = () => import('@components/Modals/Video')

      this.$modal.show(modal, {
        video: this.url,
      }, {
        height: 'auto',
        adaptive: true,
        class: 'v--modal-md v--modal-xl',
      })
    },
  },
}
</script>
