<template lang="pug">
  include ./../../../../mixins
  +b.popup-wrapper
    +b.I.icon.icon-bootsrap-x.btn-close-popup(
      @click='$emit("close")'
    )
    +b.P.popup-title {{ _('Відновлення паролю') }}
    +b.P.popup-description.item-description {{ _("Повідомлення з порядком зміни паролю буде відправлено на новий email") }}
    validation-observer(
      ref="validator",
      tag="form",
      class="form-element",
      @submit.prevent='prepareData(valid)',
      v-slot="{ valid, errors: globalErrors }",
    )
      +b.server-error(v-if="serverError") {{ serverError[0] }}
      +b.form-inputs-wrap
        +b.form-inputs
          +b.form-inp
            +b.input_label {{ _("E-mail*") }}
            input-group(
              rules='required|email'
              type="text"
              id="email"
              name="email"
              placeholder=""
              v-model="formData['email']"
              :class="{'is-error': showInputError(globalErrors, 'email')}"
            ).input_style
              template(v-slot:label="")
                span
              template(v-slot:error="{errors}")
                transition(name="fade")
                  span.help-type-error(v-if='errors.length') {{ errors[0] }}
          +b.form-button
            +b.BUTTON.btn-red--popup(
              type="submit",
              @click.prevent='prepareData(valid)'
            ) {{ _("відновити пароль") }}
</template>

<script>

import FormMixin, { defaultValidatorErrorsParser } from '../../../mixins/SendMixin'
import {
  resetPasswordStartResource,
} from '../../../services/users.service'
import InfoModal from '../InfoModal'

export default {
  data() {
    return {
      formData: {
        email: '',
        password: '',
      },
      serverError: '',
    }
  },
  mixins: [
    FormMixin,
  ],
  methods: {
    async prepareData(valid) {
      this.$refs.validator.validate()
      if (!valid) return
      this.submit(valid, {
        email: this.formData.email,
      })
    },
    send(data) {
      return resetPasswordStartResource.execute({}, data)
        .then(({ data: { item } }) => {
          this.$refs.validator.reset()
          console.log(item)
          // якщо все добре, то відкриваємо інформаційний модал
          this.$emit('close')
          this.$modal.show(
            InfoModal,
            {
              title: this._('Майже готово!'),
              information: this._('На вказаний email було направлено листа із вказівками для скидання паролю'),
            },
            {
              height: 'auto',
              width: 360,
              adaptive: true,
              scrollable: false,
              transition: 'false',
            }
          )
        })
    },
    updateValidator(errors) {
      const parsed = defaultValidatorErrorsParser(errors)
      if ('nonFieldErrors' in parsed) {
        this.serverError = parsed.nonFieldErrors
      } else {
        this.serverError = parsed.message
      }
      this.$refs.validator.setErrors(parsed)
      this.isLoading = false
    },
    showInputError(errors, inpName) {
      if (errors[inpName] && 0 < errors[inpName].length) {
        return true
      }
      return false
    },
    resetErrors() {
      this.$refs.validator.reset()
      this.nonFieldErrors = []
      this.serverError = ''
    },
  },
}

</script>
