/* eslint-disable */
/**
 * return tree view ,
 * set 4 spaces before initital string if it has depth
 * @param {string} string - text in select label
 * @param {number} by - depth
 */
import Confirm from '@components/Modals/Confirm'

export function outstand(string, by) {
  if (by < one) {
    return string
  }

  const whitespace = '\u00A0'.repeat(four)
  const marker = ''
  // const marker = '\uFE42'
  // const marker = '\u232A'

  return whitespace.repeat(by) + (by > zero ? marker : '') + string
}

export const clearPhone = phone => phone.replace(/[^+0-9]/g, '')

/**
 * @argument {object} info
 * @argument {object} self
 */
 export function openConfirmModal(info, self) {
  return new Promise(resolve => {
    /**
     * @argument {boolean} arg
     */
    const promise = arg => new Promise(res => {
      if (arg) {
        res(arg)
      }
    }).then(result => {
      resolve(result)
    })
    self.$modal.show(Confirm, {
      info,
      promise,
    }, {
      class: 'v--modal-sm',
      height: 'auto',
      adaptive: true,
    })
  })
}

/**
 * @param {string} path - name of key(
 *  f.e. Object is -{key: {child_key: 'test'}}, path - key, child_key
 * )
 */
export function pathGetter(path) {
  if (is.not.array(path)) {
    path = Array.from(arguments)
  }

  return item => path.reduce((acc, x) => (acc ? acc[x] ? acc[x] : '-' : '-'), item)
}
