<template lang="pug">
  include ./../../../../../mixins
  +b.navigation-global(:class="{'is-active': sidebarIsCollapsed}")
    +b.I.icon.icon-bootsrap-x.btn-close-popup(
      @click='toggleSidebar'
    )
    +b.sidebar-wrapper--global
      +b.cabinet-avatar-wrapper
        +e.img-wrapper
          +e.IMG.image(
            :src='user.avatar'
          )
          +e.btn.btn-avatar(@click="$refs.uploadAvatar.click()")
            +b.I.icon.icon-pencil
        input(
          ref='uploadAvatar'
          class='upload-avatar'
          type="file"
          accept="image/*"
          @change="onChangeAvatar"
        )
      +b.NAV.cabinet-menu-wrapper
        +b.cabinet-menu-section(v-for='menus in navigationList')
          +b.A.cabinet-menu-link(
            v-for='item in menus'
            :href="$router.resolve({ name: item.route }).href"
            active-class="cabinet-menu-link--active"
          )
            +b.cabinet-icon-wrapper
              +b.I.icon.cabinet-icon(:class='item.icon')
            +e.text {{ item.title }}
            +b.messages-count--cabinet(v-if="item.withCounter && totalCounter") {{ totalCounter }}
        +b.SPAN.delete-account-btn(@click='deleteAccount') {{ _('Видалити акаунт') }}
</template>

<script>
import { toggleOverflow } from '@utils/toggleOverflow'
import { mapActions, mapState } from 'vuex'
import {
  userDetailInfoResource,
  userSetAvatarResource,
  userDeactivateResource,
} from '@services/users.service'
import InfoModal from '@components/Modals/InfoModal'
import { openConfirmModal } from '@utils/helpers'
import {
  NAVIGATION_LIST,
} from './navigationList'

export default {
  data() {
    return {
      user: {},
      navigationList: NAVIGATION_LIST(this),
      // sidebarIsCollapsed: false,
    }
  },
  async mounted() {
    await this.getUserInfo()
    console.log(this.sidebarIsCollapsed)
  },
  computed: {
    ...mapState(
      'chat', [
        'totalCounter',
        'sidebarIsCollapsed',
      ]
    ),
  },
  watch: {
    sidebarIsCollapsed: {
      immediate: true,
      deep: true,
      handler(nval) {
        if (nval) {
          // this.toggleSidebar()
          toggleOverflow(this.sidebarIsCollapsed)
        }
      },
    },
  },
  methods: {
    ...mapActions('chat', [
      'updateSidebarIsCollapsed',
    ]),
    toggleSidebar() {
      // this.sidebarIsCollapsed = !this.sidebarIsCollapsed
      this.updateSidebarIsCollapsed(!this.sidebarIsCollapsed)
      toggleOverflow(this.sidebarIsCollapsed)
    },

    async getUserInfo() {
      await userDetailInfoResource.execute({}).then(({ data }) => {
        const { item } = data
        this.user = {
          age: item.age,
          avatar: item.avatar,
          dateOfBirth: item.dateOfBirth,
          email: item.email,
          firstName: item.firstName,
          id: item.id,
          isActive: item.isActive,
          lastName: item.lastName,
          username: item.username,
        }
      })
    },
    async deleteAccount() {
      const info = {
        title: this._('Ви впевнені, що хочете видалити акаунт?'),
      }
      await openConfirmModal(info, this)

      userDeactivateResource.execute().then(() => {
        this.openMessageModal()

        setTimeout(() => {
          window.location = window.indexPageLink
        }, 3000)
      })
    },

    openMessageModal() {
      this.$modal.show(InfoModal, {
        title: this._('Ваш акаунт видалено'),
      },
      {
        height: 'auto',
        width: 360,
        adaptive: true,
        scrollable: false,
        transition: 'false',
      }, {
        'before-close': () => {
          window.location = window.indexPageLink
        },
      })
    },

    onChangeAvatar(e) {
      const file = e.target.files[0]
      const url = URL.createObjectURL(file)
      const fileName = file.name
      const promise = this.getBase64Image(url);
      promise.then(
        dataURL => userSetAvatarResource.execute(
          {},
          { avatar: `${fileName};${dataURL}` }
        )
          .then(({ data }) => {
            const { item } = data
            this.user.avatar = item.avatarUrl
          })
      );
    },

    getBase64Image(url) {
      const promise = new Promise(resolve => {
        const img = new Image();
        img.crossOrigin = 'Anonymous';
        img.onload = () => {
          const canvas = document.createElement('canvas')
          canvas.width = img.width
          canvas.height = img.height
          const ctx = canvas.getContext('2d')
          ctx.drawImage(img, 0, 0)
          const dataURL = canvas.toDataURL('image/png')
          resolve(dataURL)
        };
        img.src = url
      })

      return promise
    },
  },
}
</script>
