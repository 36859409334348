/**
 * @param {string} url
 */
export function getVideoId(url) {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/
  const match = url.match(regExp)
  const eleven = 11
  if (match && match[2].length === eleven) {
    return match[2]
  }
  return 'error'
}

/**
 * @param {string} id
 */
export function getVideoPreview(id) {
  if ('error' === id) {
    return '/static/img/maxresdefault.jpg'
  }

  const videoPreview = `//img.youtube.com/vi/${id}/hqdefault.jpg`
  return videoPreview
}
