<template lang="pug">
  include ./../../../mixins
  +b.items-gallery-wrapper
    +b.items-gallery-image-wrapper(
      v-for='(file, index) in files'
      @click='openGallerySlider(index)'
      :class="{'with-date': withDate}"
    )
      +b.gallery-image
        +e.IMG.image(
          :src='file.src'
          loading="lazy"
        )
      +b.gallery-data(v-if='withDate') {{ file.createdAt }}
</template>

<script>
import {
  galleryOfUserListResource,
} from '../../services/users.service'
import GallerySliderModal from '../Modals/Users/GallerySliderModal'

export default {
  props: {
    userId: {
      type: Number,
      default: 0,
    },
    withDate: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      files: [],
    }
  },
  async mounted() {
    await this.getUserGallery()
  },
  methods: {
    openGallerySlider(index) {
      console.log(index)
      this.$modal.show(
        GallerySliderModal,
        {
          files: this.files,
          initialSlide: index,
        },
        {
          height: '90%',
          // maxHeight: "90%",
          width: '90%',
          adaptive: true,
          scrollable: false,
          transition: 'false',
        }
      )
    },
    async getUserGallery() {
      await galleryOfUserListResource.execute({
        user: this.userId,
      }).then(({ data }) => {
        const { items } = data
        const preparedImages = items.map(el => {
          el.src = el.image
          return el
        })
        this.files = preparedImages
        this.defaultFilesIsSetted = true
      })
    },
  },
}

</script>
