import VueI18n from 'vue-i18n'
import VueRouter from 'vue-router'
import VueSlider from 'vue-slider-component'
import VueSocialSharing from 'vue-social-sharing'
import VueClipboard from 'vue-clipboard2'
import Notifications from 'vue-notification'
import Vuex from 'vuex'
import Viewer from 'v-viewer'

export function install(Vue) {
  Vue.use(VueI18n)
  Vue.use(VueRouter)
  Vue.use(VueSocialSharing)
  Vue.use(VueClipboard)
  Vue.use(Notifications)
  Vue.use(Vuex)
  Vue.use(Viewer, {
    defaultOptions: {
      title: false,
      transition: false,
    },
  })

  Vue.component('range-slider', VueSlider)
}

export default { install }
