import {
  createResource,
} from '@resource/resource'

import { prefixAPI } from '@resource/api'

const COUNTRIES_SEARCH_LIST_LINK = prefixAPI('/countries/{?search,page}', 'countries')
const REGIONS_SEARCH_FILTER_LIST_LINK = prefixAPI('/regions/{?search,page,country}', 'countries')
const CITIES_SEARCH_FILTER_LIST_LINK = prefixAPI('/cities/{?search,page,country,region}', 'countries')

export const countriesSearchResource = createResource(COUNTRIES_SEARCH_LIST_LINK)
export const regionsSearchFilterResource = createResource(REGIONS_SEARCH_FILTER_LIST_LINK)
export const citiesSearchFilterResource = createResource(CITIES_SEARCH_FILTER_LIST_LINK)
