<template lang="pug">
  include ../../../mixins
  +b.g-cell.g-cols--0-lg.txt-end.header-menu-item-wrapper--user.top-link
    template(v-if='userIsAuthent')
      +b.DIV.header-text.menu-link--iconed.--user.--disabled
        +b.I.icon--notransform.user-icon.icon-profile
        span {{ _("Мій акаунт") }}
      +b.DIV.submenu-nav-wrapper
        +b.DIV.submenu-nav--user
          +b.nav-menu-section
            +b.A.menu-link.header-text(
              :href="$router.resolve({ name: CABINET_SETTINGS_NAME }).href"
            ) {{ _("Налаштування профілю") }}
            +b.A.menu-link.header-text(
              :href="$router.resolve({ name: CABINET_GALLERY_NAME }).href"
            ) {{ _("Фотогалерея") }}
            +b.A.menu-link.header-text(
              :href="$router.resolve({ name: CABINET_ANNOUNCEMENT_LIST_NAME }).href"
            ) {{ _("Мої оголошення") }}
            +b.A.menu-link.header-text.full-width.flex-between(
              :href="$router.resolve({ name: CABINET_CHAT_NAME }).href"
            ) {{ _("Повідомлення") }}
              +b.messages-count--user {{ totalCounter }}
          +b.nav-menu-section
            +b.A.header-text.menu-link(
              :href="$router.resolve({ name: CABINET_ANNOUNCEMENT_FAVOURITE_LIST_NAME }).href"
            ) {{ _("Обрані оголошення") }}
            +b.A.header-text.menu-link(
              :href="$router.resolve({ name: CABINET_FAVOURITE_USERS_NAME }).href"
            ) {{ _("Мої симпатії") }}
          logout-btn
    +b.A.btn-red--auth.--big-text(
      v-else
      :href="linkToLogin"
    ) {{ _("Вхід") }}

</template>

<script>
import { mapState } from 'vuex'
import {
  BASE_CABINET_NAME,
  CABINET_SETTINGS_NAME,
  CABINET_FAVOURITE_USERS_NAME,
  CABINET_GALLERY_NAME,
  CABINET_ANNOUNCEMENT_LIST_NAME,
  CABINET_ANNOUNCEMENT_FAVOURITE_LIST_NAME,
  CABINET_CHAT_NAME,
} from '@/consts'

export default {
  data() {
    return {
      linkToLogin: window.loginPageLink,
      userIsAuthent: window.userIsAuthent,
      BASE_CABINET_NAME,
      CABINET_SETTINGS_NAME,
      CABINET_FAVOURITE_USERS_NAME,
      CABINET_GALLERY_NAME,
      CABINET_ANNOUNCEMENT_LIST_NAME,
      CABINET_ANNOUNCEMENT_FAVOURITE_LIST_NAME,
      CABINET_CHAT_NAME,
    }
  },

  computed: {
    ...mapState('chat', ['totalCounter']),
  },
}
</script>
