<template lang="pug">
  include ./../../../mixins
  validation-provider(
    :name="$attrs.name"
    :rules="rules",
    :vid="$attrs.vid"
    v-slot="{ validate, errors }",
    tag="div"
  )
    +b.TEXTAREA(
      :class="className"
      :rows="rows"
      :disabled='$attrs.disabled',
      :placeholder="$attrs.placeholder",
      v-model="innerValue"
    )
    slot(name='error' :errors='errors')
      transition(name="fade")
        +b.SPAN.help-type-error.--offset_none(v-if='errors.length') {{ errors[0] }}

</template>
<script>
export default {
  inheritAttrs: false,

  name: 'TextareaGroup',

  props: {
    rules: {
      type: [Object, String],
      default: '',
    },
    value: {
      type: [String],
    },
    className: {
      type: String,
      default: '',
    },
    rows: {
      default: 8,
    },
  },

  data() {
    return {
      innerValue: '',
    }
  },

  watch: {
    innerValue(newVal) {
      this.$emit('input', newVal)
    },

    value(newVal) {
      this.innerValue = newVal
    },
  },

  created() {
    if (this.value) {
      this.innerValue = this.value
    }
  },
}
</script>
