<template lang="pug">
include /mixins
+b.chat-room-preview(
  @click.prevent="openRoom(item.id)"
  :class="{'is-active': activeRoomId == item.id}"
)
  +e.content
    +b.g-row.--appearance_nowrap.--space_lg.--align_center.--appearance_spaced
      +b.g-cell.g-cols.--narrow
        +b.user-avatar
          +e.IMG.item(:src="item.companionAvatar")
      +b.g-cell.g-cols.--auto
        +b.line-clamp.--one
          +b.P.ds-caption.--color_black.--size_sm {{ item.companionName }} {{ item.companionSurname }}
        +b.P.ds-caption.--color_gray.--size_xs {{ item.lastMessageAt }}
    +e.counter
      +b.i-count(v-if="item.newMessagesCount")
        +e.SPAN.content {{ item.newMessagesCount }}
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  props: {
    item: {
      type: Object,
    },
    activeRoomId: {
      type: Number,
    },
  },
  computed: {
    ...mapState('chat', ['totalCounter']),
  },
  methods: {
    ...mapActions('chat', [
      'updateMessagesCounter',
    ]),
    /**
     * @param {number} id
     */
    openRoom(id) {
      this.$emit('chat:rooms:open', id)
      this.updateMessagesCounter(this.totalCounter - this.item.newMessagesCount)
      this.item.newMessagesCount = 0
    },
  },
}
</script>
