<template lang="pug">
include /mixins
+b.chat-list
  +b.i-preloader.--absolute(v-if="isRoomLoading.value")
    +e.item
  +e.wrapper
    +e.content(
      ref="scroller"
      v-if="rooms.length"
      @scroll="handleScroll"
    )
      chat-room-preview(
        v-for="(item, index) in rooms"
        :key="`${item.id}_${index}`"
        :active-room-id="activeRoomId"
        :item="item"
        @chat:rooms:open="openRoom"
      )

    +e.content.--centered(v-if="!isRoomLoading.value && !rooms.length")
      +b.P.ds-caption.--size_sm.--weight_bold {{ _("Список собеседников пуст") }}
</template>

<script>
import { emitter } from './EventEmmiter'

export default {
  props: {
    rooms: {
      type: Array,
    },
    activeRoomId: {
      type: Number,
    },
    isRoomLoading: {
      type: Object,
    },
  },

  data() {
    return {
      scrollHeightBeforePaginate: null,
    }
  },

  beforeDestroy() {
    emitter.unsubscribe('chat:rooms:update', this.setStickyScroll)
  },

  mounted() {
    emitter.subscribe('chat:rooms:update', this.setStickyScroll)
  },

  methods: {
    handleScroll(e) {
      const { target } = e
      /**
       * check that we have scrolled the list of rooms to the bottom
       */
      if (target.offsetHeight + target.scrollTop >= target.scrollHeight) {
        /**
         * create a new event for pagination by rooms
         */
        this.$emit('chat:rooms:pagination')
        /**
         * save initial scrolling height of the room list before pagination
         */
        const { scroller: { scrollHeight } } = this.$refs
        this.scrollHeightBeforePaginate = scrollHeight
      }
    },

    setStickyScroll() {
      const { scroller } = this.$refs
      this.$nextTick(() => {
        /**
         * scroll to initial position which was before pagination
         */
        scroller.scrollTop = scroller.scrollHeight - this.scrollHeightBeforePaginate
      })
    },

    /**
     * @param {number} id
     */
    openRoom(id) {
      this.$emit('chat:rooms:open', id)
    },
  },
}
</script>
