/* eslint-disable consistent-return */
/* eslint-disable no-restricted-globals */
/* eslint-disable radix */
/* eslint-disable no-empty */
import moment from 'moment'

export const prettifyPrice = p => (p).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '\u00A0')
export const toCurrency = (
  amount,
  hasDecimal = false,
  decimalCount = 2,
  decimal = '.',
  thousands = ' '
) => {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = 0 > amount ? '-' : '';

    const i = parseInt(
      (amount = Math.abs(Number(amount) || 0).toFixed(
        hasDecimal ? decimalCount : 0
      ))
    ).toString();
    const j = 3 < i.length ? i.length % 3 : 0;

    return (
      negativeSign +
      (j ? i.substr(0, j) + thousands : '') +
      i.substr(j).replace(/(\d{3})(?=\d)/g, `$1${thousands}`) +
      (decimalCount && hasDecimal
        ? decimal +
        Math.abs(amount - i)
          .toFixed(decimalCount)
          .slice(2)
        : '')
    );
  } catch (e) { }
};

export const install = Vue => {
  Vue.filter('toCurrency', toCurrency)
  Vue.filter('prettifyPrice', prettifyPrice)
  Vue.filter('formatMomentTimeSeconds', value => moment(value).format('HH:mm:ss'))
};

export default { install };
