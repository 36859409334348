<template lang="pug">
  include ../../../mixins
  +b.nav-menu-section
    +b.SPAN.header-text.menu-link(
      @click='openModal'
    ) {{ _('Вийти') }}

</template>

<script>
import LogoutModal from '../Modals/Users/LogoutModal'

export default {
  data() {
    return {}
  },
  methods: {
    openModal() {
      this.$modal.show(
        LogoutModal,
        {},
        {
          height: 'auto',
          // height: 800,
          width: 345,
          // maxWidth: 544,
          adaptive: true,
          scrollable: false,
          transition: 'false',
        }
      )
    },
  },
}
</script>
