// import BasePage from '@/js/components/Pages/BasePage'
import CabinetGenerallPage from '@/js/components/Pages/Users/Cabinet/CabinetGenerallPage'
import CabinetSettings from '@/js/components/Pages/Users/Cabinet/settings/views/Display'
import CabinetFavouriteUsers from '@/js/components/Pages/Users/Cabinet/CabinetFavouriteUsers'
import CabinetGuests from '@/js/components/Pages/Users/Cabinet/CabinetGuests'
import CabinetGallery from '@/js/components/Pages/Users/Cabinet/CabinetGallery'
import CabinetAnnouncementCreate from '@/js/components/Pages/Users/Cabinet/announcement/views/Create'
import CabinetAnnouncementDisplay from '@/js/components/Pages/Users/Cabinet/announcement/views/Display'
import CabinetAnnouncementList from '@/js/components/Pages/Users/Cabinet/announcement/views/List'
import CabinetAnnouncementFavouriteList from '@/js/components/Pages/Users/Cabinet/announcement/views/FavouriteList'
import CabinetChat from '@/js/components/Pages/Users/Cabinet/chat/views/Display'

import {
  // path
  BASE_CABINET_PATH,
  CABINET_SETTINGS_PATH,
  CABINET_FAVOURITE_USERS_PATH,
  CABINET_GUESTS_PATH,
  CABINET_GALLERY_PATH,
  CABINET_ANNOUNCEMENT_CREATE_PATH,
  CABINET_ANNOUNCEMENT_DISPLAY_PATH,
  CABINET_ANNOUNCEMENT_LIST_PATH,
  CABINET_ANNOUNCEMENT_FAVOURITE_LIST_PATH,
  CABINET_CHAT_PATH,
  // names
  BASE_CABINET_NAME,
  CABINET_SETTINGS_NAME,
  CABINET_FAVOURITE_USERS_NAME,
  CABINET_GUESTS_NAME,
  CABINET_GALLERY_NAME,
  CABINET_ANNOUNCEMENT_CREATE_NAME,
  CABINET_ANNOUNCEMENT_DISPLAY_NAME,
  CABINET_ANNOUNCEMENT_LIST_NAME,
  CABINET_ANNOUNCEMENT_FAVOURITE_LIST_NAME,
  CABINET_CHAT_NAME,
} from '@/consts'

export function createRouter({ Router }) {
  const router = new Router({
    mode: 'history',
    base: '/',
    routes: [
      {
        path: BASE_CABINET_PATH,
        name: BASE_CABINET_NAME,
        component: CabinetGenerallPage,
        redirect: () => CABINET_SETTINGS_PATH,
        children: [
          {
            path: CABINET_SETTINGS_PATH,
            name: CABINET_SETTINGS_NAME,
            component: CabinetSettings,
          },
          {
            path: CABINET_FAVOURITE_USERS_PATH,
            name: CABINET_FAVOURITE_USERS_NAME,
            component: CabinetFavouriteUsers,
          },
          {
            path: CABINET_GUESTS_PATH,
            name: CABINET_GUESTS_NAME,
            component: CabinetGuests,
          },
          {
            path: CABINET_GALLERY_PATH,
            name: CABINET_GALLERY_NAME,
            component: CabinetGallery,
          },
          {
            path: CABINET_ANNOUNCEMENT_CREATE_PATH,
            name: CABINET_ANNOUNCEMENT_CREATE_NAME,
            component: CabinetAnnouncementCreate,
          },
          {
            path: CABINET_ANNOUNCEMENT_DISPLAY_PATH,
            name: CABINET_ANNOUNCEMENT_DISPLAY_NAME,
            component: CabinetAnnouncementDisplay,
            props: true,
          },
          {
            path: CABINET_ANNOUNCEMENT_LIST_PATH,
            name: CABINET_ANNOUNCEMENT_LIST_NAME,
            component: CabinetAnnouncementList,
          },
          {
            path: CABINET_ANNOUNCEMENT_FAVOURITE_LIST_PATH,
            name: CABINET_ANNOUNCEMENT_FAVOURITE_LIST_NAME,
            component: CabinetAnnouncementFavouriteList,
          },
          {
            path: CABINET_CHAT_PATH,
            name: CABINET_CHAT_NAME,
            component: CabinetChat,
          },
        ],
      },
    ],
  })

  return router
}
