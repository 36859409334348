var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form-base auth-form"},[_c('p',{staticClass:"form-title h1_title"},[_vm._v(_vm._s(_vm._("Реєстрація")))]),_c('div',{staticClass:"text-controls-wrapper text-controls-wrapper--fixed-w"},[_c('p',{staticClass:"text-controls"},[_c('span',{staticClass:"text-controls__gray"},[_vm._v(_vm._s(_vm._("Усе майже готово, залишилося вказати email, яку не надала соціальна мережа")))])])]),_c('validation-observer',{ref:"validator",staticClass:"form ",attrs:{"tag":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.prepareData(_vm.valid)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var globalErrors = ref.errors;
return [(_vm.serverError)?_c('div',{staticClass:"server-error"},[_vm._v(_vm._s(_vm.serverError[0]))]):_vm._e(),_c('div',{staticClass:"form-inputs-wrap"},[_c('div',{staticClass:"form-inputs"},[_c('div',{staticClass:"form-inp"},[_c('div',{staticClass:"input_label"},[_vm._v(_vm._s(_vm._('Введіть пошту')))]),_c('input-group',{staticClass:"input_style input_style_auth",class:{'is-error':  _vm.showInputError(globalErrors, 'email')},attrs:{"rules":"required|email","type":"email","id":"email","name":"email","placeholder":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span')]},proxy:true},{key:"error",fn:function(ref){
var errors = ref.errors;
return [_c('transition',{attrs:{"name":"fade"}},[(errors.length)?_c('span',{staticClass:"help-type-error"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])]}}],null,true),model:{value:(_vm.formData['email']),callback:function ($$v) {_vm.$set(_vm.formData, 'email', $$v)},expression:"formData['email']"}}),_c('validation-provider',{staticClass:"check-box-wrapper",attrs:{"rules":"must-be-true","name":"ageAdult","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"control-checkbox control-checkbox--variant_flex control-checkbox--marg-top"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.ageAdult),expression:"formData.ageAdult"}],staticClass:"control-checkbox__element",class:{'is-error':  _vm.showInputError(globalErrors, 'ageAdult')},attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.formData.ageAdult)?_vm._i(_vm.formData.ageAdult,null)>-1:(_vm.formData.ageAdult)},on:{"change":function($event){var $$a=_vm.formData.ageAdult,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.formData, "ageAdult", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.formData, "ageAdult", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.formData, "ageAdult", $$c)}}}}),_c('span',{staticClass:"control-checkbox__label"}),_c('span',{staticClass:"control-checkbox__content control-checkbox__content--space_sm-xl"},[_vm._v(_vm._s(_vm._("Я підтверджую, що на момент реєстрації мені виповнилося 18 років")))])]),(errors[0])?_c('span',{staticClass:"help-type-error help-type-error--register-text"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"form-button"},[_c('button',{staticClass:"btn-red btn-red--confirm",attrs:{"type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.prepareData(valid)}}},[_vm._v(_vm._s(_vm._("Прийняти")))])])])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }