<template lang="pug">
  include /mixins
  +b.popup-wrapper
    +b.I.icon.icon-bootsrap-x.btn-close-popup(
      @click='$emit("close")'
    )
    +b.P.ds-caption.--size_md.--size_lg-xl.--weight_bold(v-if="info.title") {{ info.title }}
    +b.ds-panel--space_lg(v-if="info.text")
      +e.element--offset_top
        +b.P.ds-caption.--size_xs.--color_gray {{ info.text }}
    +b.ds-panel--space_2xl
      +e.element--offset_top
        +b.g-row.--appearance_spaced
          +b.g-cell.g-cols.--6-xs
            +b.BUTTON.control-button(@click.prevent="submit(true)")
              +e.element
                +e.SPAN.text {{ _('ТАК') }}
          +b.g-cell.g-cols.--6-xs
            +b.BUTTON.control-button(@click.prevent="submit(false)")
              +e.element
                +e.SPAN.text {{ _('НІ') }}
</template>

<script>
export default {
  name: 'ConfirmModal',

  props: {
    info: {},
    promise: {},
  },

  methods: {
    submit(trigger) {
      this.promise(trigger)
      this.$emit('close')
    },
  },
}
</script>
