import {
  createResource,
  postResource,
} from '@resource/resource'

import { prefixAPI } from '@resource/api'

const MODEL_PREFIX = 'volunteers'

const PROJECT_CATALOG_LINK = prefixAPI('/projects_ajax_list/{?page}', MODEL_PREFIX)
const PROJECT_REQUEST_LINK = prefixAPI('/request/', MODEL_PREFIX)

export const projectCatalogResource = createResource(PROJECT_CATALOG_LINK)
export const projectRequestResource = createResource(PROJECT_REQUEST_LINK, postResource)
