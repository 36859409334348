import {
  createResource,
  postResource,
  patchResource,
} from '@resource/resource'
import {
  simpleTransformer,
} from '@resource/transformer'

import { prefixAPI } from '@resource/api'

const AUTH_LINK = prefixAPI('/auth/login/', 'users')
const LOGOUT_LINK = prefixAPI('/auth/logout/', 'users')
const SOCIAL_INPUT_EMAIL_LINK = prefixAPI('/auth/soc_input_email/', 'users')
const SOCIAL_CONFIRM_EMAIL_LINK = prefixAPI('/auth/soc_confirm_email/', 'users')
const SOCIAL_CONFIRM_AGE = prefixAPI('/auth/soc_confirm_age/', 'users')
const RESET_PASSWORD_START_LINK = prefixAPI('/auth/reset_password_request/', 'users')
const RESET_PASSWORD_CONFIRM_LINK = prefixAPI('/auth/reset_password_confirm/', 'users')
const REGISTRATION_LINK = prefixAPI('/auth/registration/', 'users')

const USER_BASE_INFO_LINK = prefixAPI('/user_base_info/', 'users')
const USER_DETAIL_INFO_LINK = prefixAPI('/user_detail_info/', 'users')
const USER_DETAIL_WITH_CITY_INFO_LINK = prefixAPI('/user_detail_wcity_info/', 'users')

const USER_SET_AVATAR_LINK = prefixAPI('/user_set_avatar/', 'users')
const USER_SET_EMAIL_LINK = prefixAPI('/user_set_email/', 'users')
const USER_SET_PASSWORD_LINK = prefixAPI('/user_set_password/', 'users')
const USER_ATTRIBUTES = prefixAPI('/create-update-attributes/', 'users')

const USER_UPDATE_BASE_DATA_LINK = prefixAPI('/user_update_base_data/', 'users')
const USER_UPDATE_CATALOG_DATA_LINK = prefixAPI('/user_update_catalog_data/', 'users')
const USER_DEACTIVATE_LINK = prefixAPI('/deactivate_user/', 'users')

const GALLERY_OF_USER_LIST_LINK = prefixAPI('/gallery_of_user/{user}/', 'users')
const GALLERY_OF_USER_LIST_UPDATE_LINK = prefixAPI('/gallery_of_user_update/', 'users')
const FAVOURITE_USERS_OF_USER_LIST_LINK = prefixAPI('/favourite_users_of_user/{?offset,limit}', 'users')
const FAVOURITE_USERS_APPEND_REMOVE_LINK = prefixAPI('/favourite_user_append_remove/', 'users')
const BAN_USERS_APPEND_REMOVE_LINK = prefixAPI('/ban_user_append_remove/', 'users')
const GUESTS_OF_USER_LINK = prefixAPI('/guests_of_user/{?offset,limit}', 'users')
const GUESTS_CLEAN_HISTORY_LINK = prefixAPI('/guests_clean_history/', 'users')

export const loginResource = createResource(AUTH_LINK, postResource)
export const logoutResource = createResource(
  LOGOUT_LINK, postResource, simpleTransformer
)
export const socialInputEmailResource = createResource(
  SOCIAL_INPUT_EMAIL_LINK, postResource
)
export const socialConfirmEmailResource = createResource(
  SOCIAL_CONFIRM_EMAIL_LINK, postResource
)
export const socialConfirmAgeResource = createResource(
  SOCIAL_CONFIRM_AGE, postResource
)
export const resetPasswordStartResource = createResource(
  RESET_PASSWORD_START_LINK, postResource
)
export const resetPasswordConfirmResource = createResource(
  RESET_PASSWORD_CONFIRM_LINK, postResource
)
export const registrationResource = createResource(
  REGISTRATION_LINK, postResource
)

export const userAttributesResource = createResource(USER_ATTRIBUTES)

export const userBaseInfoResource = createResource(
  USER_BASE_INFO_LINK
)
export const userDetailInfoWithCityResource = createResource(
  USER_DETAIL_WITH_CITY_INFO_LINK
)
export const userDetailInfoResource = createResource(
  USER_DETAIL_INFO_LINK
)
export const userSetAvatarResource = createResource(
  USER_SET_AVATAR_LINK, postResource
)
export const userSetEmailResource = createResource(
  USER_SET_EMAIL_LINK, postResource
)
export const userSetPasswordResource = createResource(
  USER_SET_PASSWORD_LINK, postResource
)
export const userUpdateBaseDataResource = createResource(
  USER_UPDATE_BASE_DATA_LINK, postResource
)
export const userUpdateCatalogDataResource = createResource(
  USER_UPDATE_CATALOG_DATA_LINK, postResource
)

export const galleryOfUserListResource = createResource(
  GALLERY_OF_USER_LIST_LINK
)
export const galleryOfUserListUpdateResource = createResource(
  GALLERY_OF_USER_LIST_UPDATE_LINK, patchResource
)
export const favouriteUsersOfUserResource = createResource(
  FAVOURITE_USERS_OF_USER_LIST_LINK
)
export const favouriteUserAppendRemoveResource = createResource(
  FAVOURITE_USERS_APPEND_REMOVE_LINK, postResource
)
export const banUserAppendRemoveResource = createResource(
  BAN_USERS_APPEND_REMOVE_LINK, postResource
)
export const guestsOfUserResource = createResource(
  GUESTS_OF_USER_LINK
)
export const guestsCleanHistoryResource = createResource(
  GUESTS_CLEAN_HISTORY_LINK, postResource
)
export const userDeactivateResource = createResource(
  USER_DEACTIVATE_LINK, postResource
)
