import AuthPage from '@/js/components/Pages/Users/AuthPage'
import SocialInputEmail from '@/js/components/Pages/Users/SocialInputEmail'
import SocialConfirmAge from '@/js/components/Pages/Users/SocialConfirmAge'
import RestorePasswordFinishPage from '@/js/components/Pages/Users/RestorePasswordFinishPage'
import RegistrationPage from '@/js/components/Pages/Users/RegistrationPage'
import FavouriteUserController from '@/js/components/Pages/Users/FavouriteUserController'
import BanUserController from '@/js/components/Pages/Users/BanUserController'
import ChatUserController from '@/js/components/Pages/Users/ChatUserController'
import UserPreviewCard from '@/js/components/Pages/Users/UserPreviewCard'
import ChatElements from '@/js/components/Chat'
import SiteSidebar from '@/js/components/Pages/Users/Cabinet/SiteSidebar'

export function install(Vue) {
  Vue.component('AuthPage', AuthPage) // зареєстрували компонент у системі
  Vue.component('SocialInputEmail', SocialInputEmail)
  Vue.component('SocialConfirmAge', SocialConfirmAge)
  Vue.component('RestorePasswordFinishPage', RestorePasswordFinishPage)
  Vue.component('RegistrationPage', RegistrationPage)
  Vue.component('FavouriteUserController', FavouriteUserController)
  Vue.component('BanUserController', BanUserController)
  Vue.component('ChatUserController', ChatUserController)
  Vue.component('UserPreviewCard', UserPreviewCard)
  Vue.use(ChatElements)
  Vue.component('SiteSidebar', SiteSidebar)
}

export default { install }
