<template lang="pug">
  include /mixins
  +b.form-base.cabinet-settings-form
    +b.i-preloader.--absolute(v-if="isRequest")
      +e.item
    validation-observer(
      ref="validator"
      tag="form"
      class="form-element-hidden"
      v-slot="{ errors: globalErrors }"
    )
      +b.form-top-info
        +b.form-title-wrapper
          +b.P.h1_title--cabinet {{ _("Додати оголошення") }}
      +b.form-spliter-wrapper
        +b.form-spliter

      div(v-if="currentStep === 1")
        +b.ds-panel.--space_xl.--space_4xl-xl
          +e.element.--offset_bottom
            +b.P.ds-caption.--size_lg.--size_2lg-lg.--size_xl-xl.--weight_bold {{ _("Крок 1") }}

        announcement-main-info(
          :formdata="formdata"
          :choices="choices"
          :global-errors="globalErrors"
        )

      div(v-if="currentStep === 2")
        +b.ds-panel.--space_xl.--space_4xl-xl
          +e.element.--offset_bottom
            +b.P.ds-caption.--size_lg.--size_2lg-lg.--size_xl-xl.--weight_bold {{ _("Крок 2") }}

        announcement-attributes(
          :formdata="formdata"
          :choices="choices"
          :exchange-rates="exchangeRates"
          :global-errors="globalErrors"
        )

      div(v-if="currentStep === 3 && choices.staticAttributes.withGallery")
        +b.ds-panel.--space_xl.--space_4xl-xl
          +e.element.--offset_bottom
            +b.P.ds-caption.--size_lg.--size_2lg-lg.--size_xl-xl.--weight_bold {{ _("Крок 3") }}

        announcement-gallery(
          :formdata="formdata"
          :preview-is-required="choices.staticAttributes.previewIsRequired"
          :global-errors="globalErrors"
        )

      div(v-if="currentStep === 4")
        +b.ds-panel.--space_xl.--space_4xl-xl
          +e.element.--offset_bottom
            +b.P.ds-caption.--size_lg.--size_2lg-lg.--size_xl-xl.--weight_bold {{ choices.staticAttributes.withGallery ? _("Крок 4") : _("Крок 3") }}

        announcement-contact-info(
          :formdata="formdata"
          :global-errors="globalErrors"
        )

      +b.ds-panel.--space_sm.--space_6xl-xl
        +e.element.--offset_top
          +b.g-row.--appearance_spaced.--justify_between
            +b.g-cell.g-cols.--6-xs.--4-sm.--3-md.--2-xl
              +b.BUTTON.control-button(
                v-if="currentStep !== 1"
                @click.prevent="goToPreviousStep"
              )
                +e.element
                  +e.SPAN.text {{ _("НАЗАД") }}
            +b.g-cell.g-cols.--12-xs.--narrow-sm(v-if="currentStep === 4")
              +b.g-row.--appearance_spaced
                +b.g-cell.g-cols.--12-xs.--narrow-sm
                  +b.BUTTON.control-button.--transparent_black(@click.prevent="prepareData('save')")
                    +e.element
                      +e.SPAN.text {{ _("ЗБЕРЕГТИ БЕЗ ПУБЛІКАЦІЇ") }}
                +b.g-cell.g-cols.--12-xs.--narrow-sm
                  +b.BUTTON.control-button(@click.prevent="prepareData('publication')")
                    +e.element
                      +e.SPAN.text {{ _("ЗБЕРЕГТИ Й ОПУБЛІКУВАТИ") }}
            +b.g-cell.g-cols.--6-xs.--4-sm.--3-md.--2-xl(v-else)
              +b.BUTTON.control-button(@click.prevent="prepareData('next')")
                +e.element
                  +e.SPAN.text {{ _("ДАЛІ") }}
</template>

<script>
import {
  announcementCreateResource,
  announcementAttributesResource,
  announcementCategoriesResource,
  exchangeRatesAdaptiveResource,
} from '@services/announcement.service'
import InfoModal from '@components/Modals/InfoModal'
import { clearPhone } from '@utils/helpers'
import FormMixin, { defaultValidatorErrorsParser } from '@mixins/SendMixin'
import { userDetailInfoResource } from '@services/users.service'
import { CABINET_ANNOUNCEMENT_LIST_NAME } from '@/consts'

const one = 1

export default {
  components: {
    'announcement-main-info': () => import('../components/MainInfo'),
    'announcement-attributes': () => import('../components/Attributes'),
    'announcement-gallery': () => import('../components/Gallery'),
    'announcement-contact-info': () => import('../components/ContactInfo'),
  },

  mixins: [
    FormMixin,
  ],

  data() {
    return {
      formdata: {
        country: {},
        region: {},
        city: {},
        status: {},
        attributes: {},
        gallery: [],
        sterlingPrice: null,
        dollarPrice: null,
        euroPrice: null,
        nameAuthor: null,
        emailAuthor: null,
      },
      currentStep: 1,
      choices: {
        category: [],
        attributes: [],
        priceUnit: [],
        staticAttributes: {},
      },
      exchangeRates: {
        sterling: {},
        euro: {},
        dollar: {},
      },
      isRequest: false,
    }
  },

  async created() {
    this.isRequest = true

    await this.getCategories()
    await this.getRates()

    this.getUserInfo()

    this.isRequest = false
  },

  methods: {
    async getUserInfo() {
      await userDetailInfoResource.execute({}).then(res => {
        const { data: { item } } = res

        this.setUserInfo(item)
      })
    },

    setUserInfo(data) {
      const nameAuthor = [data.firstName, data.lastName]

      this.formdata.nameAuthor = nameAuthor.join(' ')
      this.formdata.emailAuthor = data.email
    },

    async getCategories() {
      await announcementCategoriesResource.execute().then(res => {
        const { data } = res

        this.choices.category = data
      })
    },

    getLastCategoryChildLabel() {
      const { categoryThird, categorySecond, categoryFirst } = this.formdata

      if (categoryThird) {
        return categoryThird
      }

      if (categorySecond) {
        return categorySecond
      }

      return categoryFirst
    },

    async getAttributes() {
      this.formdata.category = this.getLastCategoryChildLabel()

      const params = { category: this.formdata.category.label }

      await announcementAttributesResource.execute(params).then(res => {
        const { data: { attributes, dynamicAttributes } } = res

        this.choices.attributes = dynamicAttributes.map(el => el.props)
        this.choices.staticAttributes = attributes

        this.setDefaultAttributesStructure()
        this.groupAttributesByType()
        this.getPeriod()
      })
    },

    groupAttributesByType() {
      this.choices.attributes = this.choices.attributes.reduce((acc, item) => {
        if (!acc[item.createWidgetType]) {
          acc[item.createWidgetType] = [item]
        } else {
          acc[item.createWidgetType].push(item)
        }

        return acc
      }, {})
    },

    getPeriod() {
      const periodAttr = this.choices.staticAttributes.items.find(attr => 'price_unit' === attr.props.label)

      if (periodAttr) {
        this.choices.priceUnit = periodAttr.props.values.map(el => el.props)
      }
    },

    setDefaultAttributesStructure() {
      this.choices.attributes.forEach(item => {
        const val = 'checkbox' === item.createWidgetType ? [] : null
        const { label } = item.attribute.props

        if (!this.formdata.attributes[label]) {
          this.$set(this.formdata.attributes, label, val)
        }
      })
    },

    async getRates() {
      await exchangeRatesAdaptiveResource.execute().then(res => {
        const { data: { item } } = res

        this.exchangeRates = item
      })
    },

    goToPreviousStep() {
      if (4 === this.currentStep && !this.choices.staticAttributes.withGallery) {
        this.currentStep = 2
      } else {
        this.currentStep -= one
      }

      this.scrollTop()
    },

    goToNextStep() {
      this.currentStep += one

      if (2 === this.currentStep) {
        this.getAttributes()
      }

      this.scrollTop()
    },

    prepareAttributes(attributes) {
      return Object.keys(attributes).reduce((acc, key) => {
        if (attributes[key]) {
          acc[key] = attributes[key]
        }

        return acc
      }, {})
    },

    openNextStep(event) {
      if (2 === this.currentStep && !this.choices.staticAttributes.withGallery) {
        this.currentStep = 4
      } else if (4 === this.currentStep) {
        this.submit(true, event)
      } else {
        this.goToNextStep()
      }
    },

    updateValidator(errors) {
      const parsed = defaultValidatorErrorsParser(errors)
      this.$refs.validator.setErrors(parsed)
    },

    async prepareData(event) {
      const isValid = await this.$refs.validator.validate()

      if (isValid) {
        this.openNextStep(event)
      } else {
        this.scrollTop()
      }
    },

    scrollTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    },

    prepareFormdata(data, status) {
      const formdata = JSON.parse(JSON.stringify(data))
      if (formdata.priceUnit) {
        formdata.priceUnit = formdata.priceUnit.label
      }
      formdata.city = formdata.city.slug

      if (formdata.region && formdata.region.slug) {
        formdata.region = formdata.region.slug
      } else {
        delete formdata.region
      }

      formdata.country = formdata.country.slug
      formdata.category = formdata.category.id
      if (formdata.phoneAuthor) {
        formdata.phoneAuthor = clearPhone(formdata.phoneAuthor)
      }
      formdata.phoneAuthor = ''
      formdata.attributes = this.prepareAttributes(formdata.attributes)

      if (!this.choices.staticAttributes.withPrice) {
        delete formdata.sterlingPrice
        delete formdata.euroPrice
        delete formdata.dollarPrice
      }

      if ('publication' === status) {
        formdata.forPublish = true
      } else if ('save' === status) {
        formdata.forPublish = false
      }

      delete formdata.categoryThird
      delete formdata.categorySecond
      delete formdata.categoryFirst

      return formdata
    },

    send(status) {
      const formdata = this.prepareFormdata(this.formdata, status)

      return announcementCreateResource.execute({}, formdata).then(() => {
        const savedInformation = this._('Ваше оголошення бережено без публікації!')
        const publicatedInformation = this._('Ваше оголошення опубліковано!')
        const information = formdata.forPublish ? publicatedInformation : savedInformation

        this.openMessageModal(information)
      })
    },

    openMessageModal(information) {
      this.$modal.show(InfoModal, {
        title: this._('Дякуємо!'),
        information,
      },
      {
        height: 'auto',
        width: 360,
        adaptive: true,
        scrollable: false,
        transition: 'false',
      }, {
        'before-close': () => {
          this.$router.push({ name: CABINET_ANNOUNCEMENT_LIST_NAME })
        },
      })
    },
  },
}
</script>
