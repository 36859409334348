<template lang="pug">
  include ../../../mixins
  +b.i-like(@click="toggleLike")
    +e.icon(:class="iconClass")
      +b.I.icon(
        :class="[{ 'is-active': isLikedLocal }, isLikedLocal ? 'icon-empty-heart' : 'icon-heart']"
      )
    +e.SPAN.content(v-if="displayText") {{ isLikedLocal ? _("прибрати із симпатій") : _("додати до симпатій") }}
</template>

<script>
import InfoModal from '@components/Modals/InfoModal'
import {
  announcementFavouriteAppendRemoveResource,
} from '@services/announcement.service'

const resources = {
  announcement: announcementFavouriteAppendRemoveResource,
}

export default {
  props: {
    itemId: {
      type: [String, Number],
      default: null,
    },
    isLiked: {
      type: Boolean,
      default: false,
    },
    iconClass: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    displayText: {
      type: Boolean,
      default: false,
    },
    itemKey: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      isLikedLocal: this.isLiked,
    }
  },

  methods: {
    toggleLike() {
      const action = this.isLikedLocal ? 'delete' : 'append'
      const formdata = { [this.itemKey]: this.itemId, action }
      const resource = resources[this.label]

      resource.execute({}, formdata).then(() => {
        this.isLikedLocal = !this.isLikedLocal

        this.$emit(action, this.itemId)
      }).catch(async error => {
        if (403 === error.status) {
          const message = this._('Необхідно авторизуватися')
          const btnTitle = this._('Авторизуватися')
          this.openModal(message, btnTitle, window.loginPageLink)
        }
      })
    },
    openModal(title, btnTitle, link) {
      this.$modal.show(InfoModal, {
        title,
        btnTitle,
        btnLink: link,
      }, {
        height: 'auto',
        width: 360,
        adaptive: true,
        scrollable: false,
        transition: 'false',
      })
    },
  },
}
</script>
