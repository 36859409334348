import { prefixLanguage } from '@/js/utils/urls'

export const BASE_CABINET_PATH = prefixLanguage('/cabinet/')
// const cabinetUrl = path => BASE_CABINET_LINK + path
// export const BASE_CABINET_PATH = cabinetUrl()
// export const CABINET_SETTINGS_PATH = cabinetUrl('settings/')
// export const CABINET_FAVOURITE_USERS_PATH = cabinetUrl('favourite-users/')
// export const CABINET_GALLERY_PATH = cabinetUrl('gallery/')
export const CABINET_SETTINGS_PATH = `${BASE_CABINET_PATH}settings/`
export const CABINET_FAVOURITE_USERS_PATH = `${BASE_CABINET_PATH}favourite-users/`
export const CABINET_GUESTS_PATH = `${BASE_CABINET_PATH}guests/`
export const CABINET_GALLERY_PATH = `${BASE_CABINET_PATH}gallery/`
export const CABINET_ANNOUNCEMENT_CREATE_PATH = `${BASE_CABINET_PATH}announcement/create/`
export const CABINET_ANNOUNCEMENT_DISPLAY_PATH = `${BASE_CABINET_PATH}announcement/:id/edit/`
export const CABINET_ANNOUNCEMENT_LIST_PATH = `${BASE_CABINET_PATH}announcement/list/`
export const CABINET_ANNOUNCEMENT_FAVOURITE_LIST_PATH = `${BASE_CABINET_PATH}announcement/favourite/list/`
export const CABINET_CHAT_PATH = `${BASE_CABINET_PATH}chat/`

export const BASE_PATH = '/'

// all names

export const BASE_CABINET_NAME = 'cabinet'
export const CABINET_SETTINGS_NAME = 'cabinet-settings'
export const CABINET_FAVOURITE_USERS_NAME = `${BASE_CABINET_NAME}-favourite-users`
export const CABINET_GUESTS_NAME = `${BASE_CABINET_NAME}-guests`
export const CABINET_GALLERY_NAME = `${BASE_CABINET_NAME}-gallery`
export const CABINET_ANNOUNCEMENT_CREATE_NAME = `${BASE_CABINET_NAME}-announcement-create`
export const CABINET_ANNOUNCEMENT_DISPLAY_NAME = `${BASE_CABINET_NAME}-announcement-display`
export const CABINET_ANNOUNCEMENT_LIST_NAME = `${BASE_CABINET_NAME}-announcement-list`
export const CABINET_ANNOUNCEMENT_FAVOURITE_LIST_NAME = `${BASE_CABINET_NAME}-announcement-favourite-list`
export const CABINET_CHAT_NAME = `${BASE_CABINET_NAME}-chat`
