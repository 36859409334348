import {
  createResource,
} from '@resource/resource'

import { prefixAPI } from '@resource/api'

const MODEL_PREFIX = 'banners'

const CABINET_BANNERS_LINK = prefixAPI('/cabinet_banners/{cabinet}/{?offset,limit}', MODEL_PREFIX)

export const cabinetBannersResource = createResource(CABINET_BANNERS_LINK)
