<template lang="pug">
  include ./../../../../mixins
  +b.user-item-wrapper(
    v-if='user'
  )
    +b.user-card.base-block-item
      +e.wrapper
        +e.heading
          +b.user-item-preview-image
            +e.IMG.image(
              :src='user.avatar'
              loading="lazy"
            )
        +e.content
          +b.user-card-info
            +e.A.name(
              :href='user.url'
            ) {{ user.firstName }} {{ user.lastName }}
            +b.location-wrapper(v-if="user.location")
              +b.I.location-icon.icon.icon-marker
              +b.SPAN.location-info {{ user.location }}
            +b.age-reason-wrapper
              +e.P.item {{`${_("Вік: ")} ${ user.age } ${_(" р.")}` }}
              +e.P.item(v-for='attr in user.attributes') {{ `${attr.title}: ${attributeValuesToString(attr.values)}` }}
            +b.is-hidden.is-visible-md
              chat-user-controller(
                :user-id="user.id",
                :is-banned="user.is_blocked"
                style-version="text"
              )
            favourite-user-controller.favourite-control-wrapper--variant_1(
              :userId='user.id',
              :isFavourite='user.isFavourite'
              :index='index',
              :with-label='false'
              @deleteFromFavouritesEvent='ondeleteFromFavourites'
            )
      +e.additional
        chat-user-controller(
          :user-id="user.id",
          :is-banned="user.is_blocked"
          style-version="text"
        )
</template>

<script>

export default {
  props: {
    user: {},
    index: {},
  },
  data() {
    return {}
  },
  methods: {
    ondeleteFromFavourites({ index }) {
      this.$emit('deleteFromFavouritesEventCard', {
        index,
      })
    },
    attributeValuesToString(values) {
      let result = ''
      let index = 1
      if (1 < values.length) {
        values.forEach(value => {
          if (index === values.length) {
            result += value.props.title
          } else {
            result += `${value.props.title}, `
          }
          index++
        })
      } else {
        result = values[0].props.title
      }
      return result
    },
  },
}
</script>
