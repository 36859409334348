export function createStore() {
  return {
    namespaced: true,

    state: {
      totalCounter: 0,
      sidebarIsCollapsed: false,
    },

    actions: {
      async updateMessagesCounter({ commit }, data) {
        commit('CHANGE_MESSAGES_COUNTER', data)
      },
      async updateSidebarIsCollapsed({ commit }, data) {
        commit('CHANGE_SIDEBAR_IS_COLLAPSED', data)
      },
    },

    mutations: {
      CHANGE_MESSAGES_COUNTER(state, result) {
        state.totalCounter = result
      },
      CHANGE_SIDEBAR_IS_COLLAPSED(state, result) {
        state.sidebarIsCollapsed = result
      },
    },
  }
}
