import {
  baseResource,
  createResource,
  postResource,
  patchResource,
} from '@resource/resource'
import { friendlyUrlGenerator } from '@utils/friendly'
import { prefixAPI } from '@resource/api'

const ANNOUNCEMENT_LIST_URL_PREFIX = prefixAPI('/announcements/catalog')
const ANNOUNCEMENT_LIST_URL_POSTFIX = 'ajax/filters'

const ANNOUNCEMENT_FILTERS = prefixAPI('announcements/catalog-attributes/{?category}')
const ANNOUNCEMENT_FAVOURITE_APPEND_REMOVE = prefixAPI('/favourite_announcement_append_remove/', 'users')
const ANNOUNCEMENT_CREATE = prefixAPI('/create/', 'announcements')
const ANNOUNCEMENT_UPDATE = prefixAPI('/update/{uuid}/', 'announcements')
const ANNOUNCEMENT_DETAIL = prefixAPI('/details/{uuid}/', 'announcements')
const ANNOUNCEMENT_ATTRIBUTES = prefixAPI('/create-update-attributes/{?category}', 'announcements')
const ANNOUNCEMENT_CATEGORIES = prefixAPI('/categories/list/', 'announcements')
const ANNOUNCEMENT_LIST_COMMON = prefixAPI('/announcements_of_user/{?offset,limit,status}', 'users')
const ANNOUNCEMENT_LIST_FAVOURITE = prefixAPI('/favourite_announcements_of_user/{?offset,limit}', 'users')
const ANNOUNCEMENT_DEACTIVATE = prefixAPI('/deactivate/', 'announcements')
const ANNOUNCEMENT_COMPLAINT = prefixAPI('/create_complaint/', 'announcements')
const EXCHANGE_RATES = prefixAPI('/price_currencies/', 'announcements')
const EXCHANGE_RATES_ADAPTIVE = prefixAPI('/adaptive_price_currencies/', 'announcements')

const orderAdditional = {
  path: [
    'prefix',
    'label',
    'postfix',
    'filters',
  ],
  query: [
    'pagination_offset',
  ],
}

export const announcementListResource = friendlyUrlGenerator([
  ANNOUNCEMENT_LIST_URL_PREFIX,
  ANNOUNCEMENT_LIST_URL_POSTFIX,
], baseResource, orderAdditional)

export const announcementFilterResource = createResource(ANNOUNCEMENT_FILTERS)

export const announcementCreateResource = createResource(ANNOUNCEMENT_CREATE, postResource)
export const announcementUpdateResource = createResource(ANNOUNCEMENT_UPDATE, patchResource)
export const announcementFavouriteAppendRemoveResource = createResource(ANNOUNCEMENT_FAVOURITE_APPEND_REMOVE, postResource)
export const announcementAttributesResource = createResource(ANNOUNCEMENT_ATTRIBUTES)
export const announcementCategoriesResource = createResource(ANNOUNCEMENT_CATEGORIES)
export const announcementDetailResource = createResource(ANNOUNCEMENT_DETAIL)
export const announcementListCommonResource = createResource(ANNOUNCEMENT_LIST_COMMON)
export const announcementListFavouriteResource = createResource(ANNOUNCEMENT_LIST_FAVOURITE)
export const announcementDeactivateResource = createResource(ANNOUNCEMENT_DEACTIVATE, postResource)
export const announcementCreateComplaintResource = createResource(ANNOUNCEMENT_COMPLAINT, postResource)
export const exchangeRatesResource = createResource(EXCHANGE_RATES)
export const exchangeRatesAdaptiveResource = createResource(EXCHANGE_RATES_ADAPTIVE)
