import {
  postResource,
  createResource,
} from '@resource/resource'

import { prefixAPI } from '@resource/api'

const MODEL = 'chat'

const ROOMS_LIST = prefixAPI('/rooms/list/{?limit,offset}', MODEL)
const ROOM_INITIALIZE = prefixAPI('/room/initialize/', MODEL)
const ROOM_DELETE = prefixAPI('/delete_room/', MODEL)
const MESSAGES_LIST = prefixAPI('/messages/{id}/list/{?limit,offset}', MODEL)
const MESSAGES_COUNTER = prefixAPI('/count_of_new_messages/', MODEL)
const MESSAGE_SEND = prefixAPI('/message/send/', MODEL)
const MESSAGES_READ = prefixAPI('/read_messages/', MODEL)
const MESSAGE_DELETE = prefixAPI('/delete_message/', MODEL)

export const roomsList = createResource(ROOMS_LIST)
export const roomInitialize = createResource(ROOM_INITIALIZE, postResource)
export const roomDelete = createResource(ROOM_DELETE, postResource)
export const messagesList = createResource(MESSAGES_LIST)
export const messagesCounter = createResource(MESSAGES_COUNTER)
export const messageSend = createResource(MESSAGE_SEND, postResource)
export const messagesRead = createResource(MESSAGES_READ, postResource)
export const messageDelete = createResource(MESSAGE_DELETE, postResource)
