import {
  CABINET_SETTINGS_NAME,
  CABINET_FAVOURITE_USERS_NAME,
  CABINET_GUESTS_NAME,
  CABINET_GALLERY_NAME,
  CABINET_ANNOUNCEMENT_CREATE_NAME,
  CABINET_ANNOUNCEMENT_LIST_NAME,
  CABINET_ANNOUNCEMENT_FAVOURITE_LIST_NAME,
  CABINET_CHAT_NAME,
} from '@/consts'

export const NAVIGATION_LIST = context => [
  [
    {
      title: context._('Мій профайл'),
      icon: 'icon-cabinet-profile',
      route: CABINET_SETTINGS_NAME,
    },
  ],
  [
    {
      title: context._('Мої оголошення'),
      icon: 'icon-cabinet-advertisement',
      route: CABINET_ANNOUNCEMENT_LIST_NAME,
    },
    {
      title: context._('Обрані оголошення'),
      icon: 'icon-cabinet-favourite-advertisement',
      route: CABINET_ANNOUNCEMENT_FAVOURITE_LIST_NAME,
    },
    {
      title: context._('Додати оголошення'),
      icon: 'icon-cabinet-create-advertisement',
      route: CABINET_ANNOUNCEMENT_CREATE_NAME,
    },
  ],
  [
    {
      title: context._('Мої симпатії'),
      icon: 'icon-heart',
      route: CABINET_FAVOURITE_USERS_NAME,
    },
    {
      title: context._('Повідомлення'),
      icon: 'icon-message-circle',
      route: CABINET_CHAT_NAME,
      withCounter: true,
    },
    {
      title: context._('Гості сторінки'),
      icon: 'icon-profile',
      route: CABINET_GUESTS_NAME,
    },
    {
      title: context._('Галерея'),
      icon: 'icon-cabinet-gallery',
      route: CABINET_GALLERY_NAME,
    },
  ],
]

export default NAVIGATION_LIST
