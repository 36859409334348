<template lang="pug">
  include /mixins
  +b.input-wrapper.--prepend_icon.--variant_styled-xl
    +e.icon.--variant_1
      +b.I.icon-marker
    +b.g-row.--appearance_spaced
      +b.g-cell.g-cols.--12-xs.--4-xl
        autocomplete-widget(
          v-model="formdata.country"
          ref="country"
          input-class="multiselect--variant_primary-xl multiselect--variant_country multiselect--variant_1"
          :need-initial-receive="true"
          :filter="autocomplete.country"
          @input="countryChanged"
        )
      +b.g-cell.g-cols.--12-xs.--4-xl
        autocomplete-widget(
          v-model="formdata.region"
          ref="region"
          input-class="multiselect--variant_primary-xl multiselect--variant_region multiselect--variant_1"
          :params="{ country: formdata.country.slug || null }"
          :filter="autocomplete.region"
          @input="regionChanged"
        )
      +b.g-cell.g-cols.--12-xs.--4-xl
        autocomplete-widget(
          v-model="formdata.city"
          ref="city"
          input-class="multiselect--variant_primary-xl multiselect--variant_city multiselect--variant_1"
          :params="{ country: formdata.country.slug || null, region: formdata.region.slug || null }"
          :filter="autocomplete.city"
          @input="cityChanged"
        )
</template>

<script>
import {
  countriesSearchResource,
  regionsSearchFilterResource,
  citiesSearchFilterResource,
} from '@services/countries.service'
import AutocompleteWidget from './Autocomplete'

export default {
  name: 'location-widget',

  components: {
    AutocompleteWidget,
  },

  props: {
    value: {},
  },

  data() {
    return {
      formdata: {
        country: {},
        region: {},
        city: {},
      },
      autocomplete: {
        country: {
          placeholder: this._('Страна'),
          name: 'country',
          label: 'name',
          resource: countriesSearchResource,
        },
        region: {
          placeholder: this._('Регион'),
          name: 'region',
          label: 'name',
          resource: regionsSearchFilterResource,
        },
        city: {
          placeholder: this._('Город'),
          name: 'city',
          label: 'name',
          resource: citiesSearchFilterResource,
        },
      },
      isMounted: true,
    }
  },

  watch: {
    value: {
      handler(nval) {
        this.setValue(nval)
      },
    },
  },

  methods: {
    countryChanged() {
      this.formdata.region = {}
      this.formdata.city = {}

      this.$nextTick(() => {
        this.$refs.region.receiveWithParams()
        this.$refs.city.receiveWithParams()

        this.emitValue()
      })
    },

    regionChanged(data) {
      this.formdata.country = data.country
      this.formdata.city = {}

      this.$nextTick(() => {
        this.$refs.city.receiveWithParams()

        this.emitValue()
      })
    },

    cityChanged(data) {
      this.formdata.region = data.region
      this.formdata.country = data.country

      this.emitValue()
    },

    emitValue() {
      this.$emit('input', this.formdata)
    },

    setValue(val) {
      if (this.isMounted) {
        this.getInitialValues(val)
      }

      this.isMounted = false

      const isEmpty = !val || (val && Object.values(val).every(v => !v))

      if (isEmpty) {
        Object.keys(this.formdata).forEach(key => {
          this.formdata[key] = {}
        })
      }
    },

    getInitialValues(val) {
      const keys = ['country', 'region', 'city']

      if (val) {
        keys.forEach(k => {
          if (val[k]) {
            const slug = val[k][0]

            this.$refs[k].getInitialValue({ search: slug })

            this.formdata[k] = { slug }
          }
        })
      }
    },
  },
}
</script>
