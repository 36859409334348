// import ChartsComponents from './components/Charts'
import pagesComponents from './components/Pages'
import uiElements from './components/UiElements'
import FilterComponents from './components/Filters'
import ListComponents from './components/List'
import ProjectComponents from './components/Project'
import Forms from './components/Forms'
import Pagination from './components/Pagination'
import Announcement from './components/Announcement'
// import helpers from './components/Helpers'

export default function Components(Vue) {
  Vue.use(uiElements)
  Vue.use(pagesComponents) // реєструємо для глобальної видимості та використання компонентів
  Vue.use(FilterComponents)
  Vue.use(ListComponents)
  Vue.use(ProjectComponents)
  Vue.use(Forms)
  Vue.use(Pagination)
  Vue.use(Announcement)
  // Vue.use(ChartsComponents)
  // Vue.use(helpers)
}
