/* eslint-disable */

import {
  goToTop,
  scrolledHeader,
} from './ui-scripts.js'

document.addEventListener('DOMContentLoaded', e => {
  goToTop()

  window.addEventListener('scroll', () => {
    goToTop()
    scrolledHeader()
  })

  if (document.getElementsByClassName('go-to-top')) {
    let anchorLinks = Array.from(document.getElementsByClassName('go-to-top'))
    let sliceHash = 1
    let time = 0
    anchorLinks.forEach(el => {
      let link = el.getAttribute("href").slice(sliceHash)
      let item = document.getElementById(link)
      if (null != item) {
        el.addEventListener('click', (e) => {
          e.preventDefault()
          document.documentElement.scrollTop = time
        })
      }
    })
  }

  // Логіка роботи бургер-кнопки у мобільному хедері
  const togglerBtn = document.getElementById('togle-icon');
  togglerBtn.addEventListener('click', () => {
    togglerBtn.classList.toggle('open')
    document.querySelector('.js-header').classList.toggle('is-active')
    document.getElementById('mobile-sidebar').classList.toggle('is-open');
    document.querySelector('body').classList.toggle('hide-scroll');
  })
  const topMenus = Array.from(document.getElementsByClassName('top-menu'));
  topMenus.forEach(el => {
    el.addEventListener('click', () => {
      el.classList.toggle('is-open')
    })
  })
})
