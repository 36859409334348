<template lang='pug'>
  include /mixins.pug
  +b.ui-uploader
    +e.content
      +e.wrapper(:class="{'is-disabled': files.length == maxFiles}")
        +b.g-row.--space_md.--appearance_spaced.--appearance_column
          +b.g-cell.g-cols
            label
              slot(name='button')
              input(
                ref="inputFile"
                style="display:none"
                type='file'
                :id='inputId'
                :name='inputId'
                :accept='acceptedFilesLocal'
                :multiple="multiple"
                @change='onFileChange'
              )
          //- +b.g-cell.g-cols.--auto-sm
          //-   +b.P.ds-caption.--size_xxs.--lh_default {{ _("Вимоги до завантажуваних файлів") }}
          //-   +b.P.ds-caption.--size_xxs.--lh_default {{ `(${_("Максимальна кількість файлів -")} ${maxFiles}, ${_("максимальний розмір файлу -")} ${maxSize} ${_("Мб.")}, ${_("дозволені формати -")} ${acceptedFiles})` }}
          //-     span(v-if="minResolution") , {{ _("минимально рекомендуемое разрешение") }} {{ minResolution }}
    +e.list
      +b.ds-panel.--space_xl(v-if='files.length > 0')
        +e.element.--offset_bottom
          +b.cabinet-gallery-wrapper(v-if="imageFiles && filesLength")
            +b.gallery-image-wrapper(
              v-for='(file, index) in files'
              v-if="file && !file.Delete"
              @click='openGallerySlider(index)'
            )
              +b.gallery-image
                +e.IMG.image(
                  :src='file.src'
                  loading="lazy"
                )
                +e.btn.btn-gallery--remove(@click.stop="removeFile(index)")
                  +b.I.icon.icon-bootsrap-x
              validation-provider(
                tag="div"
                v-slot="{errors}"
                :name="'images_' + index"
              )
                span.help-type-error(v-if='errors.length') {{ errors[0] }}
          +b.g-row.--space_sm.--space_xl-sm.--appearance_spaced(
            v-else
          )
            +b.g-cell.g-cols.--12-xs(
              v-for='(file, index) in files'
              v-if="file && !file.Delete"
            )
              validation-provider(
                tag="div"
                v-slot="{ errors }"
                :name="'file_' + index"
                slim
              )
                +b.ds-panel.--space_xs(v-if="errors && errors.length")
                  +e.element.--offset_top
                    +b.P.help-type-error.--offset_none {{ errors[0] }}
              +b.g-row.--appearance_nowrap.--align_center
                +b.g-cell
                  +b.P.ds-caption.--color_black.--size_xs.word-break {{ file.name }}
                +b.g-cell
                  +b.ds-link.--color_red.--flex.--size_md.is-passive(@click="removeFile(index)")
                    +b.I.icon-bootsrap-x
    +e.errors
      slot(name='errors' :fileErrors='fileErrors')
        +b.P.help-type-error.--offset_none(v-if='fileErrors.limit') {{ _("Перевищено максимальну кількість файлів для завантаження") }}
        +b.P.help-type-error.--offset_none(v-if='fileErrors.size') {{ _("Перевищена максимальна вага файла для завантаження") }}
        +b.P.help-type-error.--offset_none(v-if='fileErrors.type') {{ _("Не підтримуваний формат файлу") }}
        +b.P.help-type-error.--offset_none(v-if='fileErrors.height') {{ _("Максимальна висота зображення - ") }} {{ maxHeight }}px
        +b.P.help-type-error.--offset_none(v-if='fileErrors.width') {{ _("Максимальна ширина зображення - ") }} {{ maxWidth }}px
      slot(name='required')
</template>
<script>
import { getMimeType } from '@utils'
import GallerySliderModal from '@components/Modals/Users/GallerySliderModal'
/* eslint-disable */

const imageTypes = [
  'data:image/jpeg;base64',
  'data:image/jpg;base64',
  'data:image/png;base64',
  'data:image/gif;base64',
  '.jpeg',
  '.jpe',
  '.jpg',
  '.png',
  '.gif',
]

export default {
  name: 'UiUploader',

  props: {
    inputId: {
      type: String,
      default: 'upload',
    },
    maxSize: {
      type: Number,
      default: 5,
    },
    maxFiles: {
      type: Number,
      default: 5,
    },
    acceptedFiles: {
      type: String,
      default: '.jpg, .jpeg, .png',
    },
    value: {
      type: Array,
      default: () => [],
    },
    minResolution: {},
    maxWidth: {
      default: 6000,
    },
    maxHeight: {
      default: 6000,
    },
    needCropper: {
      default: false,
    },
    imageFiles: {
      default: false,
    },
    multiple: {
      default: false,
    },
  },

  data() {
    return {
      acceptedFilesLocal: '',
      bytes: 1000,
      megabyte: 1048576,
      files: [],
      zero: 0,
      one: 1,
      fileErrors: {
        limit: false,
        size: false,
        type: false,
        width: false,
        height: false,
      },
      defaultFilesIsSetted: false,
      image: {},
    }
  },

  computed: {
    filesLength() {
      return this.files.reduce((acc, el) => {
        if (el && !el.Delete) acc++
        return acc
      }, 0)
    },
  },

  watch: {
    value: {
      handler() {
        if (!this.defaultFilesIsSetted) {
          this.setDefaultFiles()
        }
      },
    },
  },

  mounted() {
    this.acceptedFilesLocal = this.acceptedFiles.replace(/ /g, '')
    this.setDefaultFiles()
  },

  methods: {
    setDefaultFiles() {
      if (this.value && this.zero < this.value.length) {
        this.files.push(...this.value)
        this.defaultFilesIsSetted = true
      }
    },

    isImageFile(src) {
      const lowercaseUrl = src.toLowerCase()
      const imageTypeIsIncluded = imageTypes.some(el => lowercaseUrl.includes(el))
      return imageTypeIsIncluded
    },

    onFileChange(e) {
      this.defaultFilesIsSetted = true
      this.fileErrors.size = false
      const files = e.target.files || e.dataTransfer.files
      if (!files.length) return
      this.createFile(files)
    },

    emitFiles() {
      const timeout = 100
      setTimeout(() => {
        this.$emit('input', this.files)
        this.$refs.inputFile.value = ''
      }, timeout)
    },

    async createFile(files) {
      await this.validateFile(files).then(() => {
        const timeout = 300
        setTimeout(() => {
          const errorsIsClean = this.checkErrors()
          if (!errorsIsClean) return

          Object.keys(files).forEach(el => {
            const file = files[el]
            const blob = URL.createObjectURL(file)
            const reader = new FileReader()

            reader.onload = e => {
              const { result } = e.target

              const src = this.needCropper ? blob : result
              this.image = {
                src,
                image: src,
                type: getMimeType(result, file.type),
                name: file.name,
              }
              if (this.needCropper) {
                this.openCropperModal()
              } else {
                this.files.push(this.image)
                this.emitFiles()
              }
            }

            if (this.needCropper) {
              reader.readAsArrayBuffer(file)
            } else {
              reader.readAsDataURL(file)
            }
          })
        }, timeout)
      })
    },

    cropImageHandler(image) {
      this.files.push({ src: image, image })
      this.emitFiles()
    },

    openCropperModal() {
      const modal = () => import('@components/Modals/Cropper')

      this.$modal.show(modal, {
        image: this.image,
        callback: this.cropImageHandler,
        stencil: 'rectangle-stencil',
      }, {
        class: 'v--modal-md',
        height: 'auto',
        adaptive: true,
      })
    },

    checkErrors() {
      return Object.values(this.fileErrors).every(el => !el)
    },

    async validateFile(files) {
      this.validateFileFormat(files)
      this.validateFilesLength(files)
      this.validateFileSize(files)
      await this.validateResolution(files)
    },

    async validateResolution(files) {
      return new Promise((resolve, reject) => {
        // Clear resolution errors
        this.fileErrors.width = false
        this.fileErrors.height = false
        Object.keys(files).forEach((el, index) => {
          const reader = new FileReader()
          reader.readAsDataURL(files[el])
          reader.onload = e => {
            // Resolve promise if it isn't image file
            if (!this.isImageFile(e.target.result)) {
              resolve()
            }
            // Create new image for getting it height and width
            let img = new Image()
            img.src = e.target.result
            img.onload = () => {
              // Check height of image
              if (this.maxWidth < img.width) {
                this.fileErrors.width = true
                reject()
              }
              // Check height of image
              if (this.maxHeight < img.height) {
                this.fileErrors.height = true
                reject()
              }
              const one = 1
              if (index === files.length - one) {
                resolve()
              }
            }
          }
        })
      })
    },

    validateFileFormat(files) {
      const acceptedFormats = this.acceptedFilesLocal.split(',')
      // Get file format
      let fileFormat = files[0].name.split('.')
      this.fileErrors.type = false
      fileFormat = `.${fileFormat[fileFormat.length - this.one]}`
      // Check if files includes accepted format
      if (this.zero > acceptedFormats.indexOf(fileFormat.toLowerCase())) {
        this.fileErrors.type = true
      }
    },

    validateFilesLength(files) {
      this.fileErrors.limit = false
      // Get not deleted files for limit validation
      const isNotDeletedItems = this.files.reduce((acc, el) => {
        if (el && !el.Delete) acc++
        return acc
      }, 0)
      // Check if files limit is valid
      if (isNotDeletedItems + files.length > parseInt(this.maxFiles)) {
        this.fileErrors.limit = true
      }
    },

    validateFileSize(files) {
      // Check if files size is valid
      this.fileErrors.size = Object.keys(files).some(el => {
        return files[el].size > parseInt(this.maxSize) * this.megabyte
      })
    },

    removeFile(index) {
      // Get file for delete
      const file = this.files[index]
      if (file && file.id) {
        // Add delete key for images from server
        file.Delete = true
      } else {
        // Remove deleted file if it's not server file
        this.files.splice(index, this.one)
      }
      this.emitFiles()
    },

    resetFiles() {
      this.files = []
    },
    
    openGallerySlider(index) {
      this.$modal.show(
        GallerySliderModal,
        {
          files: this.files,
          initialSlide: index,
        },
        {
          height: '90%',
          width: '90%',
          adaptive: true,
          scrollable: false,
          transition: 'false',
        }
      )
    },
  },
}
</script>
