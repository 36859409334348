<template lang="pug">
include /mixins
+b.chat-message(:class="{'is-highlighted': item.status === 'new' && !item.isMine}")
  +e.delete
    +b.i-circle.--size_xs.--hover_red.--bg_black(@click.prevent="deleteMessage")
      +e.icon
        +b.I.icon-trash
  +e.content
    +e.P.text {{ item.message }}
  +e.P.date {{ item.createdAt|formatMomentTimeSeconds }}
</template>

<script>
import { emitter } from './EventEmmiter'

/**
 * threshold of element visibility (from 0 to 1)
 */
const threshold = 0.5

export default {
  props: {
    item: {
      type: Object,
    },
    scrollerElement: {},
  },

  mounted() {
    this.checkMessageVisibility()
  },

  methods: {
    checkMessageVisibility() {
      this.$nextTick(() => {
        /**
         * options for IntersectionObserver
         */
        const options = {
          /**
           * root element of messages
           */
          root: this.scrollerElement,
          /**
           * add offset from top of root element
           */
          rootMargin: '-70px 0px 0px 0px',
          threshold,
        }

        const callback = entries => {
          entries.forEach(entry => {
            /**
             * `entry.intersectionRatio` - the current value of element visibility
             * check that the message is visible
             */
            if (threshold <= entry.intersectionRatio) {
              this.readMessages()
            }
          })
        }

        const observer = new IntersectionObserver(callback, options)

        observer.observe(this.$el)
      })
    },

    readMessages() {
      /**
       * check that the message is not mine and has not been read
       */
      if (!this.item.isMine && 'new' === this.item.status) {
        /**
         * create an event that will listen in the chat controller
         */
        emitter.emit('chat:message:read', this.item)
      }
    },

    deleteMessage() {
      /**
       * create an event that will listen in the chat controller
       */
      emitter.emit('chat:message:delete:request', this.item)
    },
  },
}
</script>
