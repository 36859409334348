<template lang="pug">
  include ./../../../../mixins
  +b.form-base.auth-form
    +b.P.form-title.h1_title {{ _("Вкажіть новий пароль") }}
    validation-observer(
      ref="validator",
      tag="form",
      class="form-element",
      @submit.prevent='prepareData(valid)',
      v-slot="{ valid, errors: globalErrors }",
    )
      +b.server-error(v-if="serverError") {{ serverError[0] }}
      +b.form-inputs-wrap
        +b.form-inputs
          +b.form-inp
            +b.input_label {{ _("Новий пароль*") }}
            +b.I.icon.icon-password-secret.input_icon.--color_gray.--weight_bold(
              @click="switchVisibility(0)"
              v-if="typeOfPassword[0] === 'password'"
            )
            +b.I.icon.icon-password-public.input_icon.--color_gray.--weight_bold(
              @click="switchVisibility(0)"
              v-else
            )
            input-group(
              rules='required|min:6',
              :type='typeOfPassword[0]',
              id="passwordOne",
              name="passwordOne",
              placeholder="",
              v-model="formData['passwordOne']",
              vid="confirmation",
              :class="{'input_style--password': typeOfPassword === 'password'}",
            ).input_style.input_style_auth
              template(v-slot:label="")
                span
              template(v-slot:error="{errors}")
                transition(name="fade")
                  span.help-type-error(v-if='errors.length') {{ errors[0] }}
          +b.form-inp--last
            +b.input_label {{ _("Повторіть пароль*") }}
            +b.I.icon.icon-password-secret.input_icon.--color_gray.--weight_bold(
              @click="switchVisibility(1)"
              v-if="typeOfPassword[1] === 'password'"
            )
            +b.I.icon.icon-password-public.input_icon.--color_gray.--weight_bold(
              @click="switchVisibility(1)"
              v-else
            )
            input-group(
              rules='required|confirmed:confirmation',
              :type='typeOfPassword[1]',
              id="passwordTwo",
              name="passwordTwo",
              placeholder="",
              v-model="formData['passwordTwo']",
              :class="{'input_style--password': typeOfPassword === 'password'}",
            ).input_style.input_style_auth
              template(v-slot:label="")
                span
              template(v-slot:error="{errors}")
                transition(name="fade")
                  span.help-type-error(v-if='errors.length') {{ errors[0] }}
          +b.form-button
            +b.BUTTON.btn-red--confirm(
              type="submit",
              @click.prevent='prepareData(valid)'
            ) {{ _("Прийняти") }}
</template>

<script>

import FormMixin, { defaultValidatorErrorsParser } from '../../../mixins/SendMixin'
import {
  resetPasswordConfirmResource,
} from '../../../services/users.service'
import InfoModal from '../../Modals/InfoModal'

export default {
  data() {
    return {
      formData: {
        passwordOne: '',
        passwordTwo: '',
        uniqueLink: '',
      },
      serverError: '',
      typeOfPassword: [
        'password',
        'password',
      ],
    }
  },
  mixins: [
    FormMixin,
  ],
  methods: {
    resetForm() {
      Object.keys(this.formData).forEach(k => {
        this.formData[k] = null
      })

      this.$nextTick(() => {
        this.$refs.validator.reset()
      })
    },
    async prepareData(valid) {
      this.$refs.validator.validate()
      if (!valid) return
      this.formData.uniqueLink = this.$route.query.unique_code
      this.submit(valid, {
        passwordOne: this.formData.passwordOne,
        passwordTwo: this.formData.passwordTwo,
        uniqueLink: this.formData.uniqueLink,
      })
    },
    send(data) {
      return resetPasswordConfirmResource.execute({}, data).then(() => {
        this.resetForm()
        this.$modal.show(InfoModal, {
          title: this._('Дякуємо!'),
          information: this._('Пароль успішно змінено. Тепер ви можете авторизуватися.'),
        }, {
          height: 'auto',
          width: 360,
          adaptive: true,
          scrollable: false,
          transition: 'false',
        }, {
          'before-close': () => {
            if (window.userIsAuthent) {
              window.location = window.cabinetPageLink
            } else {
              window.location = window.loginPageLink
            }
          },
        })
      })
    },
    updateValidator(errors) {
      const parsed = defaultValidatorErrorsParser(errors)
      if ('nonFieldErrors' in parsed) {
        this.serverError = parsed.nonFieldErrors
      } else {
        this.serverError = parsed.message
      }
      this.$refs.validator.setErrors(parsed)
      this.isLoading = false
    },
    goToUserPersonalPage() {
      window.location.pathname = '/cabinet/'
    },
    switchVisibility(index) {
      this.typeOfPassword[index] = 'password' === this.typeOfPassword[index] ? 'text' : 'password'
      const pass = this.typeOfPassword
      this.typeOfPassword = ''
      this.typeOfPassword = pass
    },
    showInputError(errors, inpName) {
      if (errors[inpName] && 0 < errors[inpName].length) {
        return true
      }
      return false
    },
  },
}

</script>
