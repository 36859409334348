<template lang="pug">
  include /mixins
  +b.form-base.cabinet-settings-form
    +b.i-preloader.--absolute(v-if="isRequest")
      +e.item
    validation-observer(
      ref="validator"
      tag="form"
      class="form-element-hidden"
      @submit.prevent=""
      v-slot="{ errors: globalErrors }"
    )
      +b.form-top-info
        +b.form-title-wrapper
          +b.P.h1_title--cabinet {{ _("ID:") }} {{ id }}
      +b.form-spliter-wrapper
        +b.form-spliter

      +b.ds-panel.--space_xl.--space_4xl-xl
        +e.element.--offset_bottom
          announcement-main-info(
            ref="main"
            :formdata="formdata"
            :choices="choices"
            :global-errors="globalErrors"
            :is-editing="true"
          )

      +b.ds-panel.--space_xl.--space_4xl-xl(v-if="choices.attributes && !Array.isArray(choices.attributes)")
        +e.element.--offset_bottom
          announcement-attributes(
            :formdata="formdata"
            :choices="choices"
            :exchange-rates="exchangeRates"
            :global-errors="globalErrors"
          )

      +b.ds-panel.--space_xl.--space_4xl-xl(v-if="choices.staticAttributes.withGallery")
        +e.element.--offset_bottom
          announcement-gallery(
            :formdata="formdata"
            :preview-is-required="choices.staticAttributes.previewIsRequired"
            :global-errors="globalErrors"
          )

      announcement-contact-info(
        :formdata="formdata"
        :global-errors="globalErrors"
      )

      +b.ds-panel.--space_5xl.--space_6xl-xl
        +e.element.--offset_top
          +b.g-row.--appearance_spaced.--justify_between
            +b.g-cell.g-cols.--12-xs.--narrow-sm
              +b.g-row.--appearance_spaced
                +b.g-cell.g-cols.--12-xs.--narrow-sm(v-if="formdata.status.value === 'active'")
                  +b.control-button.--transparent_black(
                    v-clipboard:copy="'http://localhost:8570/test/link/'"
                    v-clipboard:success="copied"
                  )
                    +e.icon.--size_lg
                      +b.I.icon-share
                    +e.element
                      +e.SPAN.text {{ _("ПОДІЛИТИСЯ") }}
                +b.g-cell.g-cols.--12-xs.--narrow-sm(v-if="formdata.status.value === 'active'")
                  +b.BUTTON.control-button.--transparent_black(@click.prevent="deactivate")
                    +e.icon.--size_lg
                      +b.I.icon-cross-circle
                    +e.element
                      +e.SPAN.text {{ _("Деактивувати") }}
            +b.g-cell.g-cols.--12-xs.--narrow-sm
              +b.g-row.--appearance_spaced
                +b.g-cell.g-cols.--12-xs.--narrow-sm(v-if="formdata.status.value === 'inactive'")
                  +b.BUTTON.control-button.--transparent_black(@click.prevent="prepareData('publication')")
                    +e.element
                      +e.SPAN.text {{ _("ЗБЕРЕГТИ Й ОПУБЛІКУВАТИ") }}
                +b.g-cell.g-cols.--12-xs.--narrow-sm(v-if="formdata.status.value !== 'banned'")
                  +b.BUTTON.control-button(@click.prevent="prepareData('save')")
                    +e.element
                      +e.SPAN.text {{ _("ЗБЕРЕГТИ ОГОЛОШЕННЯ") }}
</template>

<script>
import {
  announcementUpdateResource,
  announcementDetailResource,
  announcementAttributesResource,
  announcementCategoriesResource,
  announcementDeactivateResource,
  exchangeRatesAdaptiveResource,
} from '@services/announcement.service'
import InfoModal from '@components/Modals/InfoModal'
import { clearPhone, openConfirmModal } from '@utils/helpers'
import FormMixin, { defaultValidatorErrorsParser } from '@mixins/SendMixin'
import { CABINET_ANNOUNCEMENT_LIST_NAME } from '@/consts'

export default {
  components: {
    'announcement-main-info': () => import('../components/MainInfo'),
    'announcement-attributes': () => import('../components/Attributes'),
    'announcement-gallery': () => import('../components/Gallery'),
    'announcement-contact-info': () => import('../components/ContactInfo'),
  },

  props: {
    id: {
      type: [String, Number],
    },
  },

  mixins: [
    FormMixin,
  ],

  data() {
    return {
      formdata: {
        country: {},
        region: {},
        city: {},
        attributes: {},
        status: {},
        gallery: [],
      },
      choices: {
        category: [],
        attributes: [],
        priceUnit: [],
        staticAttributes: {},
      },
      exchangeRates: {
        sterling: {},
        euro: {},
        dollar: {},
      },
      isRequest: false,
    }
  },

  async created() {
    this.isRequest = true

    await this.getCategories()
    await this.getData()

    this.setCategories()

    await this.getAttributes()

    this.setAttributes()
    this.groupAttributesByType()

    this.setPeriod()

    await this.getRates()

    this.isRequest = false
  },

  methods: {
    async getData() {
      const params = { uuid: this.id }

      await announcementDetailResource.execute(params).then(res => {
        const { data: { item } } = res

        this.setData(item)
        this.getLocation()
      })
    },

    getLocation() {
      this.$refs.main.getLocation()
    },

    async getCategories() {
      await announcementCategoriesResource.execute().then(res => {
        const { data } = res

        this.choices.category = data
      })
    },

    async getAttributes() {
      const params = { category: this.formdata.category.label }

      await announcementAttributesResource.execute(params).then(res => {
        const { data: { attributes, dynamicAttributes } } = res

        this.choices.attributes = dynamicAttributes.map(el => el.props)
        this.choices.staticAttributes = attributes

        this.setDefaultAttributesStructure()
        this.getPeriod()
      })
    },

    setDefaultAttributesStructure() {
      this.choices.attributes.forEach(item => {
        const val = 'checkbox' === item.createWidgetType ? [] : null

        this.$set(this.formdata.attributes, item.attribute.props.label, val)
      })
    },

    groupAttributesByType() {
      this.choices.attributes = this.choices.attributes.reduce((acc, item) => {
        if (!acc[item.createWidgetType]) {
          acc[item.createWidgetType] = [item]
        } else {
          acc[item.createWidgetType].push(item)
        }

        return acc
      }, {})
    },

    getPeriod() {
      const periodAttr = this.choices.staticAttributes.items.find(attr => 'price_unit' === attr.props.label)
      if (periodAttr) {
        this.choices.priceUnit = periodAttr.props.values.map(el => el.props)
      }
    },

    setPeriod() {
      if (this.formdata.priceUnit) {
        const option = this.choices.priceUnit.find(el => el.label === this.formdata.priceUnit.label)
        this.formdata.priceUnit = option
      }
    },

    setData(item) {
      const location = ['country', 'region', 'city']

      location.forEach(k => {
        if (!item[k]) {
          item[k] = {}
        }
      })

      this.formdata = item

      this.setGallery()
    },

    setGallery() {
      this.formdata.gallery = this.formdata.gallery.map(el => {
        el.src = el.image

        return el
      })
    },

    setAttributes() {
      const selectedAttributes = JSON.parse(JSON.stringify(this.formdata.attributes))

      this.formdata.attributes = {}
      this.setDefaultAttributesStructure()

      selectedAttributes.forEach(item => {
        const attr = this.choices.attributes.find(el => el.attribute.props.label === item.label)

        if (!attr) return

        if ('checkbox' === attr.createWidgetType) {
          const values = item.values.map(val => val.props.id)

          this.formdata.attributes[item.label] = values
        } else if ('select' === attr.createWidgetType) {
          const [val] = item.values

          if (!val) return

          this.formdata.attributes[item.label] = val.props.id
        } else {
          const [val] = item.values

          if (!val) return

          this.formdata.attributes[item.label] = val.props.value
        }
      })
    },

    setCategories() {
      this.choices.category.forEach(first => {
        if (first.label === this.formdata.category.label) {
          this.formdata.category = first

          this.formdata.categoryFirst = first

          return
        }

        first.childs.forEach(second => {
          if (second.label === this.formdata.category.label) {
            this.formdata.category = second

            this.formdata.categoryFirst = first
            this.formdata.categorySecond = second

            return
          }

          second.childs.forEach(third => {
            if (third.label === this.formdata.category.label) {
              this.formdata.category = third

              this.formdata.categoryFirst = first
              this.formdata.categorySecond = second
              this.formdata.categoryThird = third
            }
          })
        })
      })
    },

    async getRates() {
      await exchangeRatesAdaptiveResource.execute().then(res => {
        const { data: { item } } = res

        this.exchangeRates = item
      })
    },

    copied() {
      this.$notify(this._('Посилання на оголошення скопійовано'))
    },

    async deactivate() {
      const info = {
        title: this._('Ви впевнені, що хочете деактивувати це оголошення?'),
      }
      await openConfirmModal(info, this)

      const formdata = { uuid: this.id }

      announcementDeactivateResource.execute({}, formdata).then(() => {
        const title = this._('Ваше оголошення було деактивовано')

        this.openMessageModal(title)
      })
    },

    prepareAttributes(attributes) {
      return Object.keys(attributes).reduce((acc, key) => {
        if (attributes[key]) {
          acc[key] = attributes[key]
        }

        return acc
      }, {})
    },

    updateValidator(errors) {
      const parsed = defaultValidatorErrorsParser(errors)
      this.$refs.validator.setErrors(parsed)
    },

    async prepareData(event) {
      const isValid = await this.$refs.validator.validate()

      if (isValid) {
        this.submit(isValid, event)
      } else {
        this.scrollTop()
      }
    },

    scrollTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    },

    prepareFormdata(data, status) {
      const formdata = JSON.parse(JSON.stringify(data))

      if (formdata.priceUnit) {
        formdata.priceUnit = formdata.priceUnit.label
      }

      formdata.city = formdata.city.slug

      if (formdata.region && formdata.region.slug) {
        formdata.region = formdata.region.slug
      } else {
        delete formdata.region
      }

      formdata.country = formdata.country.slug
      formdata.category = formdata.category.id
      formdata.phoneAuthor = clearPhone(formdata.phoneAuthor)
      formdata.attributes = this.prepareAttributes(formdata.attributes)

      if (!this.choices.staticAttributes.withPrice) {
        delete formdata.sterlingPrice
        delete formdata.euroPrice
        delete formdata.dollarPrice
      }

      if ('publication' === status) {
        formdata.forPublish = true
      } else if ('save' === status) {
        if ('active' === formdata.status.value) {
          formdata.forPublish = true
        } else {
          formdata.forPublish = false
        }
      }

      delete formdata.categoryThird
      delete formdata.categorySecond
      delete formdata.categoryFirst

      return formdata
    },

    send(status) {
      const params = { uuid: this.id }
      const formdata = this.prepareFormdata(this.formdata, status)

      return announcementUpdateResource.execute(params, formdata).then(() => {
        const title = this._('Дякуємо!')
        const savedInformation = this._('Ваше оголошення бережено без публікації!')
        const publicatedInformation = this._('Ваше оголошення опубліковано!')
        const information = formdata.forPublish ? publicatedInformation : savedInformation

        this.openMessageModal(title, information)
      })
    },

    openMessageModal(title, information) {
      this.$modal.show(InfoModal, {
        title,
        information,
      },
      {
        height: 'auto',
        width: 360,
        adaptive: true,
        scrollable: false,
        transition: 'false',
      }, {
        'before-close': () => {
          this.$router.push({ name: CABINET_ANNOUNCEMENT_LIST_NAME })
        },
      })
    },
  },
}
</script>
