<template lang="pug">
  include ./../../../../mixins
  +b.popup-wrapper
    +b.I.icon.icon-bootsrap-x.btn-close-popup(
      @click='$emit("close")'
    )
    +b.P.popup-title {{ _('Підтверджувальний код було надіслано на пошту') }}
    +b.P.popup-description.item-description {{ _("Для завершення реєстрації, введіть надісланий код") }}
    validation-observer(
      ref="validator",
      tag="form",
      class="form-element",
      @submit.prevent='prepareData(valid)',
      v-slot="{ valid, errors: globalErrors }",
    )
      +b.server-error(v-if="serverError") {{ serverError[0] }}
      +b.form-inputs-wrap
        +b.form-inputs
          +b.form-inp
            +b.input_label {{ _("Введіть код") }}
            input-group(
              rules='required|min:20'
              type='text'
              id="confirmPassword"
              name="confirmPassword"
              placeholder=""
              v-model="formData['confirmPassword']"
              :class="{'is-error': showInputError(globalErrors, 'confirmPassword')}"
            ).input_style
              template(v-slot:label="")
                span
              template(v-slot:error="{errors}")
                transition(name="fade")
                  span.help-type-error(v-if='errors.length') {{ errors[0] }}
          +b.form-button
            +b.BUTTON.btn-red--popup(
              type="button"
              @click='prepareData(true)'
              ) {{ _("Прийняти") }}
</template>

<script>
import {
  getUrlParameter,
} from '@utils'
import FormMixin, { defaultValidatorErrorsParser } from '../../../mixins/SendMixin'
import {
  socialConfirmEmailResource,
} from '../../../services/users.service'

export default {
  data() {
    return {
      formData: {
        confirmPassword: '',
        partialToken: '',
      },
      serverError: '',
    }
  },
  mixins: [
    FormMixin,
  ],
  mounted() {
    this.getToken()
  },
  methods: {
    resetErrors() {
      this.$refs.validator.reset()
      this.nonFieldErrors = []
    },
    async prepareData(valid) {
      this.$refs.validator.validate()
      if (!valid) return
      this.submit(valid, {
        confirmPassword: this.formData.confirmPassword,
        partialToken: this.formData.partialToken,
      })
    },
    send(data) {
      console.log('send')
      console.log(data)
      return socialConfirmEmailResource.execute({}, data)
        .then(({ data: { item } }) => {
          this.$refs.validator.reset()
          console.log(item)
          // якщо все добре, то редирект, куди бекенд скаже
          if (item.redirectTo) {
            window.location.pathname = item.redirectTo
          }
        })
    },
    updateValidator(errors) {
      const parsed = defaultValidatorErrorsParser(errors)
      if ('nonFieldErrors' in parsed) {
        this.serverError = parsed.nonFieldErrors
      } else {
        this.serverError = parsed.message
      }
      this.$refs.validator.setErrors(parsed)
      this.isLoading = false
    },
    getToken() {
      const token = getUrlParameter('partial_token')
      this.formData.partialToken = token
    },
    showInputError(errors, inpName) {
      if (errors[inpName] && 0 < errors[inpName].length) {
        return true
      }
      return false
    },
  },
}

</script>
