<template lang="pug">
  include /mixins
  +b.input-wrapper.--prepend_icon
    +e.icon
      +b.I.icon-search
    +b.form-inp.--space_none.input_style.--variant_default
      input-group(
        v-model="query"
        type="search"
        autocomplete="off"
        :label="filter.label"
        :name="filter.name || 'search'"
        :placeholder="filter.placeholder || placeholder",
        @input="searchDebounceFn"
      )
</template>
<script>
import { isEmpty } from '@aspectus/vue-utils'
import { debounce } from 'lodash'

const MIN_LENGTH = 3

export default {
  name: 'search-widget',

  props: {
    value: {},
    filter: {
      default: () => {},
    },
  },

  data() {
    return {
      query: '',
      searchDebounceFn: null,
      placeholder: this._('Введите запрос'),
    }
  },

  watch: {
    value: {
      immediate: true,
      handler() {
        this.setValue()
      },
    },
  },

  mounted() {
    const timeout = 500
    /**
     * add debounce wrapper for prevent multiple requests
     */
    this.searchDebounceFn = debounce(this.submit, timeout)
  },

  methods: {
    setValue() {
      this.query = this.normalizeFrom(this.value)
    },

    submit() {
      const length = this.filter.min_length || MIN_LENGTH
      const zero = 0
      if (this.query.length >= length) {
        this.$emit('input', this.query)
        return
      }
      if (this.query.length === zero) {
        this.$emit('input', '')
      }
    },

    normalizeFrom(value) {
      if (isEmpty(value)) return ''
      const query = value
      return query
    },
  },
}
</script>
