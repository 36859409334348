import { createStore as createChatStore } from './chat/store'

export function createStore(parameters) {
  return {
    modules: {
      chat: createChatStore(parameters),
    },
  }
}

export function createStoreObject({ Store, Vue }) {
  return new Store(createStore({ Store, Vue }))
}
