var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form-base auth-form"},[_c('p',{staticClass:"form-title h1_title"},[_vm._v(_vm._s(_vm._("Вкажіть новий пароль")))]),_c('validation-observer',{ref:"validator",staticClass:"form-element",attrs:{"tag":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.prepareData(_vm.valid)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var globalErrors = ref.errors;
return [(_vm.serverError)?_c('div',{staticClass:"server-error"},[_vm._v(_vm._s(_vm.serverError[0]))]):_vm._e(),_c('div',{staticClass:"form-inputs-wrap"},[_c('div',{staticClass:"form-inputs"},[_c('div',{staticClass:"form-inp"},[_c('div',{staticClass:"input_label"},[_vm._v(_vm._s(_vm._("Новий пароль*")))]),(_vm.typeOfPassword[0] === 'password')?_c('i',{staticClass:"icon icon-password-secret input_icon input_icon--color_gray input_icon--weight_bold",on:{"click":function($event){return _vm.switchVisibility(0)}}}):_c('i',{staticClass:"icon icon-password-public input_icon input_icon--color_gray input_icon--weight_bold",on:{"click":function($event){return _vm.switchVisibility(0)}}}),_c('input-group',{staticClass:"input_style input_style_auth",class:{'input_style--password': _vm.typeOfPassword === 'password'},attrs:{"rules":"required|min:6","type":_vm.typeOfPassword[0],"id":"passwordOne","name":"passwordOne","placeholder":"","vid":"confirmation"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span')]},proxy:true},{key:"error",fn:function(ref){
var errors = ref.errors;
return [_c('transition',{attrs:{"name":"fade"}},[(errors.length)?_c('span',{staticClass:"help-type-error"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])]}}],null,true),model:{value:(_vm.formData['passwordOne']),callback:function ($$v) {_vm.$set(_vm.formData, 'passwordOne', $$v)},expression:"formData['passwordOne']"}})],1),_c('div',{staticClass:"form-inp form-inp--last"},[_c('div',{staticClass:"input_label"},[_vm._v(_vm._s(_vm._("Повторіть пароль*")))]),(_vm.typeOfPassword[1] === 'password')?_c('i',{staticClass:"icon icon-password-secret input_icon input_icon--color_gray input_icon--weight_bold",on:{"click":function($event){return _vm.switchVisibility(1)}}}):_c('i',{staticClass:"icon icon-password-public input_icon input_icon--color_gray input_icon--weight_bold",on:{"click":function($event){return _vm.switchVisibility(1)}}}),_c('input-group',{staticClass:"input_style input_style_auth",class:{'input_style--password': _vm.typeOfPassword === 'password'},attrs:{"rules":"required|confirmed:confirmation","type":_vm.typeOfPassword[1],"id":"passwordTwo","name":"passwordTwo","placeholder":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span')]},proxy:true},{key:"error",fn:function(ref){
var errors = ref.errors;
return [_c('transition',{attrs:{"name":"fade"}},[(errors.length)?_c('span',{staticClass:"help-type-error"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])]}}],null,true),model:{value:(_vm.formData['passwordTwo']),callback:function ($$v) {_vm.$set(_vm.formData, 'passwordTwo', $$v)},expression:"formData['passwordTwo']"}})],1),_c('div',{staticClass:"form-button"},[_c('button',{staticClass:"btn-red btn-red--confirm",attrs:{"type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.prepareData(valid)}}},[_vm._v(_vm._s(_vm._("Прийняти")))])])])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }