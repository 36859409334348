import DialogWrapper from './DialogWrapper'
import Message from './Message'
import MessageGroup from './MessageGroup'
import Room from './Room'
import Controller from './Controller'
import RoomPreview from './RoomPreview'
import RoomList from './RoomList'
import Audio from './Audio'
import EmojiButton from './EmojiButton'
import CentrifugeConnector from './CentrifugeConnector'

export default function Components(Vue) {
  Vue.component('chat-dialog-wrapper', DialogWrapper)
  Vue.component('chat-message', Message)
  Vue.component('chat-message-group', MessageGroup)
  Vue.component('chat-room', Room)
  Vue.component('chat-controller', Controller)
  Vue.component('chat-room-preview', RoomPreview)
  Vue.component('chat-room-list', RoomList)
  Vue.component('chat-audio', Audio)
  Vue.component('chat-emoji-button', EmojiButton)
  Vue.component('chat-centrifuge-connector', CentrifugeConnector)
}
