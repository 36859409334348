<template lang="pug">
  include /mixins
  ui-slider(
    v-viewer=""
    :options="options"
  )
    template(#slide)
      +b.i-gallery(v-for="(item, index) in items")
        +e.content
          +e.icon
            +b.I.icon-search
          +b.ds-aspect-ratio.--appearance_filled.--variant_1
            +e.body
              +b.i-image-wrapper
                +e.IMG.item(:src="item.image")
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
    },
  },

  data() {
    return {
      options: {
        dots: false,
        infinite: false,
        slidesToScroll: 1,
        arrows: true,
        slidesToShow: 1,
        prevArrow: "<div class='slider-arrow slider-arrow--previous' aria-label='Previous'><i class='icon icon-chevronb-left'></div>",
        nextArrow: "<div class='slider-arrow slider-arrow--next' aria-label='Next'><i class='icon icon-chevronb-right'></div>",
      },
    }
  },
}
</script>
