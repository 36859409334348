<template lang="pug">
  include ./../../../../mixins
  +b.form-base.auth-form
    +b.P.form-title.h1_title {{ _("Авторизація") }}
    +b.P.text-controls-wrapper
      +b.A.text-controls(:href="linkToRegistration")
        +e.gray {{ _("Ще немає акаунту? ") }}
        +e.red {{ _("Зареєструватись") }}
    social-authorization
    validation-observer(
      ref="validator",
      tag="form",
      class="form-element",
      @submit.prevent='prepareData(valid)',
      v-slot="{ valid, errors: globalErrors }",
    )
      +b.server-error(v-if="serverError") {{ serverError[0] }}
      //- +b.input_label(v-if="serverError") {{ serverError[0] }}
      +b.form-inputs-wrap
        +b.form-inputs
          +b.form-inp
            +b.input_label {{ _("E-mail*") }}
            input-group(
              rules='required|email'
              type="text"
              id="email"
              name="email"
              placeholder=""
              v-model="formData['email']"
              :class="{'is-error': showInputError(globalErrors, 'email')}"
            ).input_style.input_style_auth
              template(v-slot:label="")
                span
              template(v-slot:error="{errors}")
                transition(name="fade")
                  span.help-type-error(v-if='errors.length') {{ errors[0] }}
          +b.form-inp--last
            +b.input_label {{ _("Пароль*") }}
            +b.I.icon.icon-password-secret.input_icon.--color_gray.--weight_bold(
              @click="switchVisibility()"
              v-if="typeOfPassword === 'password'"
            )
            +b.I.icon.icon-password-public.input_icon.--color_gray.--weight_bold(
              @click="switchVisibility()"
              v-else
            )
            input-group(
              rules='required|min:3'
              :type='typeOfPassword'
              id="password"
              name="password"
              placeholder=""
              v-model="formData['password']"
              :class="{'input_style--password': typeOfPassword === 'password', 'is-error': showInputError(globalErrors, 'password')}"
            ).input_style.input_style_auth
              template(v-slot:label="")
                span
              template(v-slot:error="{errors}")
                transition(name="fade")
                  span.help-type-error(v-if='errors.length') {{ errors[0] }}
          +b.form-button
            +b.BUTTON.btn-red--confirm(
              type="submit",
              @click.prevent='prepareData(valid)'
            ) {{ _("Увійти") }}
    +b.DIV.text-controls-wrapper
      +b.P.text-controls--last(@click='openModal')
        +e.gray {{ _("Забули пароль? ") }}
        +e.red {{ _("Відновити пароль") }}
</template>

<script>
import {
  getUrlParameter,
} from '@utils'
import FormMixin, { defaultValidatorErrorsParser } from '../../../mixins/SendMixin'
import {
  loginResource,
} from '../../../services/users.service'
import RestorePasswordStartModal from '../../Modals/Users/RestorePasswordStartModal'

export default {
  data() {
    return {
      formData: {
        email: '',
        password: '',
      },
      serverError: '',
      typeOfPassword: 'password',
      linkToRegistration: window.registrationPageLink,
    }
  },
  mixins: [
    FormMixin,
  ],
  methods: {
    async prepareData(valid) {
      this.$refs.validator.validate()
      if (!valid) return
      this.submit(valid, {
        email: this.formData.email,
        password: this.formData.password,
      })
    },
    send(data) {
      return loginResource.execute({}, data)
        .then(({ data: { item } }) => {
          this.$refs.validator.reset()
          console.log(item)
          // якщо є параметр, куди редіректити
          const redirectTo = getUrlParameter('redirect_to')
          if (redirectTo) {
            window.location.pathname = redirectTo
          } else {
            // якщо все добре, то редірект на Особистий кабінет
            this.goToUserPersonalPage()
          }
        })
    },
    updateValidator(errors) {
      const parsed = defaultValidatorErrorsParser(errors)
      if ('nonFieldErrors' in parsed) {
        this.serverError = parsed.nonFieldErrors
      } else {
        this.serverError = parsed.message
      }
      this.$refs.validator.setErrors(parsed)
      this.isLoading = false
    },
    goToUserPersonalPage() {
      window.location.pathname = '/cabinet/'
    },
    switchVisibility() {
      this.typeOfPassword = 'password' === this.typeOfPassword ? 'text' : 'password'
    },
    showInputError(errors, inpName) {
      if (errors[inpName] && 0 < errors[inpName].length) {
        return true
      }
      return false
    },
    openModal() {
      this.$modal.show(
        RestorePasswordStartModal,
        {},
        {
          height: 'auto',
          // height: 800,
          width: 360,
          // maxWidth: 544,
          adaptive: true,
          scrollable: false,
          transition: 'false',
        }
        // {
        //   'before-close': () => {
        //       console.log('close event')
        //       window.location.pathname = window.indexPageLink
        //   },
        // }
      )
    },
  },
}

</script>
