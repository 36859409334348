import { baseResource, createResource } from '@resource/resource'
import { friendlyUrlGenerator } from '@utils/friendly'
import { prefixAPI } from '@resource/api'

const USER_LIST_URL_PREFIX = prefixAPI('/users/catalog')
const USER_LIST_URL_POSTFIX = 'ajax/filters'

const USER_FILTERS = prefixAPI('users/catalog-attributes/')

const orderAdditional = {
  path: [
    'prefix',
    'label',
    'postfix',
    'filters',
  ],
  query: [
    'pagination_offset',
  ],
}

export const usersListResource = friendlyUrlGenerator([
  USER_LIST_URL_PREFIX,
  USER_LIST_URL_POSTFIX,
], baseResource, orderAdditional)

export const usersFilterResource = createResource(USER_FILTERS)
