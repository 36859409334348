<template lang="pug">
  include /mixins
  +b.g-row.--appearance_spaced
    +b.g-cell.g-cols.--12-xs.--6-xl(
      v-if="needSearch"
    )
      tag(
        :tag="$options.widgetsMap[searchFilter.filterWidgetType]"
        :value="preparedValue[searchFilter.name]"
        :filter="searchFilter"
        @input="filterHandler($event, searchFilter)"
      )
    +b.g-cell.g-cols.--12-xs.--6-xl.--4-fhd
      tag(
        :tag="$options.widgetsMap['location_autocomplete']"
        :value="{ country: preparedValue.country, region: preparedValue.region, city: preparedValue.city }"
        @input="filterHandler($event, { filterWidgetType: 'location_autocomplete' })"
      )
    +b.g-cell.g-cols.--12-xs.--3-xl.--2-fhd(
      v-if="needPrice"
    )
      tag(
        :tag="$options.widgetsMap[priceFilter.filterWidgetType]"
        :value="preparedValue[priceFilter.name]"
        :filter="priceFilter"
        @input="filterHandler($event, priceFilter)"
      )
    +b.g-cell.g-cols.--12-xs.--3-xl.--2-fhd(
      v-for="filter in dynamicFilters"
    )
      tag(
        :tag="$options.widgetsMap[filter.filterWidgetType]"
        :value="preparedValue[filter.attribute.props.label]"
        :filter="filter.attribute"
        @input="filterHandler($event, filter)"
      )
    +b.g-cell.g-cols.--12-xs.--3-xl.--2-fhd(v-if="!filterIsEmpty")
      +b.BUTTON.control-button.--size_sm(@click.prevent="$emit('clear')")
        +e.element
          +e.SPAN.text {{ _("Очистить фильтр") }}
</template>

<script>
import UiMixin from './mixins/UiMixin'

export default {
  mixins: [
    UiMixin,
  ],
}
</script>
