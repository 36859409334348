<template lang="pug">
  include /mixins
  ui-click-outside(:do="hide")
    +b.chat-emoji
      +e.button(@click.prevent="toggle")
        +b.I.icon-emoji
      +e.content(v-show="isVisible")
        v-emoji-picker(
          @select="selectEmoji"
          :emojis-by-row="8"
          :emoji-size="24"
        )
</template>

<script>
import { VEmojiPicker } from 'v-emoji-picker'

export default {
  components: {
    VEmojiPicker,
  },

  data() {
    return {
      isVisible: false,
    }
  },

  methods: {
    toggle() {
      this.isVisible = !this.isVisible
    },

    hide() {
      this.isVisible = false
    },

    selectEmoji(emoji) {
      this.$emit('select', emoji.data)
    },
  },
}
</script>
