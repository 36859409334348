<template lang="pug">
  include ../../../mixins
  +b.header-text.menu-link--iconed.--user(
    @click='toggleSidebar'
  )
    +b.I.icon--notransform.user-icon.icon-profile
    span {{ _('Мій акаунт') }}

</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  data() {
    return {}
  },
  computed: {
    ...mapState(
      'chat', [
        'sidebarIsCollapsed',
      ]
    ),
  },
  methods: {
    ...mapActions('chat', [
      'updateSidebarIsCollapsed',
    ]),
    toggleSidebar() {
      this.updateSidebarIsCollapsed(!this.sidebarIsCollapsed)
    },
  },
}
</script>
