<template lang="pug">
  include ./../../../../mixins
  +b.form-base.auth-form
    +b.P.form-title.h1_title {{ _("Реєстрація") }}
    +b.DIV.text-controls-wrapper--fixed-w
      +b.P.text-controls
        +e.gray {{ _("Усе майже готово, залишилося підтвердити, що на момент реєстрації на сайті, вам виполнилося 18 років") }}
      +b.form-button
        +b.BUTTON.btn-red--confirm(
            type="submit",
            @click.prevent='confirmAge()'
          ) {{ _("Підтвердити") }}
      +b.form-button
        +b.A.btn-red--confirm.--black-white(
            href='https://www.google.com/'
          ) {{ _("Покинути сайт") }}
</template>

<script>
import {
  getUrlParameter,
} from '@utils'
import {
  socialConfirmAgeResource,
} from '../../../services/users.service'

export default {
  data() {
    return {
      formData: {
        partialToken: '',
      },
    }
  },
  mounted() {
    this.getToken()
  },
  methods: {
    confirmAge() {
      return socialConfirmAgeResource.execute({}, {
        partialToken: this.formData.partialToken,
      })
        .then(({ data: { item } }) => {
          console.log(item)
          // якщо все добре, то редирект, куди бекенд скаже
          if (item.redirectTo) {
            window.location.pathname = item.redirectTo
          }
        })
    },
    getToken() {
      const token = getUrlParameter('partial_token')
      this.formData.partialToken = token
    },
  },
}

</script>
