<template lang="pug">
  include ./../../../mixins
  div
    slot(v-if="!result")
    +b.ds-panel.--space_xl.--space_4xl-xl(v-if="result")
      +e.element.--offset_top
        renderer(
          :result="result"
          class-render="g-row g-row--space_xl g-row--space_4xl-xl g-row--appearance_spaced"
        )
    +b.ds-panel.--space_4xl.--space_7xl-xl(v-if="pagination.next && !isLoad")
      +e.element.--offset_top
        +b.g-row.--justify_center
          +b.g-cell
            +b.BUTTON.btn-red.--space_2xl(
              @click.prevent="getData"
            ) {{ _('Показати більше') }}
</template>

<script>
import { projectCatalogResource } from '@services/project.service'
import Renderer from '@components/List/Renderer'

export default {
  name: 'project-catalog',

  components: {
    Renderer,
  },

  props: {
    catalogPagination: Object,
  },

  data() {
    return {
      pagination: this.catalogPagination,
      isLoad: false,
      page: 1,
      result: '',
      initialCards: '',
    }
  },

  mounted() {
    this.initialCards = this.$slots.default[0].elm.innerHTML
  },

  methods: {
    getData() {
      if (this.pagination.next) {
        this.isLoad = true

        this.page++

        const params = { page: this.page }

        projectCatalogResource.execute(params).then(res => {
          const { data, pagination } = res
          this.addInitialCards()
          this.result += data
          this.pagination = pagination
          this.isLoad = false
        })
      }
    },

    addInitialCards() {
      if (2 === this.page) {
        this.result = this.initialCards
      }
    },
  },
}
</script>
