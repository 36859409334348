import {
  createResource,
  postResource,
} from '@resource/resource'

import { prefixAPI } from '@resource/api'

const MODEL_PREFIX = 'business_requests'

const BUSINESS_REQUEST_CREATE_LINK = prefixAPI('/create/', MODEL_PREFIX)

export const businessRequestCreateResource = createResource(BUSINESS_REQUEST_CREATE_LINK, postResource)
