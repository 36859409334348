import Vue from 'vue'

Vue.directive('blockquote-wrapper', {
  bind: node => {
    const items = Array.from(node.querySelectorAll('blockquote'))
    if (items.length) {
      items.forEach(el => {
        const wrapper = document.createElement('div')
        el.parentNode.insertBefore(wrapper, el)
        wrapper.appendChild(el)
        wrapper.classList.add('blockquote-wrapper')
      })
    }
  },
})
