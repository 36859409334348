<template lang="pug">
include /mixins
+b.chat-message-group(
  :class="['chat-message-group--alignment_' + alignment]"
)
  +e.A.avatar(
    :href="group.url"
    target="_blank"
  )
    +b.user-avatar.--variant_1
      +e.IMG.item(:src="group.senderAvatar")
  +e.wrapper
    +e.content
      chat-message(
        v-for="item in group.messages"
        :item="item"
        :key="item.id"
        :scroller-element="scrollerElement"
      )
</template>

<script>
export default {
  props: {
    group: {
      type: Object,
    },
    /**
     * alignment message by left or right side of the room
     */
    alignment: {
      type: String,
      default: 'left',
    },
    scrollerElement: {},
  },
}
</script>
