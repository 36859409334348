import ModalTrigger from './ModalTrigger'
import InputGroup from './InputGroup'
import TextareaGroup from './TextareaGroup'
import UiSlider from './UiSlider'
import SocialAuthorization from './SocialAuthorization'
import LanguageChanger from './LanguageChanger'
import LogoutBtn from './LogoutBtn'
import ClickOutside from './ClickOutside'
import UserMenu from './UserMenu'
import UiUploader from './Uploader'
import ShareLinks from './ShareLinks'
import ItemsGallery from './ItemsGallery'
import ChatCounter from './ChatCounter'
import LikeButton from './LikeButton'
import ShareButton from './ShareButton'
import VideoPreview from './VideoPreview'
import CookieInfo from './CookieInfo'
import SideBarBtn from './SideBarBtn'

export default function Components(Vue) {
  Vue.component('ModalTrigger', ModalTrigger)
  Vue.component('InputGroup', InputGroup)
  Vue.component('TextareaGroup', TextareaGroup)
  Vue.component('UiSlider', UiSlider)
  Vue.component('SocialAuthorization', SocialAuthorization)
  Vue.component('LanguageChanger', LanguageChanger)
  Vue.component('LogoutBtn', LogoutBtn)
  Vue.component('ui-click-outside', ClickOutside)
  Vue.component('UserMenu', UserMenu)
  Vue.component('share-links', ShareLinks)
  Vue.component('ui-uploader', UiUploader)
  Vue.component('ItemsGallery', ItemsGallery)
  Vue.component('ChatCounter', ChatCounter)
  Vue.component('LikeButton', LikeButton)
  Vue.component('ShareButton', ShareButton)
  Vue.component('VideoPreview', VideoPreview)
  Vue.component('CookieInfo', CookieInfo)
  Vue.component('SideBarBtn', SideBarBtn)
}
