<template lang="pug">
  include /mixins
  div
    +b.ds-panel.--space_xl
      +e.element.--offset_bottom
        +b.card.--space_sm.--space_2sm-xl.--radius_sm
          +b.g-row.--appearance_spaced.--justify_between.--align_center
            +b.g-cell
              +b.P.h1_title--cabinet {{ _("Мої оголошення") }}
            +b.g-cell
              +b.ROUTER-LINK.ds-link.--size_sm.--underline.--color_red(
                :to="{ name: 'cabinet-announcement-create' }"
              ) {{ _("Додати оголошення") }}
    +b.ds-panel.--space_xl
      +e.element.--offset_bottom
        +b.i-tabs
          +e.wrapper
            +e.item(
              v-for="tab in tabs"
              @click.prevent="selectStatus(tab.key)"
              :key="tab.key"
              :class="{ 'is-active': tab.key === filters.status }"
            )
              +e.SPAN.title {{ tab.title }}
    +b.g-row.--appearance_spaced.--space_xxl-xl(v-if="items.length")
      +b.g-cell.g-cols--12.--6-md.--12-xl.align-stretch(v-for="item in items")
        +b.announcement-card.align-height.--variant_1
          +e.image-wrapper.--size_md(v-if="item.preview")
            +b.ds-aspect-ratio.--appearance_filled.--ratio_4x4
              +e.body
                +b.i-image-wrapper.--radius_sm
                  +e.IMG.item(:src="item.preview")
          +e.content.--size_md(:class="{ 'announcement-card__content--fullwidth': !item.preview }")
            +e.like
              +b.is-hidden-xl
                like-button(
                  label="announcement"
                  icon-class="i-like__icon--variant_1"
                  :item-id="item.uuid"
                  item-key="announcementUuid"
                  :is-liked="item.isFavourite"
                )
              +b.is-hidden.is-visible-xl
                like-button(
                  label="announcement"
                  icon-class="i-like__icon--variant_2"
                  :item-id="item.uuid"
                  item-key="announcementUuid"
                  :is-liked="item.isFavourite"
                )
            +e.A.title.line-clamp.--two(
              v-if="item.status.value === 'active'"
              :href="item.fullUrl"
              target="_blank"
            ) {{ item.title }}
            +e.P.title.line-clamp.--two(v-else) {{ item.title }}
            +e.price(v-if="item.category.withPrice && item.sterlingPrice")
              +b.g-row.--appearance_spaced.--justify_between.--justify_start-sm.--align_center
                +b.g-cell
                  +b.P.ds-caption.--size_lg.--size_xl-2xs.--size_2xl-xl.--weight_bold £ {{ item.sterlingPrice }}
                    |&nbsp;
                    +b.SPAN.ds-caption.--size_sm.--size_2md-xl.--weight_regular(v-if='item.priceUnit') / {{ item.priceUnit }}
                +b.g-cell
                  +b.announcement-prices
                    +b.g-row.--appearance_spaced.--space_4xl-xl
                      +b.g-cell
                        +b.P.ds-caption.--size_md.--size_2md-2xs.--weight_bold € {{ item.euroPrice }}
                      +b.g-cell
                        +b.P.ds-caption.--size_md.--size_2md-2xs.--weight_bold $ {{ item.dollarPrice }}
            +e.additional
              +b.g-row.--appearance_spaced.--space_4xl-xl
                +b.g-cell.g-cols.--narrow
                  +b.i-text
                    +e.icon
                      +b.I.icon-marker
                    +e.SPAN.content {{ item.location }}
                +b.g-cell.g-cols.--narrow(v-if="item.dateOfPublish")
                  +b.i-text
                    +e.icon.--size_2sm
                      +b.I.icon-calendar
                    +e.SPAN.content {{ _("Дата публікації:") }} {{ item.dateOfPublish }}
            +e.info
              +b.P.ds-caption.--size_sm.--color_red(v-if="item.dateOfFinish") {{ _("Дата закінчення строку публікації:") }} {{ item.dateOfFinish }}
              +b.ds-panel.--space_sm
                +e.element.--offset_top
                  +b.P.ds-caption.--size_sm.--color_gray {{ _("Статус оголошення:") }} {{ item.status.title }}
              +b.ds-panel.--space_sm
                +e.element.--offset_top
                  +b.P.ds-caption.--size_sm.--color_gray {{ _("Категорія:") }} {{ item.category.title }}
            +e.attributes
              +b.g-row.--appearance_spaced
                +b.g-cell.g-cols(v-for="attr in item.attributes")
                  +b.P.ds-caption.--size_sm.--weight_bold {{ attr.title }}: {{ attr.values.map(v => v.caption).join(', ') }}
            +e.P.description.line-clamp.--two {{ item.description }}
            +e.button-wrapper
              +e.button
                +b.g-row.--appearance_spaced
                  +b.g-cell.g-cols
                    +b.ROUTER-LINK.control-button.--transparent_black(
                      :to="{ name: 'cabinet-announcement-display', params: { id: item.uuid } }"
                    )
                      +e.icon.--size_lg
                        +b.I.icon-pencil
                      +e.element
                        +e.SPAN.text {{ _("РЕДАГУВАТИ") }}
                  +b.g-cell.g-cols
                    +b.control-button.--transparent_black(
                      v-clipboard:copy="item.fullUrl"
                      v-clipboard:success="copied"
                    )
                      +e.icon.--size_lg
                        +b.I.icon-share
                      +e.element
                        +e.SPAN.text {{ _("ПОДІЛИТИСЯ") }}

    +b.P.ds-caption.--size_sm.--weight_bold(v-if="!isLoading && !items.length") {{ _("Список объявлений пуст") }}

    +b.ds-panel.--space_xl(v-if="pagination.total > pagination.limit")
      +e.element.--offset_top
        ui-limit-pagination(
          :pagination="pagination"
          @input="handlePaginate"
        )
</template>

<script>
import { announcementListCommonResource } from '@services/announcement.service'

export default {
  data() {
    return {
      items: [],
      isLoading: false,
      pagination: {
        limit: 10,
        total: 0,
      },
      tabs: [
        { title: this._('АКТИВНІ'), key: 'active' },
        { title: this._('НЕАКТИВНІ'), key: 'inactive' },
        { title: this._('ВІДХИЛЕНІ'), key: 'banned' },
      ],
      filters: {
        status: 'active',
      },
    }
  },

  mounted() {
    this.getItems()
  },

  methods: {
    async getItems() {
      const params = {
        ...this.pagination,
        ...this.filters,
      }

      this.isLoading = true

      announcementListCommonResource.execute(params).then(res => {
        const { data: { items, pagination } } = res

        this.items = items
        this.pagination = pagination
      }).finally(() => {
        this.isLoading = false
      })
    },

    handlePaginate({ limit: limitValue, offset }) {
      if (this.isLoading) {
        return
      }

      const limit = limitValue || this.pagination.limit

      this.changePagination({ limit, offset })
    },

    changePagination(pagination) {
      this.pagination.limit = pagination.limit
      this.pagination.offset = pagination.offset

      this.getItems()
    },

    copied() {
      this.$notify(this._('Посилання на оголошення скопійовано'))
    },

    selectStatus(key) {
      this.filters.status = key

      this.getItems()
    },
  },
}
</script>
