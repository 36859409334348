<script>
export default {
  data() {
    return {
      isOpened: false,
    }
  },

  methods: {
    toggle() {
      this.isOpened = !this.isOpened
    },

    hide() {
      this.isOpened = false
    },
  },

  render() {
    return this.$scopedSlots.default({
      toggle: this.toggle,
      hide: this.hide,
      isOpened: this.isOpened,
    })
  },
}
</script>
