import BaseView from '@components/List/View'
import { withDefaultProps } from '@aspectus/vue-utils'
import { usersListResource, usersFilterResource } from '@services/user.service'
import { announcementListResource, announcementFilterResource } from '@services/announcement.service'
import RouterParametersController from './RouterParametersController'

const composer = (
  listUrl,
  filtersUrl,
  controllerName = 'Controller',
  filterName = 'DefaultFilter',
  needSearch = false
) => withDefaultProps({
  listResource: () => listUrl,
  filtersResource: () => filtersUrl,
  controllerName: () => controllerName,
  filterName: () => filterName,
  needSearch: () => needSearch,
})(BaseView)

const UserCatalogView = composer(
  usersListResource,
  usersFilterResource,
  'Controller',
  'DefaultFilter'
)

const AnnouncementCatalogView = composer(
  announcementListResource,
  announcementFilterResource,
  'Controller',
  'DefaultFilter',
  true,
  true
)

export default function plugin(Vue) {
  Vue.component('user-catalog', UserCatalogView)
  Vue.component('announcement-catalog', AnnouncementCatalogView)
  Vue.component('router-parameters-controller', RouterParametersController)
}
