<script>
import { mapActions } from 'vuex'
import Centrifuge from 'centrifuge'
import { emitter } from '@components/Chat/EventEmmiter'

export default {
  props: {
    config: {
      type: Object,
    },
  },

  data() {
    return {
      centrifuge: null,
    }
  },

  mounted() {
    this.initCentrifuge()
  },

  methods: {
    ...mapActions('chat', [
      'updateMessagesCounter',
    ]),

    initCentrifuge() {
      this.centrifuge = new Centrifuge(window.centrifugoUrl)

      this.centrifuge.setToken(this.config.token)

      this.subscribe()
    },

    subscribe() {
      this.centrifuge.on('publish', message => {
        const { data: { event, item, data } } = message

        if ('messages-count' === event) {
          this.updateMessagesCounter(data.count)
        } else if ('create-message' === event) {
          emitter.emit('message:update', item)
        } else if ('remove-message' === event) {
          emitter.emit('message:remove', data.messageId)
        } else if ('remove-room' === event) {
          emitter.emit('room:remove', data.roomId)
        }
      })

      this.centrifuge.connect()
    },
  },

  render() {
    return null
  },
}
</script>
