<template lang="pug">
  include /mixins.pug
  +b.SECTION.cabinet-generall-section.section-base--dark
    +b.cabinet-container.g-container--base.--no-vert-pd
      +b.cabinet-wrapper
        cabinet-sidebar
        +e.main
          router-view
</template>

<script>
import cabinetSidebar from './CabinetSidebar'

export default {
  components: {
    'cabinet-sidebar': cabinetSidebar,
  },

  data() {
    return {
    }
  },

}
</script>
