<template lang="pug">
  include ../../../mixins
  +b.social-auth-wrapper
    +e.SPAN.title {{ _('Або увійти') }}
    +b.A.social-autorization-link--facebook(:href='authorizationLinks.facebook')
      +b.I.icon.icon-facebook
    +b.A.social-autorization-link--google(:href='authorizationLinks.google')
      +b.I.icon.icon-icons8-google

</template>

<script>

export default {
  name: 'social-authorization',
  data() {
    return {
      authorizationLinks: {
        facebook: window.facebook_oauth_link,
        google: window.google_oauth2_link,
      },
    }
  },
}
</script>
