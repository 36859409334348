<template lang="pug">
  include /mixins
  +b.cookie-info(v-if="isVisible")
    +e.main
      +e.content
        slot
        //- +b.html-content.--size_sm {{ coockiePopupText }}
      +e.buttons
        +e.button(@click.prevent="allow") {{ _("Дозволити") }}
</template>

<script>
import { getCookie, setCookie } from '@utils/cookies'

export default {
  data() {
    return {
      isVisible: false,
      // isVisible: 'allow' !== getCookie('allow_cookies'),
      // coockiePopupText: window.coockiePopupText,
    }
  },

  methods: {
    allow() {
      const seconds = 60
      const minutes = 60
      const hours = 24
      const days = 30
      const expires = seconds * minutes * hours * days

      this.isVisible = false

      setCookie('allow_cookies', 'allow', `;max-age=${expires}`)
    },
  },
  mounted() {
    this.isVisible = 'allow' !== getCookie('allow_cookies')
  },
}
</script>
