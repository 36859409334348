import Vue from 'vue'

Vue.directive('accordion', {
  bind: node => {
    const timeout = 200
    const triggers = node.querySelectorAll('.js-accordion-trigger')
    const xl = 1200

    triggers.forEach(el => {
      el.addEventListener('click', () => {
        const isMobile = window.innerWidth < xl
        const header = document.querySelector('.js-header .header-wrapper-mobile')

        triggers.forEach(elem => {
          if (elem !== el) {
            elem.closest('.js-accordion-item').classList.remove('is-active')
          }
        })

        el.closest('.js-accordion-item').classList.toggle('is-active')

        setTimeout(() => {
          const headerHeight = isMobile ? header.clientHeight : 0
          const topPos = el.getBoundingClientRect().top + window.scrollY - 10 - headerHeight

          window.scrollTo({
            top: topPos,
            behavior: 'smooth',
          })
        }, timeout)
      })
    })
  },
})
