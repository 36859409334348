<template lang="pug">
  include /mixins
  validation-provider(
    v-slot="{ errors }"
    :rules="rules"
    :name="filter.name"
  )
    +b.input_label(v-if="filter.inputLabel") {{ filter.inputLabel }} {{ required ? "*" : "" }}
    multiselect.form-single-select(
      v-model="checked"
      v-bind="$attrs"
      track-by="id"
      :label="filter.label || 'label'"
      :placeholder="filter.placeholder || ''"
      :name="filter.name"
      :internal-search="false"
      :searchable="true"
      :options="localOptions"
      :max-height="180"
      :loading="loading"
      :class="[inputClass, {'is-error': errors[0]}]"
      :disabled="disabled"
      open-direction="bottom"
      @search-change="search"
      @input="submit"
    )
      template(slot="noResult") {{ _('Ничего не найдено') }}
      template(slot="noOptions") {{ _('Введите для поиска') }}
      template(slot="afterList")
        div(
          v-observe-visibility="setVisibility"
          v-if="hasNextPage"
        )
    span.help-type-error(v-if='errors[0]') {{ errors[0] }}
</template>

<script>
import { isEmpty } from '@aspectus/vue-utils'
import { debounce } from 'lodash'

const MIN_LENGTH = 2
const one = 1

export default {
  name: 'autocomplete-widget',

  props: {
    value: {},
    filter: {},
    params: {
      default: () => {},
    },
    needInitialReceive: {
      default: false,
    },
    rules: {},
    required: {},
    inputClass: {},
    disabled: {
      default: false,
    },
  },

  data() {
    return {
      isPagination: false,
      localOptions: [],
      query: null,
      checked: '',
      isMounted: true,
      page: 1,
      result: {
        pagination: {},
        items: [],
      },
      receiveDebounce: null,
      loading: false,
    }
  },

  computed: {
    hasNextPage() {
      const page = this.result.pagination.hasNext
      return page
    },
  },

  watch: {
    value: {
      immediate: true,
      handler(nval) {
        this.setInitialValue(nval)
      },
    },
  },

  mounted() {
    const timeout = 300
    /**
     * add debounce wrapper for prevent multiple requests
     */
    this.receiveDebounce = debounce(this.receive, timeout)

    this.initialReceive()
  },

  methods: {
    setInitialValue(nval) {
      this.checked = this.normalizeFrom(nval)
    },

    getInitialValue(params) {
      this.loading = true

      this.filter.resource.execute(params).then(res => {
        const { data: { items } } = res
        const [option] = items

        this.checked = option
      }).finally(() => {
        this.loading = false
      })
    },

    receive(params) {
      this.loading = true

      this.filter.resource.execute(params).then(res => {
        const { data } = res

        this.result = data

        this.setOptions(data)
      }).finally(() => {
        this.loading = false
      })
    },

    initialReceive() {
      if (this.needInitialReceive) {
        this.receiveWithParams()
      }
    },

    receiveWithParams() {
      this.isPagination = false
      this.receiveDebounce({ ...this.params })
    },

    resetValue() {
      this.value = ''

      this.localOptions = []

      this.submit()
    },

    submit() {
      this.$nextTick(() => {
        const checked = this.normalizeTo(this.checked)
        const val = JSON.parse(JSON.stringify(checked))
        this.$emit('input', val)
      })
    },

    normalizeTo(value) {
      if (!value) return []

      return value
    },

    normalizeFrom(value) {
      if (isEmpty(value)) return ''

      if (value.slug) {
        return value
      }

      return this.localOptions.find(el => el.slug === value.slug)
    },

    setOptions(nval) {
      const { items } = nval

      if (this.isPagination) {
        this.localOptions.push(...items)
      } else {
        this.localOptions = items || []
      }
    },

    search(query) {
      this.isPagination = false
      this.page = one

      const minLength = this.filter.minLength || MIN_LENGTH

      this.query = query

      if (query.length >= minLength) {
        this.receiveDebounce({ search: query, ...this.params })
      } else {
        this.receiveWithParams()
      }
    },

    setVisibility(reached) {
      if (reached) {
        this.page += one
        this.isPagination = true
        this.receiveDebounce({ ...this.params, page: this.page, search: this.query })
      }
    },
  },
}

</script>
