<template lang="pug">
  include /mixins
  +b.dialog-wrapper
    +e.emoji-wrapper
      chat-emoji-button(@select="setEmoji")
    +e.content
      +b.TEXTAREA.chat-textarea(
        v-model="formdata.message",
        ref="field"
        name="message",
        rows="1"
        element-class="control-input__element--styling_secondary"
        :placeholder="labels.message",
        @keyup="keyUpCallback"
        @keydown="preventNewLineOnEnter"
        @keyup.enter="send"
        @input="updateFieldHeight"
        @click="setSelectionStart"
      )
    +e.button(@click.prevent="send")
      +b.I.icon-send
</template>

<script>
import { messageSend } from '@services/chat.service'
import { emitter } from './EventEmmiter'

export default {
  props: {
    id: {},
  },

  data() {
    return {
      formdata: {
        message: '',
        roomId: this.id,
      },
      labels: {
        message: this._('Ваше сообщение'),
      },
      fieldSelection: {
        start: 0,
      },
    }
  },

  beforeDestroy() {
    emitter.unsubscribe('chat:rooms:open', this.focusOnMessageField)
  },

  mounted() {
    emitter.subscribe('chat:rooms:open', this.focusOnMessageField)
  },

  methods: {
    async prepareData() {
      const isValid = await this.$refs.validator.validate()

      if (!isValid) return

      this.submit(isValid, this.formdata)
    },

    async send() {
      if (!this.formdata.message) return

      messageSend.execute({}, this.formdata).then(() => {
        this.resetForm()
      })
    },

    resetForm() {
      /**
       * reset data after message sending
       */
      this.formdata.message = null
    },

    focusOnMessageField() {
      /**
       * focus on message field in the room
       * if it is not mobile device
       */
      const DESKTOP_MEDIA = 1200
      if (window.innerWidth >= DESKTOP_MEDIA) {
        this.$refs.field.focus()
      }
    },

    keyUpCallback(e) {
      if ((e.ctrlKey || e.metaKey) && (13 === e.keyCode || 10 === e.keyCode)) {
        this.udpateMessage('\n')
        this.updateFieldHeight()
      } else {
        this.setSelectionStart(e)
      }
    },

    preventNewLineOnEnter(e) {
      if (13 === e.keyCode) {
        e.preventDefault()

        this.updateFieldHeight()
      }
    },

    updateFieldHeight() {
      setTimeout(() => {
        const { field } = this.$refs

        field.style.height = '24px'

        const { scrollHeight } = field

        field.style.height = `${scrollHeight}px`

        this.$emit('update:height', scrollHeight)
      }, 100)
    },

    setSelectionStart(e) {
      this.fieldSelection.start = e.srcElement.selectionStart
    },

    setEmoji(emoji) {
      this.udpateMessage(emoji)
    },

    udpateMessage(data) {
      const { message } = this.formdata
      const { start } = this.fieldSelection
      const firstPart = message.substring(0, start)
      const secondPart = message.substring(start, message.length)

      this.formdata.message = `${firstPart}${data}${secondPart}`

      this.$nextTick(() => {
        const newPosition = start + 1
        this.$refs.field.setSelectionRange(newPosition, newPosition)
      })
    },
  },
}
</script>
