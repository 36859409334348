<template lang="pug">
  include ./../../../../mixins
  +b.ban-control-wrapper(
    :class='styleClass'
    @click='updateBanRelation(userId, userIsBanned, index)'
  )
    +b.I.ban-icon.icon.icon-cross-circle
    +b.ban-control-label {{ label }}
</template>

<script>
import InfoModal from '@components/Modals/InfoModal'
import {
  banUserAppendRemoveResource,
} from '@services/users.service'

export default {
  props: {
    userId: {
      default: 0,
    },
    isBanned: {
      default: false,
    },
    index: {
      default: 0,
    },
    styleVersion: {
      default: 'text',
    },
  },
  data() {
    return {
      label: '',
      userIsBanned: this.isBanned,
      styleClass: '',
    }
  },
  mounted() {
    if (this.isBanned) {
      this.label = this._('зняти блокування')
    } else {
      this.label = this._('заблокувати')
    }
    if ('button' === this.styleVersion) {
      this.styleClass = 'as-button'
    } else if ('text' === this.styleVersion) {
      this.styleClass = 'as-text'
    }
  },
  methods: {
    updateBanRelation(userId, userIsBanned, index) {
      let action = 'append'
      if (userIsBanned) {
        action = 'delete'
      }
      banUserAppendRemoveResource.execute({}, {
        userId,
        action,
      }).then(() => {
        if ('delete' === action) {
          this.label = this._('заблокувати')
          this.$emit('deleteFromBanEvent', {
            index,
          })
        } else {
          this.label = this._('зняти блокування')
        }
        this.userIsBanned = !this.userIsBanned
      }).catch(async error => {
        if (403 === error.status) {
          const message = this._('Необхідно авторизуватися')
          const btnTitle = this._('Авторизуватися')
          this.openModal(message, btnTitle, window.loginPageLink)
        }
      })
    },
    openModal(title, btnTitle, link) {
      this.$modal.show(InfoModal, {
        title,
        btnTitle,
        btnLink: link,
      }, {
        height: 'auto',
        width: 360,
        adaptive: true,
        scrollable: false,
        transition: 'false',
      })
    },
  },
}
</script>
