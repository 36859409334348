<template lang="pug">
  include ./../../../../../mixins
  +b.form-base.cabinet-gallery-form
    validation-observer(
      ref="validator"
      tag="form"
      class="form-element-hidden"
      @submit.prevent='prepareData(valid)'
      v-slot="{ valid, errors: globalErrors }"
    )
      +b.form-top-info
        +b.form-title-wrapper
          +b.P.h1_title--cabinet {{ _("Моя фотогалерея") }}
      +b.form-spliter-wrapper
        +b.form-spliter
      +b.g-row.--appearance_spaced.--space_xl
        +b.g-cell.g-cols--12.--auto-xl
          +b.form-info-wrapper
            +b.I.icon.icon-form-info.form-info-icon
            +b.SPAN.form-info {{ _("Ваші фотографії будуть доступні іншим юзерам, тільки якщо Ви дозволит") }}
          +b.form-info-wrapper
            +b.I.icon.icon-form-info.form-info-icon
            +b.SPAN.form-info {{ `${_("Кількість фото обмежена")} ${maxFiles} ${_("один.")}, ${_("максимальний розмір")} ${maxSize} ${_("Мб.")}` }}
        +b.g-cell.g-cols--12.--6-sm.--4-md.--3-mac
          +b.btn-red--gallery(
            @click="$refs.uploadImage.click()"
          ) {{ _("Додати") }}
      +b.server-error(v-if="serverError") {{ serverError }}
      input(
        ref='uploadImage'
        class='upload-avatar'
        type="file"
        :accept='acceptedFilesLocal'
        multiple="multiple"
        @change="onFileChange"
      )
      +b.cabinet-gallery-wrapper
        +b.gallery-image-wrapper(
          v-for='(file, index) in files'
          v-if="file && !file.Delete"
          @click='openGallerySlider(index)'
        )
          +b.gallery-image
            +e.IMG.image(
              :src='file.src'
              loading="lazy"
            )
            +e.btn.btn-gallery--remove(@click.stop="removeFile(index)")
              +b.I.icon.icon-bootsrap-x
          validation-provider(
            tag="div"
            v-slot="{errors}"
            :name="'images_' + index"
          )
            span.help-type-error(v-if='errors.length') {{ errors[0] }}
      slot(name='errors' :fileErorrs='fileErorrs')
        +b.P.help-type-error-block(v-if='fileErorrs.limit') {{ _("Перевищено максимальну кількість файлів для завантаження") }}
        +b.P.help-type-error-block(v-if='fileErorrs.size') {{ _("Перевищена максимальна вага файла для завантаження") }}
        +b.P.help-type-error-block(v-if='fileErorrs.type') {{ _("Не підтримуваний формат файлу") }}
        +b.P.help-type-error-block(v-if='fileErorrs.height') {{ _("Максимальна висота зображення - ") }} {{ maxHeight }}px
        +b.P.help-type-error-block(v-if='fileErorrs.width') {{ _("Максимальна ширина зображення - ") }} {{ maxWidth }}px
      +b.g-row.--appearance_spaced(v-if="files.length")
        +b.g-cell.g-cols--12.--6-sm.--4-md.--3-mac
          +b.BUTTON.btn-red--gallery(
            type="submit",
            @click.prevent='prepareData(valid)'
          ) {{ _("Зберегти зміни") }}
</template>

<script>
import { getMimeType } from '@utils'
import FormMixin, { defaultValidatorErrorsParser } from '../../../../mixins/SendMixin'
import {
  galleryOfUserListResource,
  galleryOfUserListUpdateResource,
} from '../../../../services/users.service'
import InfoModal from '../../../Modals/InfoModal'
import GallerySliderModal from '../../../Modals/Users/GallerySliderModal'

const imageTypes = [
  'data:image/jpeg;base64',
  'data:image/jpg;base64',
  'data:image/png;base64',
  'data:image/gif;base64',
  '.jpeg',
  '.jpe',
  '.jpg',
  '.png',
  '.gif',
]

export default {
  props: {
    inputId: {
      type: String,
      default: 'upload',
    },
    maxSize: {
      type: Number,
      default: 10,
    },
    maxFiles: {
      type: Number,
      default: 10,
    },
    acceptedFiles: {
      type: String,
      default: '.jpg, .jpeg, .png',
    },
    buttonText: {
      type: String,
      default: 'Upload file',
    },
    value: {
      type: Array,
      default: () => [],
    },
    minResolution: {},
    maxWidth: {
      default: 10000,
    },
    maxHeight: {
      default: 10000,
    },
  },
  data() {
    return {
      formData: {},
      files: [],
      serverError: '',

      acceptedFilesLocal: '',
      bytes: 1000,
      megabyte: 1048576,
      // files: [],
      zero: 0,
      one: 1,
      fileErorrs: {
        limit: false,
        size: false,
        type: false,
        width: false,
        height: false,
      },
      defaultFilesIsSetted: false,
      image: {},
    }
  },
  mixins: [
    FormMixin,
  ],
  async mounted() {
    this.acceptedFilesLocal = this.acceptedFiles.replace(/ /g, '')
    await this.getUserGallery()
  },
  methods: {
    resetErrors() {
      this.$refs.validator.reset()
      this.nonFieldErrors = []
    },
    async prepareData() {
      const valid = await this.$refs.validator.validate()
      if (!valid) return
      this.submit(
        valid,
        {
          files: this.files,
        }
      )
    },
    send(data) {
      return galleryOfUserListUpdateResource.execute({}, data).then(() => {
        this.$refs.validator.reset()
        // якщо все добре, то оновлюємо дані
        this.$modal.show(InfoModal, {
          title: this._('Дані оновлено'),
        }, {
          height: 'auto',
          width: 360,
          adaptive: true,
          scrollable: false,
          transition: 'false',
        })
        this.getUserGallery()
        // this.updateFormData(item)
      }).catch(error => {
        console.log(error)
      })
    },
    updateValidator(errors) {
      const parsed = defaultValidatorErrorsParser(errors)
      if ('nonFieldErrors' in parsed) {
        this.serverError = parsed.nonFieldErrors
      } else {
        this.serverError = parsed.message
      }
      this.$refs.validator.setErrors(parsed)
      this.isLoading = false
    },
    async getUserGallery() {
      await galleryOfUserListResource.execute({
        user: 0,
      }).then(({ data }) => {
        const { items } = data
        const preparedImages = items.map(el => {
          el.src = el.image
          return el
        })
        this.files = preparedImages
        this.defaultFilesIsSetted = true
      })
    },
    showInputError(errors, inpName) {
      if (errors[inpName] && 0 < errors[inpName].length) {
        return true
      }
      return false
    },
    openGallerySlider(index) {
      this.$modal.show(
        GallerySliderModal,
        {
          files: this.files,
          initialSlide: index,
        },
        {
          height: '90%',
          // maxHeight: "90%",
          width: '90%',
          adaptive: true,
          scrollable: false,
          transition: 'false',
        }
      )
    },
    // логіка роботи загрузки та видалення файлів на стороні фронта (початок)
    isImageFile(src) {
      const lowercaseUrl = src.toLowerCase()
      const imageTypeIsIncluded = imageTypes.some(el => lowercaseUrl.includes(el))
      return imageTypeIsIncluded
    },

    onFileChange(e) {
      this.defaultFilesIsSetted = true
      this.fileErorrs.size = false
      const files = e.target.files || e.dataTransfer.files
      if (!files.length) return
      this.createFile(files)
    },

    emitFiles() {
      const timeout = 100
      setTimeout(() => {
        this.$emit('input', this.files)
        this.$refs.uploadImage.value = ''
      }, timeout)
    },

    async createFile(files) {
      await this.validateFile(files).then(() => {
        const timeout = 300
        setTimeout(() => {
          const errorsIsClean = this.checkErrors()
          if (!errorsIsClean) return

          Object.keys(files).forEach(el => {
            const file = files[el]
            const reader = new FileReader()

            reader.onload = e => {
              const { result } = e.target

              this.image = {
                src: result,
                image: result,
                type: getMimeType(result, file.type),
              }
              this.files.push(this.image)
              this.emitFiles()
            }
            reader.readAsDataURL(file)
          })
        }, timeout)
      })
    },

    checkErrors() {
      return Object.values(this.fileErorrs).every(el => !el)
    },

    async validateFile(files) {
      this.validateFileFormat(files)
      this.validateFilesLength(files)
      this.validateFileSize(files)
      await this.validateResolution(files)
    },

    async validateResolution(files) {
      return new Promise((resolve, reject) => {
        // Clear resolution errors
        this.fileErorrs.width = false
        this.fileErorrs.height = false
        Object.keys(files).forEach((el, index) => {
          const reader = new FileReader()
          reader.readAsDataURL(files[el])
          reader.onload = e => {
            // Resolve promise if it isn't image file
            if (!this.isImageFile(e.target.result)) {
              resolve()
            }
            // Create new image for getting it height and width
            const img = new Image()
            img.src = e.target.result
            img.onload = () => {
              // Check height of image
              if (this.maxWidth < img.width) {
                this.fileErorrs.width = true
                reject()
              }
              // Check height of image
              if (this.maxHeight < img.height) {
                this.fileErorrs.height = true
                reject()
              }
              const one = 1
              if (index === files.length - one) {
                resolve()
              }
            }
          }
        })
      })
    },

    validateFileFormat(files) {
      const acceptedFormats = this.acceptedFilesLocal.split(',')
      // Get file format
      let fileFormat = files[0].name.split('.')
      this.fileErorrs.type = false
      fileFormat = `.${fileFormat[fileFormat.length - this.one]}`
      // Check if files includes accepted format
      if (this.zero > acceptedFormats.indexOf(fileFormat.toLowerCase())) {
        this.fileErorrs.type = true
      }
    },

    validateFilesLength(files) {
      this.fileErorrs.limit = false
      // Get not deleted files for limit validation
      const isNotDeletedItems = this.files.reduce((acc, el) => {
        if (el && !el.Delete) acc++
        return acc
      }, 0)
      // Check if files limit is valid
      if (isNotDeletedItems + files.length > parseInt(this.maxFiles)) {
        this.fileErorrs.limit = true
      }
    },

    validateFileSize(files) {
      // Check if files size is valid
      this.fileErorrs.size = Object.keys(files).some(el => files[el].size > parseInt(this.maxSize) * this.megabyte)
    },

    removeFile(index) {
      // Get file for delete
      const file = this.files[index]
      if (file && file.id) {
        // Add delete key for images from server
        this.$set(file, 'Delete', true)
      } else {
        // Remove deleted file if it's not server file
        this.files.splice(index, this.one)
      }
      this.emitFiles()
    },

    resetFiles() {
      this.files = []
    },
    // логіка роботи загрузки та видалення файлів на стороні фронта (кінець)
  },
}

</script>
