<template lang="pug">
  include /mixins
  +b.A.messages-header.header-text.menu-link--iconed(
    :href="$router.resolve({ name: CABINET_CHAT_NAME }).href"
  )
    +b.I.icon.icon-message-circle
    span {{ _("Повідомлення") }}
    +b.DIV.messages-count {{ totalCounter }}
</template>

<script>
import { mapState } from 'vuex'
import { CABINET_CHAT_NAME } from '@/consts'

export default {
  computed: {
    ...mapState('chat', ['totalCounter']),
  },

  data() {
    return {
      CABINET_CHAT_NAME,
    }
  },
}
</script>
