<template lang="pug">
  include ../../../mixins
  +b.g-row.--space_sm.--appearance_spaced
    +b.g-cell(
      v-for="socialName in socials"
      :key="socialName"
    )
      share-network(
        :url="shareUrl"
        :title="shareTitle"
        :network="socialName"
      )
        +b.i-action
          +b.I(:class="`icon-${socialName}`")
    +b.g-cell
      +b.i-action(
        v-clipboard:copy="shareUrl"
        v-clipboard:success="copied"
      )
        +b.I.icon-copy
</template>

<script>
export default {
  props: {
    shareUrl: String,
    shareTitle: String,
  },

  data() {
    return {
      socials: [
        'facebook',
        'linkedin',
        'twitter',
        'telegram',
        'email',
      ],
    }
  },

  methods: {
    copied() {
      this.$notify(this._('Скопировано'))
    },
  },
}
</script>
