<template lang="pug">
include /mixins
+b.chat-room
  +e.wrapper(
    :style="{ height: wrapperHeight }"
    :class="{ 'chat-room__wrapper--align_center': !messageGroups.length }"
  )
    +e.content(
      v-if="messageGroups.length"
      ref="scroller"
      @scroll.prevent="debounceAction"
    )
      +b.chat-message-date-group(v-for="dateGroup in messageGroups")
        +e.divider
          +e.SPAN.date {{ dateGroup.prettyDate }}
        +e.content
          chat-message-group(
            v-for="(group, index) in dateGroup.groups"
            :alignment="group.alignment"
            :group="group"
            :key="index"
            :scroller-element="scroller"
          )
    +e.content(
      v-else
      ref="scroller"
    )
      +b.P.ds-caption.--color-dark.--size_sm.--size_md-xl.--appearance_center.--weight_bold {{ _("Список сообщений пуст") }}
  +e.dialog(v-if="currentRoom && !currentRoom.companionIsBanned && !currentRoom.iAmBanned")
    chat-dialog-wrapper(
      :id="activeRoomId"
      @update:height="updateHeight"
    )
  +e.hint(v-else)
    div(v-if="currentRoom")
      +b.P.ds-caption.--size_xs.--size_sm-xl.--weight_bold(
        v-if="currentRoom.companionIsBanned"
      ) {{ _("Ви не можете написати повідомлення цьому користувачу, оскільки ви його заблокували") }}
      +b.P.ds-caption.--size_xs.--size_sm-xl.--weight_bold(
        v-if="currentRoom.iAmBanned"
      ) {{ _("Ви не можете написати повідомлення цьому користувачу, оскільки він вас заблокував") }}
</template>

<script>
import { debounce } from 'vue-debounce'
import { emitter } from './EventEmmiter'

export default {
  props: {
    isMessagesLoading: {
      type: Object,
    },
    activeRoomId: {
      type: Number,
    },
    messageGroups: {
      type: Array,
    },
    currentRoom: {
      type: Object,
    },
  },

  data() {
    return {
      scrollHeightBeforePaginate: null,
      debounceAction: null,
      scroller: {},
      fieldHeight: 24,
      fieldPadding: 26,
    }
  },

  computed: {
    wrapperHeight() {
      const h = this.fieldHeight + this.fieldPadding
      const { innerWidth } = window
      const isMobile = 992 > innerWidth

      return isMobile ? '50vh' : `calc(100% - ${h}px)`
    },
  },

  beforeDestroy() {
    emitter.unsubscribe('chat:messages:list', this.setStickyScroll)
    emitter.unsubscribe('chat:messages:update', this.setStickyScroll)
  },

  mounted() {
    this.updateScrollerElement()

    emitter.subscribe('chat:messages:list', this.setStickyScroll)
    emitter.subscribe('chat:messages:update', this.setStickyScroll)

    const delay = 300
    /**
     * add debounce to prevent multiple request on scroll
     */
    this.debounceAction = debounce(e => {
      this.handleScroll(e)
    }, delay)
  },

  methods: {
    updateScrollerElement() {
      this.scroller = this.$refs.scroller
    },

    scrollToBottom() {
      this.updateScrollerElement()
      /**
       * scroll to the bottom of the message list
       */
      this.scroller.scrollTop = this.scroller.scrollHeight
    },

    handleScroll(e) {
      const offset = 200
      const targetScrollTop = e.target.scrollTop
      /**
       * check that we have scrolled the list of messages to the top
       */
      if (targetScrollTop <= offset && !this.isMessagesLoading.value) {
        /**
         * create a new event for pagination by messages
         */
        this.$emit('chat:messages:pagination')
        /**
         * save initial scrolling height of the message list before pagination
         */
        this.updateScrollerElement()
        const { scrollHeight } = this.scroller
        /**
         * targetScrollTop is needed for prevent page shift on load new messages
         */
        this.scrollHeightBeforePaginate = scrollHeight - targetScrollTop
      }
    },

    setStickyScroll() {
      this.$nextTick(() => {
        /**
         * scroll to initial position which was before pagination
         */
        this.updateScrollerElement()
        const offset = this.scroller.scrollHeight - this.scrollHeightBeforePaginate
        this.scroller.scrollTop = offset
      })
    },

    updateHeight(fieldHeight) {
      this.fieldHeight = fieldHeight
    },
  },
}
</script>
