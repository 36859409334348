<template lang="pug">
  include /mixins
  +b.decoration
    +e.content
      +b.card.--space_sm.--radius_sm
        +b.VALIDATION-OBSERVER(
          ref="validator"
          tag="form"
          v-slot="{ errors: globalErrors }"
          @submit.prevent="prepareData"
        )
          +b.g-row.--appearance_spaced.--space_7xl.--space_2xl-xl.--space_sm-vertical-xl
            +b.g-cell.g-cols.--12-xs.--4-xl
              slot(name="contacts")
            +b.g-cell.g-cols.--12-xs.--8-xl
              +b.ds-panel.--space_xl.--space_6xl-xl
                +e.element.--offset_bottom
                  +b.P.ds-caption.--size_lg.--size_xl-sm.--size_2xl-xl.--size_xxl-mac.--weight_bold {{ _("У вас є запитання чи пропозиції?") }}
                  +b.P.ds-caption.--size_lg.--size_lg-sm.--size_2xl-xl.--size_xxl-mac {{ _("Не соромтеся писати нам") }}
              +b.g-row.--appearance_spaced.--space_lg.--space_2xl-xl.--space_sm-vertical-xl
                +b.g-cell.g-cols.--12-xs.--6-sm
                  +b.form-inp.--space_none
                    +b.input_label {{ _("Ім'я") }} *
                    input-group.input_style(
                      error-class="help-type-error--offset_none"
                      rules='required'
                      type="text"
                      id="name"
                      name="name"
                      placeholder=""
                      v-model="formdata.name"
                      :class="{'is-error': globalErrors.name && globalErrors.name.length}"
                    )
                +b.g-cell.g-cols.--12-xs.--6-sm
                  +b.form-inp.--space_none
                    +b.input_label {{ _("Прізвище") }}
                    input-group.input_style(
                      error-class="help-type-error--offset_none"
                      type="text"
                      id="surname"
                      name="surname"
                      placeholder=""
                      v-model="formdata.surname"
                      :class="{'is-error': globalErrors.surname && globalErrors.surname.length}"
                    )
                +b.g-cell.g-cols.--12-xs.--6-sm
                  +b.form-inp.--space_none
                    +b.input_label {{ _("Телефон") }}
                    input-group.input_style(
                      error-class="help-type-error--offset_none"
                      rules='phone'
                      type="tel"
                      id="phone"
                      name="phone"
                      placeholder=""
                      v-model="formdata.phone"
                      :class="{'is-error': globalErrors.phone && globalErrors.phone.length}"
                    )
                +b.g-cell.g-cols.--12-xs.--6-sm
                  +b.form-inp.--space_none
                    +b.input_label {{ _("Email") }} *
                    input-group.input_style(
                      error-class="help-type-error--offset_none"
                      rules='required|email'
                      type="email"
                      id="email"
                      name="email"
                      placeholder=""
                      v-model="formdata.email"
                      :class="{'is-error': globalErrors.email && globalErrors.email.length}"
                    )
                +b.g-cell.g-cols.--12-xs
                  +b.form-inp.--space_none
                    +b.input_label {{ _("Запитання або коментар") }} *
                    validation-provider(
                      id="comment"
                      name="comment"
                      rules="required",
                      v-slot="{ validate, errors }",
                      tag="div"
                      :class="{'is-error': globalErrors.comment && globalErrors.comment.length}"
                    ).input_style
                      +b.TEXTAREA(
                        ref='comment',
                        rows="8"
                        :disabled='false',
                        placeholder="",
                        v-model="formdata.comment"
                      )
                      +b.P.help-type-error.--offset_none(v-if="errors[0]") {{ errors[0] }}
                +b.g-cell.g-cols.--12-xs
                  +b.ds-panel.--space_xl
                    +e.element.--offset_top
                      +b.g-row.--space_xl.--appearance_spaced.--justify_between.--align_center
                        +b.g-cell
                          validation-provider(
                            tag="div"
                            v-slot="{ errors }"
                            :rules="{ required: { allowFalse: false } }"
                            name="agreement"
                          )
                            +b.LABEL.control-checkbox.--variant_flex
                              +e.INPUT.element(
                                type="checkbox"
                                v-model="formdata.agreement"
                              )
                              +e.label
                              +e.content.--space_sm-xl
                                span
                                  span {{ _("Згоден із") }}
                                  |&nbsp;
                                  +b.A.ds-link.--color_gray.--size_sm.--inline(
                                    :href="policyUrl"
                                    target="_blank"
                                  ) {{ _("правилами приватності") }}
                            +b.P.help-type-error.--offset_none(v-if="errors[0]") {{ errors[0] }}
                        +b.g-cell.g-cols.--12-xs.--4-md
                          +b.BUTTON.control-button(
                            @click.prevent='prepareData'
                          ) {{ _("НАДІСЛАТИ") }}
    +e.IMG.item.--variant_1(src="/static/img/decorate-1.png")
    +e.IMG.item.--variant_2(src="/static/img/decorate-2.png")
</template>

<script>
import FormMixin, { defaultValidatorErrorsParser } from '@mixins/SendMixin'
import { businessRequestCreateResource } from '@services/businessRequests.service'
import InfoModal from '@components/Modals/InfoModal'
import { clearPhone } from '@utils/helpers'

export default {
  name: 'ContactRequestForm',

  mixins: [
    FormMixin,
  ],

  data() {
    return {
      policyUrl: window.policyUrl,
      formdata: {
        name: null,
        surname: null,
        email: null,
        phone: null,
        comment: null,
      },
    }
  },

  methods: {
    updateValidator(errors) {
      const parsed = defaultValidatorErrorsParser(errors)
      this.$refs.validator.setErrors(parsed)
    },

    async prepareData() {
      const isValid = await this.$refs.validator.validate()

      if (!isValid) return

      this.submit(isValid, this.formdata)
    },

    send(data) {
      if (data.phone) {
        data.phone = clearPhone(data.phone)
      }
      data.typeOfRequest = 'question'

      return businessRequestCreateResource.execute({}, data).then(() => {
        this.openMessageModal()
        this.resetForm()
      })
    },

    openMessageModal() {
      this.$modal.show(InfoModal, {
        title: this._('Дякуємо за запит!'),
        information: this._("Ми з'єднаємось із Вами у найближчий час"),
      },
      {
        height: 'auto',
        width: 360,
        adaptive: true,
        scrollable: false,
        transition: 'false',
      })
    },

    resetForm() {
      Object.keys(this.formdata).forEach(k => {
        this.formdata[k] = null
      })

      this.$nextTick(() => {
        this.$refs.validator.reset()
      })
    },
  },
}
</script>
