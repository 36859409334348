<template lang="pug">
  include ./../../../../mixins
  +b.popup-wrapper--logout
    +b.I.icon.icon-bootsrap-x.btn-close-popup(
      @click='$emit("close")'
    )
    +b.popup-title {{ _('Бажаєте вийти?') }}
    +b.ds-panel.--space_xl
      +e.element.--offset_top
        +b.g-row.--space_md.--appearance_spaced
          +b.g-cell.g-cols.--auto
            +e.BUTTON.exit.btn-red.--fullwidth.--popup(
              type="button"
              @click='prepareData(true)'
              ) {{ _("Так") }}
          +b.g-cell.g-cols.--auto
            +e.BUTTON.close.btn-red.--fullwidth.--popup(type="button" @click='$emit("close")') {{ _("Ні") }}
</template>

<script>

import FormMixin from '../../../mixins/SendMixin'
import {
  logoutResource,
} from '../../../services/users.service'

export default {
  data() {
    return {
      serverError: '',
    }
  },
  mixins: [
    FormMixin,
  ],
  methods: {
    async prepareData(valid) {
      this.submit(valid, {})
    },
    send(data) {
      return logoutResource.execute({}, data)
        .then(() => {
          // після успішної разовтаризації, обновлюємо сторінку
          window.location.reload()
        })
    },
    updateValidator() {
    },
  },
}

</script>
