<template lang="pug">
  include ./../../../../mixins
  +b.form-base.registration-form
    +b.i-preloader.--absolute(v-if="isLoading")
      +e.item
    +b.form-top-info
      +b.title-and-link-wrapper
        +b.P.h1_title--register {{ _("Зареєструватися") }}
        +b.P.text-controls-wrapper
          +b.A.text-controls(:href="linkToLogin")
            +e.gray {{ _("Вже є акаунт? ") }}
            +e.red {{ _("Увійти") }}
      social-authorization
    validation-observer(
      ref="validator"
      tag="form"
      class="form-element"
      @submit.prevent='prepareData(valid)'
      v-slot="{ valid, errors: globalErrors }"
    )
      +b.server-error(v-if="serverError") {{ serverError[0] }}
      +b.form-inputs-wrap
        +b.form-inputs-register
          +b.form-inp-group-register
            +b.form-inp--register
              +b.input_label {{ _("Ім'я*") }}
              input-group(
                rules='required'
                type="text"
                id="firstName"
                name="firstName"
                placeholder=""
                v-model="formData['firstName']"
                :class="{'is-error': showInputError(globalErrors, 'firstName')}"
              ).input_style
                template(v-slot:error="{errors}")
                  transition(name="fade")
                    span.help-type-error(v-if='errors.length') {{ errors[0] }}
            +b.form-inp--register
              +b.input_label {{ _("Прізвище*") }}
              input-group(
                rules='required'
                type="text"
                id="lastName"
                name="lastName"
                placeholder=""
                v-model="formData['lastName']"
                :class="{'is-error': showInputError(globalErrors, 'lastName')}"
              ).input_style
                template(v-slot:error="{errors}")
                  transition(name="fade")
                    span.help-type-error(v-if='errors.length') {{ errors[0] }}
            +b.form-inp--register
              +b.input_label {{ _("Дата народження*") }}
              +b.I.icon.icon-calendar.input_icon.--events_none
              validation-provider(
                v-slot="{errors}"
                rules="required"
                name="dateOfBirth"
                :class="{'is-error': showInputError(globalErrors, 'dateOfBirth')}"
              ).input_style.flat-pickr-input
                flat-pickr(
                  v-model="formData['dateOfBirth']"
                  :config="config"
                  name="dateOfBirth"
                  placeholder=""
                )
                span.help-type-error(v-if='errors.length') {{ errors[0] }}
          +b.form-inp-group-register
            +b.form-inp--register
              +b.input_label {{ _("E-mail*") }}
              input-group(
                rules='required|email'
                type="email"
                id="email"
                name="email"
                placeholder=""
                v-model="formData['email']"
                :class="{'is-error': showInputError(globalErrors, 'email')}"
              ).input_style
                template(v-slot:error="{errors}")
                  transition(name="fade")
                    span.help-type-error(v-if='errors.length') {{ errors[0] }}
            +b.form-inp--register
              +b.input_label {{ _("Пароль*") }}
              +b.I.icon.icon-password-secret.input_icon.--color_gray.--weight_bold(
                @click="switchVisibility(0)"
                v-if="typeOfPassword[0] === 'password'"
              )
              +b.I.icon.icon-password-public.input_icon.--color_gray.--weight_bold(
                @click="switchVisibility(0)"
                v-else
              )
              input-group(
                rules='required|min:6',
                :type='typeOfPassword[0]',
                id="passwordOne",
                name="passwordOne",
                placeholder="",
                v-model="formData['passwordOne']",
                vid="confirmation",
                :class="{'input_style--password': typeOfPassword[0] === 'password', 'is-error': showInputError(globalErrors, 'passwordOne')}",
              ).input_style
                template(v-slot:error="{errors}")
                  transition(name="fade")
                    span.help-type-error(v-if='errors.length') {{ errors[0] }}
            +b.form-inp--register
              +b.input_label {{ _("Повторіть пароль*") }}
              +b.I.icon.icon-password-secret.input_icon.--color_gray.--weight_bold(
                @click="switchVisibility(1)"
                v-if="typeOfPassword[1] === 'password'"
              )
              +b.I.icon.icon-password-public.input_icon.--color_gray.--weight_bold(
                @click="switchVisibility(1)"
                v-else
              )
              input-group(
                rules='required|confirmed:confirmation',
                :type='typeOfPassword[1]',
                id="passwordTwo",
                name="passwordTwo",
                placeholder="",
                v-model="formData['passwordTwo']",
                :class="{'input_style--password': typeOfPassword[1] === 'password', 'is-error': showInputError(globalErrors, 'passwordTwo')}",
              ).input_style
                template(v-slot:error="{errors}")
                  transition(name="fade")
                    span.help-type-error(v-if='errors.length') {{ errors[0] }}
          +b.form-inp-group-register
            +b.form-inp--register.multiselect-wrapper
              autocomplete-select(
                v-model="formData.country"
                ref="country"
                rules="required"
                :filter="autocomplete.country"
                :required="true"
                :need-initial-receive="true"
                @input="getRegions"
              )
            +b.form-inp--register.multiselect-wrapper
              autocomplete-select(
                v-model="formData.region"
                ref="region"
                rules=""
                :filter="autocomplete.region"
                :required="false"
                :need-initial-receive="false"
                :params="{ country: formData.country.slug }"
                @input="getCities"
              )
            +b.form-inp--register.multiselect-wrapper
              autocomplete-select(
                v-model="formData.city"
                ref="city"
                rules="required"
                :filter="autocomplete.city"
                :required="true"
                :need-initial-receive="false"
                :params="{ country: formData.country.slug, region: formData.region.slug }"
                @input="cityChangedHandler"
              )
        +b.form-inputs-register-checks-wrapper
          +b.g-row.--appearance_spaced.--space_2xl-xl
            +b.g-cell.g-cols
              validation-provider(
                v-slot="{errors}"
                rules='must-be-true'
                name="siteRules"
                tag='div'
                class='check-box-wrapper'
              )
                +b.LABEL.control-checkbox.--variant_flex
                  +e.INPUT.element(
                    type='checkbox'
                    v-model="formData.siteRules"
                  )
                  +e.label
                  +e.SPAN.content.--space_sm-xl {{ _("Я погоджуюсь з правилами сайту та політикою конфіденційності") }}
                span.help-type-error.help-type-error--register-text(v-if='errors[0]') {{ errors[0] }}
            +b.g-cell.g-cols
              validation-provider(
                v-slot="{errors}"
                rules='must-be-true'
                name="ageAdult"
                tag='div'
                class='check-box-wrapper'
              )
                +b.LABEL.control-checkbox.--variant_flex
                  +e.INPUT.element(
                    type='checkbox'
                    v-model="formData.ageAdult"
                  )
                  +e.label
                  +e.SPAN.content.--space_sm-xl {{ _("Я підтверджую, що на момент реєстрації мені виповнилося 18 років") }}
                span.help-type-error.help-type-error--register-text(v-if='errors[0]') {{ errors[0] }}
            +b.g-cell.g-cols
              validation-provider(
                v-slot="{errors}"
                rules='required'
                name="showMeInMeeting"
                tag='div'
                class='check-box-wrapper'
              )
                +b.LABEL.control-checkbox.--variant_flex
                  +e.INPUT.element(
                    type='checkbox'
                    v-model="formData.showMeInMeeting"
                  )
                  +e.label
                  +e.SPAN.content.--space_sm-xl {{ _("Відображати мій профіль у каталозі знайомств") }}
                span.help-type-error.help-type-error--register-text(v-if='errors[0]') {{ errors[0] }}
        +b.form-register-bottom-wrapper
          +b.form-description
            +e.text {{ _("після реєстрації на сайті у вас буде доступ до об’яв в каталогах нерухомість та робота. активацію розділу") }}
            +e.text {{ _("знайомства та подачу власних оголошень можна зробити у власному акаунті користувача") }}
          +b.form-button
            +b.BUTTON.btn-red--register(
              type="submit",
              @click.prevent='prepareData(valid)'
            ) {{ _("Зареєструватись") }}
</template>

<script>
import AutocompleteSelect from '@components/Filters/Widgets/Autocomplete'
import {
  countriesSearchResource,
  regionsSearchFilterResource,
  citiesSearchFilterResource,
} from '@services/countries.service'
import {
  registrationResource,
} from '@services/users.service'
import FormMixin, { defaultValidatorErrorsParser } from '../../../mixins/SendMixin'
import InfoModal from '../../Modals/InfoModal'

export default {
  components: {
    AutocompleteSelect,
  },

  data() {
    return {
      date: null,
      formData: {
        email: '',
        passwordOne: '',
        passwordTwo: '',
        firstName: '',
        lastName: '',
        dateOfBirth: null,
        country: {},
        region: {},
        city: {},
        siteRules: false,
        ageAdult: false,
        showMeInMeeting: true,
      },
      serverError: '',
      typeOfPassword: [
        'password',
        'password',
      ],
      linkToLogin: window.loginPageLink,
      isLoading: false,
      config: {
        dateFormat: 'Y-m-d',
        allowInput: false,
        altInput: true,
        altFormat: 'd.m.Y',
        disableMobile: 'false',
        wrap: true,
        locale: window.language,
      },
      autocomplete: {
        country: {
          inputLabel: this._('Страна'),
          name: 'country',
          label: 'name',
          resource: countriesSearchResource,
        },
        region: {
          inputLabel: this._('Регион'),
          name: 'district',
          label: 'name',
          resource: regionsSearchFilterResource,
        },
        city: {
          inputLabel: this._('Город'),
          name: 'city',
          label: 'name',
          resource: citiesSearchFilterResource,
        },
      },
    }
  },
  mixins: [
    FormMixin,
  ],
  methods: {
    resetErrors() {
      this.$refs.validator.reset()
      this.nonFieldErrors = []
    },
    async prepareData(valid) {
      this.$refs.validator.validate()
      if (!valid) return
      this.submit(valid, {
        email: this.formData.email,
        passwordOne: this.formData.passwordOne,
        passwordTwo: this.formData.passwordTwo,
        firstName: this.formData.firstName,
        lastName: this.formData.lastName,
        dateOfBirth: this.formData.dateOfBirth,
        country: this.formData.country.slug,
        region: this.formData.region ? this.formData.region.slug : '',
        city: this.formData.city.slug,
        showMeInMeeting: this.formData.showMeInMeeting,
      })
    },
    send(data) {
      this.isLoading = true

      return registrationResource.execute({}, data).then(() => {
        this.$refs.validator.reset()
        // якщо все добре, то відкриваємо попап з інфою
        this.$modal.show(InfoModal, {
          title: this._('Дякуємо!'),
          information: this._('Реєстрацію майже завершено. На вказаний email було направлено листа для підтвердження.'),
        }, {
          height: 'auto',
          width: 360,
          adaptive: true,
          scrollable: false,
          transition: 'false',
        }, {
          'before-close': () => {
            window.location.pathname = window.indexPageLink
          },
        })
        // Обнуляємо дані форми
        this.resetData()
      }).finally(() => {
        this.isLoading = false
      })
    },
    resetData() {
      this.formData = {
        email: '',
        passwordOne: '',
        passwordTwo: '',
        firstName: '',
        lastName: '',
        dateOfBirth: null,
        siteRules: false,
        ageAdult: false,
        country: null,
        region: null,
        city: null,
      }
    },
    updateValidator(errors) {
      const parsed = defaultValidatorErrorsParser(errors)
      if ('nonFieldErrors' in parsed) {
        this.serverError = parsed.nonFieldErrors
      } else {
        this.serverError = parsed.message
      }
      this.$refs.validator.setErrors(parsed)
      this.isLoading = false
    },
    goToUserPersonalPage() {
      window.location.pathname = '/cabinet/'
    },
    switchVisibility(index) {
      this.typeOfPassword[index] = 'password' === this.typeOfPassword[index] ? 'text' : 'password'
      const pass = this.typeOfPassword
      this.typeOfPassword = ''
      this.typeOfPassword = pass
    },
    showInputError(errors, inpName) {
      if (errors[inpName] && 0 < errors[inpName].length) {
        return true
      }
      return false
    },

    getRegions() {
      this.formData.region = {}
      this.formData.city = {}
      this.$nextTick(() => {
        this.$refs.region.receiveWithParams()
        this.$refs.city.receiveWithParams()
      })
    },

    getCities(data) {
      this.formData.country = data.country
      this.formData.city = {}
      this.$nextTick(() => {
        this.$refs.city.receiveWithParams()
      })
    },

    cityChangedHandler(data) {
      console.log(data)
      this.formData.region = data.region
      this.formData.country = data.country
    },
  },
}

</script>
