<template lang="pug">
include /mixins
div
  +b.form-top-info
    +b.g-row.--appearance_spaced.--justify_between.--align_center.full-width
      +b.g-cell.g-cols.--auto
        +b.P.h1_title--cabinet {{ _("Ваші повідомлення") }}
      +b.g-cell.g-cols.--narrow(v-if="activeRoomId")
        +b.g-row.--appearance_spaced
          +b.g-cell.g-cols
            +b.i-circle.--size_md.--hover_red(
              @click.prevent="blockUser"
              :title="currentRoom.companionIsBanned ? _('Разблокировать') : _('Заблокировать')"
            )
              +e.icon
                +b.I.icon-minus-circle
          +b.g-cell.g-cols
            +b.i-circle.--size_md.--hover_red(
              @click.prevent="deleteRoomHandler"
              :title="_('Удалить чат с пользователем')"
            )
              +e.icon
                +b.I.icon-trash
  +b.form-spliter-wrapper
    +b.form-spliter
  +b.chat
    chat-audio(:url="'/static/assets/notification.mp3'")
    +e.wrapper
      +e.info(:class="{ 'is-active': activeRoomId }")
        chat-room-list(
          :rooms="rooms"
          :active-room-id="activeRoomId"
          :is-room-loading="isRoomLoading"
          @chat:rooms:open="openRoom"
          @chat:rooms:pagination="updatePagination(roomsPagination, 'rooms')"
        )
      +e.main(:class="{ 'is-active': activeRoomId }")
        +b.i-preloader.--absolute(v-if="isMessagesLoading.value")
          +e.item
        template(v-if="activeRoomId")
          +b.ds-panel.--space_sm.is-hidden-lg
            +e.element.--offset_bottom.--border_bottom
              +b.ds-panel.--space_sm
                +e.element.--indent_inside.--offset_bottom
                  +b.A.ds-link.--color_black.--size_xs.--bold.is-passive(
                    @click.prevent="toRoomsList"
                  )
                    +b.g-row.--align_center.--space_sm.--appearance_spaced
                      +b.g-cell
                        +b.flex
                          +b.I.icon-chevronb-left
                      +b.g-cell
                        span {{ _("К списку чатов") }}
          chat-room(
            :key="activeRoomId"
            :active-room-id="activeRoomId"
            :current-room="currentRoom"
            :message-groups="messageGroups"
            :is-messages-loading="isMessagesLoading"
            @chat:messages:pagination="updatePagination(messagesPagination, 'messages')"
          )
        +b.P.ds-caption.--color-dark.--size_sm.--size_md-xl.--appearance_center.--weight_bold(v-else) {{ _('Щоб розпочати діалог виберіть співбесідника') }}
</template>

<script>
import moment from 'moment'
import InfoModal from '@components/Modals/InfoModal'
import { groupWith } from 'ramda'
import { debounce } from 'vue-debounce'
import { roomsList, roomDelete, messagesList, messagesRead, messageDelete } from '@services/chat.service'
import { banUserAppendRemoveResource } from '@services/users.service'
import { CURRENT_LANGUAGE } from '@utils/urls'
import { openConfirmModal } from '@utils/helpers'
import { emitter } from './EventEmmiter'

const USER_ID = Number(window.currentUserId)
const ROOMS_PAGINATION_SHIFT = 20
const MESSAGES_PAGINATION_SHIFT = 10

/**
 * set current locale to moment
 * for displaying localized months names for example
 */
moment.locale(CURRENT_LANGUAGE)

export default {
  data() {
    return {
      isRoomLoading: {
        value: false,
      },
      isMessagesLoading: {
        value: false,
      },
      messageGroups: [],
      initialMessages: [],
      messagesPagination: {},
      rooms: [],
      roomsPagination: {
        limit: ROOMS_PAGINATION_SHIFT,
        offset: 0,
        total: 0,
      },
      activeRoomId: null,
      debounceReadMessages: null,
      messagesToRead: [],
    }
  },

  beforeDestroy() {
    emitter.unsubscribe('message:update', this.updateMessages)
    emitter.unsubscribe('room', this.updateRooms)
    emitter.unsubscribe('chat:message:read', this.setReadMessagesId)
    emitter.unsubscribe('chat:message:delete:request', this.deleteMessage)
    emitter.unsubscribe('message:remove', this.removeMessage)
    emitter.unsubscribe('room:remove', this.removeRoom)
  },

  async created() {
    const delay = 300
    /**
     * add debounce to prevent multiple requests
     */
    this.debounceReadMessages = debounce(() => {
      this.readMessages()
    }, delay)

    await this.getRoomsList()

    this.preselectRoom()

    this.setInitialMessagePagination()
    this.subscribeOnEvents()
  },

  computed: {
    currentRoom() {
      return this.rooms.find(el => el.id === this.activeRoomId)
    },
  },

  methods: {
    /**
     * @param {array} items
     */
    prepeareRooms(items) {
      return items.map(el => {
        console.log(el)
        el.lastMessageAt = moment(el.dataOfLastMessage).format('DD.MM.YYYY')
        return el
      })
    },

    /**
     * @param {string} event
     */
    async getRoomsList(event) {
      this.isRoomLoading.value = true
      // this.rooms = this.prepeareRooms(this.rooms)

      await roomsList.execute(this.roomsPagination).then(res => {
        const { data: { items, pagination } } = res

        this.roomsPagination = pagination

        const prepearedRooms = this.prepeareRooms(items)

        /**
         * adding rooms to previously received
         */
        if ('chat:rooms:pagination' === event) {
          this.rooms.push(...prepearedRooms)
          /**
           * create a new event on pagination
           */
          this.createEventEmitter('chat:rooms:update')
        } else {
          /**
           * completely refresh the room list
           */
          this.rooms = prepearedRooms
          /**
           * create a new event on list getting
           */
          this.createEventEmitter('chat:rooms:list')
        }
        this.isRoomLoading.value = false
      })
    },

    preselectRoom() {
      const { search } = window.location
      const searchParams = new URLSearchParams(search)
      const roomId = searchParams.get('room')

      if (roomId) {
        this.activeRoomId = Number(roomId)

        this.getMessagesList('chat:messages:list')

        this.clearQueryParams()
      }
    },

    clearQueryParams() {
      window.history.replaceState(null, null, window.location.pathname)
    },

    /**
     * @param {string} event
     */
    getMessagesList(event) {
      /**
       * create an object with parameters that will be transformed
       * to a query string for recieve messages request
       */
      const params = { id: this.activeRoomId, ...this.messagesPagination }

      this.isMessagesLoading.value = false

      /**
       * message list request
       */
      messagesList.execute(params).then(res => {
        const { data: { items, pagination } } = res

        this.messagesPagination = pagination

        if ('chat:messages:pagination' === event) {
          /**
           * adding messages to previously received
           */
          this.initialMessages.unshift(...items)
          this.transformItems(this.initialMessages)
          /**
           * create a new event on pagination
           */
          this.createEventEmitter('chat:messages:update')
        } else {
          /**
           * completely refresh the message list
           */
          this.initialMessages = items
          this.transformItems(this.initialMessages)
          /**
           * create a new event on list getting
           */
          this.createEventEmitter('chat:messages:list')
        }
        this.isMessagesLoading.value = false
      })
    },

    /**
     * @param {string} eventName
     */
    createEventEmitter(eventName) {
      this.$nextTick(() => {
        emitter.emit(eventName)
      })
    },

    /**
     * @param {object} pagination
     * @param {string} event
     */
    updatePagination(pagination, event) {
      const { limit, offset, total } = pagination
      /**
       * calc current amount of displayed messages
       */
      const itemsCount = limit + offset
      /**
       * if total of all messages more than current amount
       * we have opportunity to pagination
       */
      if (total > itemsCount) {
        const eventMap = {
          rooms: {
            shift: ROOMS_PAGINATION_SHIFT,
            method: this.getRoomsList,
            emitEvent: 'chat:rooms:pagination',
          },
          messages: {
            shift: MESSAGES_PAGINATION_SHIFT,
            method: this.getMessagesList,
            emitEvent: 'chat:messages:pagination',
          },
        }

        const eventObject = eventMap[event]

        /**
         * increase pagination offset by pagination shift
         */
        pagination.offset += eventObject.shift

        /**
         * get messages or rooms list with updated pagination depending of event key
         */
        eventObject.method(eventObject.emitEvent)
      }
    },

    /**
     * @param {array} items
     */
    transformItems(items) {
      let copiedItems = JSON.parse(JSON.stringify(items))
      copiedItems = this.sortByDate(copiedItems)
      copiedItems = this.setMessageAlignment(copiedItems)
      copiedItems = this.groupByDate(copiedItems)
      this.messageGroups = copiedItems
    },

    /**
     * @param {array} items
     */
    sortByDate(items) {
      /**
       * sort messages by date from newest (at the bottom of the list)
       * to oldest (at the top of the list)
       */
      return items.sort((a, b) => moment(a.createdAt) - moment(b.createdAt))
    },

    /**
     * @param {array} items
     */
    setMessageAlignment(items) {
      return items.map(el => {
        const isMine = el.sender.id === USER_ID
        el.isMine = isMine
        el.alignment = isMine ? 'right' : 'left'
        return el
      })
    },

    /**
     * @param {array} items
     */
    groupByDate(items) {
      const formatDate = date => moment(date).format('DD.MM.YYYY')
      const groupedWith = groupWith((a, b) => formatDate(a.createdAt) === formatDate(b.createdAt), items)
      const grouped = groupedWith.reduce((acc, messages) => {
        const [message] = messages
        const preparedItems = this.groupByAuthor(messages)

        acc.push({
          groups: preparedItems,
          prettyDate: moment(message.createdAt).format('DD MMM YYYY'),
        })
        return acc
      }, [])
      return grouped
    },

    /**
     * @param {array} items
     */
    groupByAuthor(items) {
      const groupedWith = groupWith((a, b) => a.sender.id === b.sender.id, items)
      const grouped = groupedWith.reduce((acc, messages) => {
        const [message] = messages
        acc.push({
          messages,
          senderFullName: message.sender.fullName,
          senderAvatar: message.sender.avatar,
          url: message.sender.url,
          alignment: message.alignment,
        })
        return acc
      }, [])
      return grouped
    },

    /**
     * @param {number} id
     */
    openRoom(id) {
      this.activeRoomId = id
      /**
       * clear initial messages and reset pagination
       */

      // this.initialMessages = []
      this.transformItems(this.initialMessages)

      this.setInitialMessagePagination()
      /**
       * get messages of opened room
       */
      this.getMessagesList('chat:messages:list')
      /**
       * add event to focus on textarea in room
       */
      this.createEventEmitter('chat:rooms:open')
    },

    setInitialMessagePagination() {
      this.messagesPagination = {
        limit: MESSAGES_PAGINATION_SHIFT,
        offset: 0,
        total: 0,
      }
    },

    subscribeOnEvents() {
      emitter.subscribe('message:update', this.updateMessages)
      emitter.subscribe('room', this.updateRooms)
      emitter.subscribe('chat:message:read', this.setReadMessagesId)
      emitter.subscribe('chat:message:delete:request', this.deleteMessageHandler)
      emitter.subscribe('message:remove', this.removeMessage)
      emitter.subscribe('room:remove', this.removeRoom)
    },

    /**
     * @param {object} item
     */
    updateMessages(item) {
      if (item.roomId === this.activeRoomId) {
        const zero = 0
        /**
         * check that message exists
         */
        const messageIndex = this.initialMessages.findIndex(el => el.id === item.id)

        /**
         * if message exists that we should update it
         */
        if (messageIndex >= zero) {
          this.initialMessages[messageIndex].status = item.status
          this.updateMessageStatus(item)
        /**
         * if message doesn't exist we add this message to list
         */
        } else {
          /**
           * push a new message from the socket to the initial messages
           */
          this.initialMessages.push(item)

          /**
           * update pagination offset for correct next pagination
           */
          this.messagesPagination.offset++

          this.transformItems(this.initialMessages)

          /**
           * add event for scrolling down if the current user is a sender
           */
          if (item.sender.id === USER_ID) {
            this.createEventEmitter('chat:messages:list')
          }
        }
      } else {
        this.getRoomsList()
      }
    },

    /**
     * @param {object} item
     */
    updateMessageStatus(item) {
      /**
       * iterate by date groups
       */
      this.messageGroups = this.messageGroups.map(el => {
        /**
         * iterate by author groups
         */
        el.groups = el.groups.map(group => {
          /**
           * iterate by messages
           */
          group.messages = group.messages.map(message => {
            /**
             * find a message by the id of updated message
             * to change its status
             */
            if (message.id === item.id) {
              message.status = item.status
            }
            return message
          })
          return group
        })
        return el
      })
    },

    /**
     * @param {object} item
     */
    updateRooms(item) {
      /**
       * check that them room already exists
       */
      const roomIndex = this.rooms.findIndex(room => room.id === item.id)
      const zero = 0

      if (roomIndex >= zero) {
        /**
         * update count of new messages
         */
        this.rooms[roomIndex].newMessages = item.newMessages
      } else {
        /**
         * add a new room from the socket to the rooms
         */
        this.rooms.unshift(item)

        /**
         * update pagination offset for correct next pagination
         */
        this.roomsPagination.offset++
      }
    },

    /**
     * @param {object} item
     */
    setReadMessagesId(item) {
      /**
       * add message id to array for read request
      */
      this.messagesToRead.push(item.id)
      /**
       * call debounce method for prevent multiply requests
       * and send an array of messages ids to read
       */
      this.debounceReadMessages()
    },

    readMessages() {
      const formdata = {
        messages: this.messagesToRead,
        room: this.activeRoomId,
      }
      messagesRead.execute({}, formdata).then(() => {
        /**
         * reset array of messages ids to read
         */
        this.messagesToRead = []
      })
    },

    /**
     * method to leave the room
     */
    toRoomsList() {
      this.activeRoomId = null
      this.initialMessages = []
      this.setInitialMessagePagination()
    },

    async deleteMessageHandler(item) {
      const info = {
        title: this._('Ви впевнені, що хочете видалити це повідомдення?'),
      }
      await openConfirmModal(info, this)

      messageDelete.execute({}, { messageId: item.id })
    },

    /**
     * @param {number} messageId
     */
    removeMessage(messageId) {
      this.initialMessages = this.initialMessages.filter(el => el.id !== messageId)

      this.messageGroups = this.messageGroups.map(el => {
        el.groups = el.groups.map(group => {
          group.messages = group.messages.filter(m => m.id !== messageId)
          return group
        })
        el.groups = el.groups.filter(group => group.messages.length)
        return el
      })
      this.messageGroups = this.messageGroups.filter(el => el.groups.length)
    },

    async blockUser() {
      const info = {
        title: this._('Ви впевнені, що хочете заблокувати юзера?'),
        text: this._('У разі блокування юзера, він не зможе вам писати. Аналогічно, ви самі не зможете написати користувачеві'),
      }
      await openConfirmModal(info, this)

      const action = this.currentRoom.companionIsBanned ? 'delete' : 'append'
      const formdata = {
        userId: this.currentRoom.companionId || 1,
        action,
      }
      banUserAppendRemoveResource.execute({}, formdata).then(() => {
        this.rooms = this.rooms.map(el => {
          if (el.id === this.currentRoom.id) {
            el.companionIsBanned = !el.companionIsBanned
          }
          return el
        })
      })
    },

    async deleteRoomHandler() {
      const info = {
        title: this._('Ви впевнені, що хочете видалити чат с юзером?'),
      }
      await openConfirmModal(info, this)

      const formdata = { roomId: this.activeRoomId }

      roomDelete.execute({}, formdata)
    },

    removeRoom(roomId) {
      this.rooms = this.rooms.filter(item => item.id !== roomId)

      if (this.activeRoomId === roomId) {
        this.activeRoomId = null
        this.messageGroups = []

        this.$modal.show(InfoModal, {
          title: this._('Кімната була видалена'),
        }, {
          height: 'auto',
          width: 360,
          adaptive: true,
          scrollable: false,
          transition: 'false',
        })
      }
    },
  },
}
</script>
