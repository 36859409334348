<template lang='pug'>
  include /mixins
  div
    +b.i-catalog
      +e.sidebar
        catalog-sidebar(
          :filters="filters"
          @update:filters="changeFilters"
          :pagination="pagination",
          :attributesResource='attributesResource',
          :additionalResource="additionalResource"
          :label='label'
          :totalItems="totalItems"
          :filterName="filterName"
          :needSearch="needSearch"
          :needPriceFilter="needPriceFilter"
          :priceFilterTitle="priceFilterTitle"
        )
      +e.content
        +b.i-preloader.--variant_1(v-if="loading")
          +e.item.--size_md
        renderer(
          v-if="result"
          :result="result.data"
        )

        slot(
          v-else
          name="content"
        )

    +b.ds-panel.--space_md.--space_8xl-xl(
      v-if="pagination.total > pagination.limit"
    )
      +e.element.--offset_top
        ui-limit-pagination(
          :pagination='pagination'
          @input='handlePaginate'
        )
</template>

<script>
import CatalogSidebar from './Sidebar'
import Renderer from './Renderer'

export default {
  components: {
    CatalogSidebar,
    Renderer,
  },

  props: [
    'result',
    'loading',
    'receive',
    'parameters',
    'filters',
    'changeFilters',
    'pagination',
    'changePagination',
    'attributesResource',
    'additionalResource',
    'label',
    'filterName',
    'totalItems',
    'needSearch',
    'needPriceFilter',
    'priceFilterTitle',
  ],

  methods: {
    handlePaginate({ limit: limitValue, offset }) {
      if (this.loading) {
        return
      }
      const limit = limitValue || this.pagination.limit
      this.changePagination({ limit, offset })
    },
  },
}

</script>
