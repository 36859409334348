<template lang="pug">
  include ./../../../../mixins
  +b.popup-wrapper
    +b.I.icon.icon-bootsrap-x.btn-close-popup(
      @click='$emit("close")'
    )
    +b.P.popup-title--center {{ _('Моя галерея') }}
    +b.gallery-slider-wrapper
      +e.slides
        ui-slider(
          :options='sliderOptions'
          :reiniter='reiniter'
        )
          template(#slide)
            +b.DIV.gallery-slide(
              v-for="file in files"
            )
              +e.img-wrapper
                +e.IMG.image(
                  :src='file.image'
                )
      +e.arrows
        +b.volunteer-slider-arrows
</template>

<script>

export default {
  props: {
    files: [],
    initialSlide: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      reiniter: null,
      sliderOptions: {
        dots: false,
        infinite: false,
        slidesToScroll: 1,
        arrows: true,
        slidesToShow: 1,
        initialSlide: this.initialSlide,
        autoplay: false,
        variableWidth: false,
        prevArrow: "<div class='slider-arrow slider-arrow--previous' aria-label='Previous'><i class='icon icon-chevronb-left'></div>",
        nextArrow: "<div class='slider-arrow slider-arrow--next' aria-label='Next'><i class='icon icon-chevronb-right'></div>",
        appendArrows: '.volunteer-slider-arrows',
        responsive: [
          {
            breakpoint: 768,
            settings: {
              arrows: false,
            },
          },
        ],
      },
    }
  },
  methods: {

  },
  // mounted() {

  // },
}
</script>
