import './public-path'

import Vue from 'vue'
import I18n from 'vue-i18n'
import Router from 'vue-router'
import { Store } from 'vuex'

import TagPlugin from '@aspectus/vue-tag'
import DermisPlugin from '@aspectus/vue-dermis'
import VueBem from '@aspectus/vue-bem'

import Multiselect from 'vue-multiselect'
import VModal from 'vue-js-modal'
import VueFlatPickr from 'vue-flatpickr-component'
import VueObserveVisibility from 'vue-observe-visibility'
import ResourceComponents from '@resource'

import { createValidationRules } from './js/validation/extends'

import './js/validation'
import './js/utils/index'
import './js/directives'
import filters from '@/js/utils/filters'

import { install } from '@/install'
import { createApp } from '@/app'

import './styles/index.sass'
import appComponents from './js'

Vue.config.productionTip = false

Vue.use(TagPlugin)
Vue.use(filters)
Vue.use(DermisPlugin, {
  prefix: 'ds-',
  prefixType: 't-',
  prefixUtils: 'u-',
})
Vue.use(VueBem, {
  blockNameKey: 'block',
})

Vue.component('multiselect', Multiselect)
Vue.use(VModal, { dynamic: true, dialog: true })
Vue.use(VueFlatPickr)
Vue.use(VueObserveVisibility)

Vue.use(install)
Vue.use(appComponents)
Vue.use(ResourceComponents)

const { app } = createApp({
  Vue,
  I18n,
  Router,
  Store,
})

app.$mount('#app')

createValidationRules(app)
