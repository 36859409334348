<template lang="pug">
  include ./../../../../../mixins
  +b.cabinet-favourites-wrapper
    +b.cabinet-title-wrapper.base-block-item
      +b.form-top-info
        +b.form-title-wrapper
          +b.P.h1_title--cabinet {{ _("Мої симпатії") }}
    +e.content-wrapper
      +b.user-list-wrapper(v-if='users.length')
        user-preview-card(
          v-for='(user, index) in users'
          :user="user"
          :key="user.id"
          :index="index"
          @deleteFromFavouritesEventCard='ondeleteFromFavourites'
        )
        +e.P.info(v-if='!users.length') {{ _("Ви ще не обрали жодного користувача") }}
        ui-limit-pagination(
          :pagination='pagination'
          @input='handlePaginate'
        )
      +b.cabinet-additional-wrapper
        info-banner(
          v-for='banner in banners',
          v-bind:key="banner.id",
          :banner='banner'
        )
</template>

<script>
import {
  favouriteUsersOfUserResource,
} from '../../../../services/users.service'
import {
  cabinetBannersResource,
} from '../../../../services/banners.service'

export default {
  components: {
    'info-banner': () => import('./InfoBanner'),
  },

  data() {
    return {
      users: [],
      banners: [],
      pagination: {
        limit: 10,
      },
    }
  },
  async mounted() {
    await this.getUserFavorites()
  },
  methods: {
    async getUserFavorites() {
      await favouriteUsersOfUserResource.execute(
        this.pagination
      ).then(({ data }) => {
        const { items, pagination } = data
        this.users = items
        this.pagination = pagination
      })
      await cabinetBannersResource.execute(
        {
          cabinet: 'favourites',
          limit: this.pagination.limit,
          offset: this.pagination.offset,
        }
      ).then(({ data }) => {
        const { item } = data
        this.banners = item
        console.log(this.banners)
      })
    },
    handlePaginate({ limit: limitValue, offset }) {
      if (this.loading) {
        return
      }
      const limit = limitValue || this.pagination.limit
      this.changePagination({ limit, offset })
    },
    changePagination(pagination) {
      this.pagination.limit = pagination.limit
      this.pagination.offset = pagination.offset
      this.getUserFavorites()
    },
    ondeleteFromFavourites({ index }) {
      this.users.splice(index, 1)
    },
  },
}
</script>
