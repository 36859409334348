<template lang="pug">
  include ./../../../../mixins
  +b.form-base.auth-form
    +b.P.form-title.h1_title {{ _("Реєстрація") }}
    +b.DIV.text-controls-wrapper--fixed-w
      +b.P.text-controls
        +e.gray {{ _("Усе майже готово, залишилося вказати email, яку не надала соціальна мережа") }}
    validation-observer(
      ref="validator",
      tag="form",
      class="form ",
      @submit.prevent='prepareData(valid)',
      v-slot="{ valid, errors: globalErrors }",
    )
      +b.server-error(v-if="serverError") {{ serverError[0] }}
      +b.form-inputs-wrap
        +b.form-inputs
          +b.form-inp
            +b.input_label {{ _('Введіть пошту') }}
            input-group(
              rules='required|email'
              type="email",
              id="email",
              name="email"
              placeholder="",
              v-model="formData['email']"
              :class="{'is-error':  showInputError(globalErrors, 'email')}"
            ).input_style.input_style_auth
              template(v-slot:label="")
                span
              template(v-slot:error="{errors}")
                transition(name="fade")
                  span.help-type-error(v-if='errors.length') {{ errors[0] }}
            validation-provider(
                v-slot="{errors}"
                rules='must-be-true'
                name="ageAdult"
                tag='div'
                class='check-box-wrapper'
              )
                +b.LABEL.control-checkbox.--variant_flex.--marg-top
                  +e.INPUT.element(
                    type='checkbox'
                    v-model="formData.ageAdult"
                    :class="{'is-error':  showInputError(globalErrors, 'ageAdult')}"
                  )
                  +e.label
                  +e.SPAN.content.--space_sm-xl {{ _("Я підтверджую, що на момент реєстрації мені виповнилося 18 років") }}
                span.help-type-error.help-type-error--register-text(v-if='errors[0]') {{ errors[0] }}
          +b.form-button
            +b.BUTTON.btn-red--confirm(
                type="submit",
                @click.prevent='prepareData(valid)'
              ) {{ _("Прийняти") }}
</template>

<script>

import FormMixin, { defaultValidatorErrorsParser } from '../../../mixins/SendMixin'
import {
  socialInputEmailResource,
} from '../../../services/users.service'
import SocialConfirmEmailModal from '../../Modals/Users/SocialConfirmEmailModal'

export default {
  data() {
    return {
      formData: {
        email: '',
        ageAdult: false,
      },
      serverError: '',
    }
  },
  mixins: [
    FormMixin,
  ],
  methods: {
    resetErrors() {
      this.$refs.validator.reset()
      this.nonFieldErrors = []
    },
    async prepareData(valid) {
      this.$refs.validator.validate()
      if (!valid) return
      this.submit(valid, {
        email: this.formData.email,
      })
    },
    send(data) {
      console.log('send')
      console.log(data)
      return socialInputEmailResource.execute({}, data)
        .then(({ data: { item } }) => {
          this.$refs.validator.reset()
          console.log(item)
          // якщо все добре, то відкриваємо модал для коду підтвердження
          this.openModal()
        })
        // .catch(error => {
        //   console.log(error)
        //   this.openModal()
        // })
    },
    updateValidator(errors) {
      const parsed = defaultValidatorErrorsParser(errors)
      if ('nonFieldErrors' in parsed) {
        this.serverError = parsed.nonFieldErrors
      } else {
        this.serverError = parsed.message
      }
      this.$refs.validator.setErrors(parsed)
      this.isLoading = false
    },
    openModal() {
      this.$modal.show(
        SocialConfirmEmailModal,
        {},
        {
          height: 'auto',
          // height: 800,
          width: 360,
          // maxWidth: 544,
          adaptive: true,
          scrollable: false,
          transition: 'false',
        }
      )
    },
    showInputError(errors, inpName) {
      if (errors[inpName] && 0 < errors[inpName].length) {
        return true
      }
      return false
    },
    goToUserPersonalPage() {
      window.location.pathname = '/cabinet/'
    },
  },
}

</script>
