<template lang="pug">
  include ./../../../../mixins
  +b.chat-control-wrapper(
    :class='styleClass'
    @click='goToChat'
  )
    +b.I.icon.icon-mail
    +b.chat-control-label {{ label }}
</template>

<script>
import InfoModal from '@components/Modals/InfoModal'
import { getCommonParsedErrors } from '@mixins/SendMixin'
import { roomInitialize } from '@services/chat.service'
import { CABINET_CHAT_NAME } from '@/consts'

export default {
  props: {
    userId: {
      type: Number,
    },
    isBanned: {
      type: Boolean,
      default: false,
    },
    styleVersion: {
      type: String,
      default: 'text',
    },
  },
  data() {
    return {
      label: this._('написати'),
      userIsBanned: this.isBanned,
      styleClass: '',
    }
  },
  mounted() {
    if ('button' === this.styleVersion) {
      this.styleClass = 'as-button'
    } else if ('text' === this.styleVersion) {
      this.styleClass = 'as-text'
    }
  },
  methods: {
    async goToChat() {
      const formdata = { userToId: this.userId }

      roomInitialize.execute({}, formdata).then(res => {
        const { data: { item } } = res
        const link = this.$router.resolve({
          name: CABINET_CHAT_NAME,
          query: { room: item.id },
        }).href

        window.location = link
      }).catch(async error => {
        const parsed = await getCommonParsedErrors(error)

        if (403 === error.status) {
          const message = this._('Необхідно авторизуватися')
          const btnTitle = this._('Авторизуватися')
          this.openModal(message, btnTitle, window.loginPageLink)
        }

        if (parsed && parsed.userToId) {
          const [firstItem] = parsed.userToId
          const { message } = firstItem

          this.openModal(message)
        }
      })
    },

    openModal(title, btnTitle, link) {
      this.$modal.show(InfoModal, {
        title,
        btnTitle,
        btnLink: link,
      }, {
        height: 'auto',
        width: 360,
        adaptive: true,
        scrollable: false,
        transition: 'false',
      })
    },
  },
}
</script>
